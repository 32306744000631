import { AbstractControl } from '@angular/forms';

export function checkDailyTime(control: AbstractControl): any {
  const HoursOptions = [
    '01:',
    '02:',
    '03:',
    '04:',
    '05:',
    '06:',
    '07:',
    '08:',
    '09:',
    '10:',
    '11:',
    '12:',
    '13:',
    '14:',
    '15:',
    '16:',
    '17:',
    '18:',
    '19:',
    '20:',
    '21:',
    '22:',
    '23:',
    '00:',
    undefined,
    null,
    '',
  ];

  const minutesOptions = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '00',
  ];

  const dailyTime = control.root.get('dailyTimeControl')?.value;

  if (
    !HoursOptions.includes(
      dailyTime
        ?.charAt(0)
        .concat(dailyTime?.charAt(1).concat(dailyTime?.charAt(2)))
    ) ||
    !minutesOptions.includes(
      dailyTime?.charAt(3).concat(dailyTime?.charAt(4))
    ) ||
    dailyTime?.length > 5
  ) {
    return { invalidDailyTime: true };
  } else {
    control.root.get('dailyTimeControl')?.setErrors(null);
  }
}

export function checkWeeklyTime(control: AbstractControl): any {
  const HoursOptions = [
    '01:',
    '02:',
    '03:',
    '04:',
    '05:',
    '06:',
    '07:',
    '08:',
    '09:',
    '10:',
    '11:',
    '12:',
    '13:',
    '14:',
    '15:',
    '16:',
    '17:',
    '18:',
    '19:',
    '20:',
    '21:',
    '22:',
    '23:',
    '00:',
    undefined,
    null,
    '',
  ];

  const minutesOptions = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '00',
  ];

  const weeklyTime = control.root.get('weeklyTimeControl')?.value;

  if (
    !HoursOptions.includes(
      weeklyTime
        ?.charAt(0)
        .concat(weeklyTime?.charAt(1).concat(weeklyTime?.charAt(2)))
    ) ||
    !minutesOptions.includes(
      weeklyTime?.charAt(3).concat(weeklyTime?.charAt(4))
    ) ||
    weeklyTime?.length > 5
  ) {
    return { invalidweeklyTime: true };
  } else {
    control.root.get('weeklyTimeControl')?.setErrors(null);
  }
}

export function checkStartTime(control: AbstractControl): any {
  const HoursOptions = [
    '01:',
    '02:',
    '03:',
    '04:',
    '05:',
    '06:',
    '07:',
    '08:',
    '09:',
    '10:',
    '11:',
    '12:',
    '13:',
    '14:',
    '15:',
    '16:',
    '17:',
    '18:',
    '19:',
    '20:',
    '21:',
    '22:',
    '23:',
    '00:',
    undefined,
    null,
    '',
  ];

  const minutesOptions = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '00',
  ];

  const startTime = control.root.get('startTimeControl')?.value;

  if (
    !HoursOptions.includes(
      startTime
        ?.charAt(0)
        .concat(startTime?.charAt(1).concat(startTime?.charAt(2)))
    ) ||
    !minutesOptions.includes(
      startTime?.charAt(3).concat(startTime?.charAt(4))
    ) ||
    startTime?.length > 5
  ) {
    if (startTime != '') {
      return { invalidStartTime: true };
    } else {
      control.root.get('startTimeControl')?.setErrors(null);
    }
  } else {
    control.root.get('startTimeControl')?.setErrors(null);
  }
}

export function checkEndTime(control: AbstractControl): any {
  const HoursOptions = [
    '01:',
    '02:',
    '03:',
    '04:',
    '05:',
    '06:',
    '07:',
    '08:',
    '09:',
    '10:',
    '11:',
    '12:',
    '13:',
    '14:',
    '15:',
    '16:',
    '17:',
    '18:',
    '19:',
    '20:',
    '21:',
    '22:',
    '23:',
    '00:',
    undefined,
    null,
    '',
  ];

  const minutesOptions = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '00',
  ];

  const endTime = control.root.get('endTimeControl')?.value;

  if (
    !HoursOptions.includes(
      endTime?.charAt(0).concat(endTime?.charAt(1).concat(endTime?.charAt(2)))
    ) ||
    !minutesOptions.includes(endTime?.charAt(3).concat(endTime?.charAt(4))) ||
    endTime?.length > 5
  ) {
    if (endTime != '') {
      return { invalidEndTime: true };
    } else {
      control.root.get('endTimeControl')?.setErrors(null);
    }
  } else {
    control.root.get('endTimeControl')?.setErrors(null);
  }
}

export function checkDate(control: AbstractControl): any {
  const startDateValue = control.root.get('startDate');
  const endDateValue = control.root.get('endDate');

  const startDate = new Date(startDateValue?.value);
  const endDate = new Date(endDateValue?.value);

  if (startDate?.setHours(0, 0, 0, 0) > endDate?.setHours(0, 0, 0, 0)) {
    return { startDateBeforeEnd: true };
  } else {
    if (startDateValue?.value != null && endDateValue?.value != null) {
      control.root.get('startDate')?.setErrors(null);
      control.root.get('endDate')?.setErrors(null);
    }
    return null;
  }
}

export function checkTimeValidity(control: AbstractControl): any {
  const startDateValue = control.root.get('startDate');
  const endDateValue = control.root.get('endDate');
  const startTime = control.root.get('startTimeControl')?.value;
  const endTime = control.root.get('endTimeControl')?.value;

  const startDate = new Date(startDateValue?.value);
  const endDate = new Date(endDateValue?.value);

  if (
    control.root.get('startDate')?.errors?.startDateBeforeEnd != true &&
    control.root.get('endDate')?.errors?.startDateBeforeEnd != true
  ) {
    if (
      startDate?.setHours(0, 0, 0, 0) == endDate?.setHours(0, 0, 0, 0) &&
      control.root.get('startTimeControl')?.errors?.invalidStartTime != true &&
      control.root.get('endTimeControl')?.errors?.invalidEndTime != true
    ) {
      if (
        Number(startTime?.replace(':', '')) >
          Number(endTime?.replace(':', '')) &&
        endTime != '' &&
        startTime != ''
      ) {
        return { endTimeBeforeError: true };
      } else {
        control.root.get('startTimeControl')?.setErrors(null);
        control.root.get('endTimeControl')?.setErrors(null);
      }
    } else {
      if (
        control.root.get('startTimeControl')?.errors?.invalidStartTime !=
          true &&
        control.root.get('endTimeControl')?.errors?.invalidEndTime != true
      ) {
        control.root.get('startTimeControl')?.setErrors(null);
        control.root.get('endTimeControl')?.setErrors(null);
      }
    }
    return null;
  }
}

export function checkDateAndTime(control: AbstractControl): any {
  const startDateValue = control.root.get('startDate');
  const endDateValue = control.root.get('endDate');
  const startTime = control.root.get('startTimeControl')?.value;
  const endTime = control.root.get('endTimeControl')?.value;

  const startDate = new Date(startDateValue?.value);
  const endDate = new Date(endDateValue?.value);

  if (
    startDate?.setHours(0, 0, 0, 0) == endDate?.setHours(0, 0, 0, 0) &&
    control.root.get('startTimeControl')?.errors?.invalidStartTime != true &&
    control.root.get('endTimeControl')?.errors?.invalidEndTime != true
  ) {
    if (
      Number(startTime?.replace(':', '')) > Number(endTime?.replace(':', '')) &&
      endTime != '' &&
      startTime != ''
    ) {
      control.root
        .get('endTimeControl')
        ?.setErrors({ endTimeBeforeError: true });
      return null;
    }
  } else {
    if (
      control.root.get('startDate')?.errors?.startDateBeforeEnd != true &&
      control.root.get('endDate')?.errors?.startDateBeforeEnd != true &&
      control.root.get('startTimeControl')?.errors?.invalidStartTime != true &&
      control.root.get('endTimeControl')?.errors?.invalidEndTime != true
    ) {
      control.root.get('startTimeControl')?.setErrors(null);
      control.root.get('endTimeControl')?.setErrors(null);
      return null;
    }
  }
}
