import { ChapterModel } from './models/cardboard/chapiter.model';
import { DimensionModel } from './models/cardboard/dimension.model';
import { ItemArticleModel } from './models/cardboard/item-article.model';
import { ItemModel } from './models/cardboard/item.model';
import { ResultGlobalChartModel } from './models/cardboard/result-global-chart.model';

export interface State {
  dimensions: DimensionModel[];
  dimension: DimensionModel;
  chapters: ChapterModel[];
  chapter: ChapterModel;
  items?: ItemModel[];
  filtredItems?: ItemModel[];
  item?: ItemModel;
  itemsShuffled: boolean;
  articles: ItemArticleModel[];
  initialArticles: ItemArticleModel[];
  pending?: boolean;
  pendingReport?: boolean;
  error?: string;
  selectedArticle: string | null;
  globalChart: ResultGlobalChartModel | null;
  ecartType: boolean;
}

export const initialState: State = {
  dimensions: [],
  initialArticles: [],
  itemsShuffled: false,
  items: [],
  dimension: {
    _id: '1',
    rank: 1,
    title: 'Dimension',
    description: '',
    chapters: [],
    avg: 0,
  },
  chapters: [],
  chapter: {
    _id: '1',
    rank: 1,
    title: 'Chapitre',
    description: '',
    hasItems: false,
    result: {
      avg: 0,
    },
  },
  articles: [
    {
      _id: '1',
      rank: 1,
      title: '',
      description: '',
    },
    {
      _id: '2',
      rank: 2,
      title: '',
      description: '',
    },
    {
      _id: '3',
      rank: 3,
      title: '',
      description: '',
    },
    {
      _id: '4',
      rank: 4,
      title: '',
      description: '',
    },
    {
      _id: '5',
      rank: 5,
      title: '',
      description: '',
    },
  ],
  globalChart: null,
  selectedArticle: null,
  ecartType: false,
};
