import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterState,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from 'environments/environment';
import { AuthStoreModule } from './auth/auth.module';
import { BoardsStoreModule } from './boards/boards.module';
import { ProjectsStoreModule } from './projects/projects.module';
import { SharedStoreModule } from './shared-store/shared-store.module';
import { WorkshopsStoreModule } from './workshops/workshops.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    AuthStoreModule,
    ProjectsStoreModule,
    BoardsStoreModule,
    WorkshopsStoreModule,
    SharedStoreModule,

    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'Excelway',
          maxAge: 50,
          connectInZone: true,
        })
      : [],
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
  ],
})
export class RootStoreModule {}
