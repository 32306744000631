import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'excelway-user-notification-preferences',
  templateUrl: './user-notification-preferences.component.html',
})
export class UserNotificationPreferencesComponent {
  displayPictureIcons: boolean;

  language = new FormControl('');

  isOpenDailySummaries = false;
  isOpenWeeklySummaries = false;

  isSelected: false;
  selecthoursDailyummaries: string = '';
  selecthoursWeeklyummaries: string = '';
  profileDetailsForm: FormGroup;

  daysList: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  hours_of_day: string[] = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
    '00:00',
  ];
  constructor(private _formBuilder: FormBuilder) {
    this.profileDetailsForm = this._formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      jobtittle: ['', [Validators.required]],
      language: ['', [Validators.required]],
      selecthoursdailyummaries: ['', [Validators.required]],
      selecthoursWeeklyummaries: ['', [Validators.required]],
    });
  }

  OpenDropdownDailyummaries(): void {
    if (this.isOpenDailySummaries) {
      this.isOpenDailySummaries = false;
    } else {
      this.isOpenDailySummaries = true;
    }
  }
  OpenDropdownWeeklySummaries(): void {
    if (this.isOpenWeeklySummaries) {
      this.isOpenWeeklySummaries = false;
    } else {
      this.isOpenWeeklySummaries = true;
    }
  }
  onDropdownItemClick1(event: Event, selecthoursdailyummaries: string): void {
    event.preventDefault();
    this.selecthoursDailyummaries = selecthoursdailyummaries;
    this.profileDetailsForm.patchValue({
      selecthoursdailyummaries: selecthoursdailyummaries,
    });
    this.OpenDropdownDailyummaries();
  }
  onDropdownItemClick2(event: Event, selecthoursWeeklyummaries: string): void {
    event.preventDefault();
    this.selecthoursWeeklyummaries = selecthoursWeeklyummaries;
    this.profileDetailsForm.patchValue({
      selecthoursWeeklyummaries: selecthoursWeeklyummaries,
    });
    this.OpenDropdownWeeklySummaries();
  }
}
