import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/common-dialogs/common.services';

@Component({
  selector: 'access-and-invites',
  templateUrl: './access-and-invites.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessInvitesComponent implements OnInit {
  accessInvitesForm: UntypedFormGroup;
  plans: any[];
  isPublic = true;

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<AccessInvitesComponent>,
    private _workshopService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Create the form
    this.accessInvitesForm = this._formBuilder.group({
      plan: ['public'],
    });

    this.accessInvitesForm.controls['plan'].setValue(
      this.isPublic ? 'public' : 'private'
    );

    // Setup the plans
    this.plans = [
      {
        value: 'public',
        label: 'Public workshop',
        icon: 'lock_open',
        details: 'Available to everyone with the link. No sign-up required.',
        price: '10',
      },
      {
        value: 'private',
        label: 'Private workshop',
        icon: 'lock',
        details: 'Only available to those invited by email. Sign-up required.',
        price: '20',
      },
    ];
  }

  saveChanges(): void {
    console.log(this.accessInvitesForm.value.plan);
    const objectData = {
      name: this.data.name,
      isPublic: this.accessInvitesForm.value.plan === 'public' ? true : false,
    };
    this._workshopService
      .updateObjectDetails('project', objectData, this.data.id)
      .subscribe();
    this._matDialogRef.close();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
