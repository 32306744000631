import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateBoardModel } from '@excelway/models/board/board.model';
import { ApiService } from '@excelway/services/api.service';

@Component({
  selector: 'app-customize-cards-fields',
  templateUrl: './customize-cards-fields.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CustomizeCardsFieldsComponent implements OnInit {
  cardFieldsForm: FormGroup;
  constructor(
    private _apiService: ApiService,
    @Inject(MAT_DIALOG_DATA)
    public boardData,
    private _formBuilder: FormBuilder
  ) {
    this.cardFieldsForm = this._formBuilder.group({
      cost: [false],
      duration: [false],
    });
  }

  /* 
  OnInit  
  */
  ngOnInit(): void {
    try {
      this.cardFieldsForm.controls['cost'].setValue(
        this.boardData.enableCardCost
      );
      this.cardFieldsForm.controls['duration'].setValue(
        this.boardData.enableCardDuration
      );
    } catch (err) {
      console.log(err);
    }
  }
  /* 
  public functions 
  */

  updateCost(cost: boolean): void {
    this.updateBoardSettings({
      enableCardCost: cost,
    });
  }
  updateDuration(duration: boolean): void {
    this.updateBoardSettings({
      enableCardDuration: duration,
    });
  }

  /* 
  private functions
  */

  private updateBoardSettings(data: UpdateBoardModel): void {
    const payload = {
      objectId: this.boardData.id,
      roleType: 'Board',
      body: data,
    };

    this._apiService.updateObject(payload).subscribe();
  }
}
