import { Injectable, OnDestroy } from '@angular/core';
import {
  UserInterface,
  UserInterfaceService,
} from '@excelway/services/user-interface.service';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { ApiService } from './../../../@excelway/services/api.service';

export interface Language {
  value: string;
}

export interface Field {
  id?: string;
  name: string;
  value: {
    type: string;
    languages: { [key: string]: string };
  };
  isEditing?: boolean;
}

export interface Interface {
  id: string;
  name: string;
  description: string;
  value: any;
}

export interface UpdateInterfacePayload {
  id: string;
  name: string;
  description: string;
  value: {
    path: string;
  };
}

export interface InterfaceState {
  interfaces: Interface[];
  fields: Field[];
  loading: boolean;
  error: string | null;
}

export const initialState: InterfaceState = {
  interfaces: [],
  fields: [],
  loading: false,
  error: null,
};

@Injectable()
export class InterfaceStore
  extends ComponentStore<InterfaceState>
  implements OnDestroy
{
  constructor(
    private _apiService: ApiService,
    private _userInterfaceService: UserInterfaceService
  ) {
    super(initialState);
  }

  resetState(): void {
    this.setState(initialState);
  }

  ngOnDestroy(): void {
    this.resetState();
  }

  //**********SELECTORS*********** */

  readonly interfaces$ = this.select(state => state.interfaces);
  readonly fields$ = this.select(state => state.fields);

  //**********END SELECTORS*********** */

  //*************UPDATERS********************** */

  readonly addCreatedInterface = this.updater(
    (state, newInterface: Interface) => {
      return {
        ...state,
        interfaces: [...state.interfaces, newInterface],
      };
    }
  );

  readonly setCreatedField = this.updater((state, newField: Field) => ({
    ...state,
    fields: [...state.fields, newField],
  }));

  readonly updateField = this.updater((state, updatedField: Field) => ({
    ...state,
    fields: state.fields.map(field =>
      field.id === updatedField.id ? updatedField : field
    ),
  }));

  readonly addInterfaces = this.updater((state, interfaces: Interface[]) => ({
    ...state,
    interfaces: [...state.interfaces, ...interfaces],
    loading: false,
    error: null,
  }));

  readonly setFields = this.updater((state, fields: any[]) => ({
    ...state,
    fields: [...state.fields, ...fields],
    loading: false,
    error: null,
  }));

  readonly setLoading = this.updater(state => ({
    ...state,
    loading: true,
  }));

  readonly setError = this.updater((state, error: string) => ({
    ...state,
    error,
  }));

  readonly updateInterfaceInState = this.updater(
    (state, updatedInterface: any) => ({
      ...state,
      interfaces: state.interfaces.map(userInterface =>
        userInterface.id === updatedInterface.id
          ? updatedInterface
          : userInterface
      ),
    })
  );

  //*************END UPDATERS********************** */

  //***********************EFFECTS**************************** */

  readonly createUserInterface = this.effect(
    (payload$: Observable<{ body: UserInterface }>) => {
      return payload$.pipe(
        switchMap(({ body }) =>
          this._userInterfaceService.createUserInterface(body).pipe(
            tap(response => {
              const createdInterface = {
                id: response.id,
                name: response.name,
                description: response.description,
                value: response.value,
                appservice: response.appservice,
              };
              this.addCreatedInterface(createdInterface);
            }),
            catchError(error => {
              console.error('Error creating interface:', error);
              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly getInterfacesList = this.effect(
    (payload$: Observable<{ skip: number; take: number }>) => {
      return payload$.pipe(
        switchMap(({ skip, take }) =>
          this._userInterfaceService.getUserInterfaces(skip, take).pipe(
            tap(response => {
              this.addInterfaces(response);
            }),
            catchError(error => {
              console.error('Error fetching interfaces:', error);
              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly updateUserInterface = this.effect(
    (
      payload$: Observable<{
        id: string;
        changes: UpdateInterfacePayload;
        appServiceName;
      }>
    ) => {
      return payload$.pipe(
        switchMap(({ id, changes, appServiceName }) =>
          this._userInterfaceService.updateUserInterface(id, changes).pipe(
            tap(updatedInterface => {
              const updatedUserInterface = {
                id: updatedInterface.id,
                name: updatedInterface.name,
                description: updatedInterface.description,
                appservice: {
                  name: appServiceName,
                },
                value: updatedInterface.value,
              };
              this.updateInterfaceInState(updatedUserInterface);
            }),
            catchError(error => {
              console.error('Error updating interface:', error);
              return EMPTY;
            })
          )
        )
      );
    }
  );
  readonly createField = this.effect(
    (payload$: Observable<{ body; interfaceId }>) => {
      return payload$.pipe(
        switchMap(({ body, interfaceId }) =>
          this._userInterfaceService
            .createInterfaceField(body, interfaceId)
            .pipe(
              tap(response => {
                const createdField = {
                  id: response.id,
                  name: response.name,
                  value: response.value,
                  isEditing: false,
                };
                this.setCreatedField(createdField);
              }),
              catchError(error => {
                console.error('Error creating field:', error);
                return EMPTY;
              })
            )
        )
      );
    }
  );

  readonly updateFieldEffect = this.effect(
    (
      payload$: Observable<{
        fieldData: any;
        fieldId: string;
      }>
    ) => {
      return payload$.pipe(
        switchMap(({ fieldData, fieldId }) =>
          this._userInterfaceService.updateField(fieldData, fieldId).pipe(
            tap(updatedfield => {
              const updatedUserInterface = {
                id: updatedfield.id,
                name: updatedfield.name,
                description: updatedfield.description,
                value: updatedfield.value,
              };
              this.updateField(updatedUserInterface);
            }),
            catchError(error => {
              console.error('Error updating the field:', error);
              return EMPTY;
            })
          )
        )
      );
    }
  );

  readonly getInterfaceFields = this.effect(
    (
      payload$: Observable<{
        interfaceId: string;
        skip?: number;
        take?: number;
      }>
    ) => {
      return payload$.pipe(
        switchMap(({ interfaceId, skip, take }) =>
          this._userInterfaceService.getFields(interfaceId, skip, take).pipe(
            tap(response => {
              this.setFields(response);
            }),
            catchError(error => {
              console.error('Error fetching fields:', error);
              return EMPTY;
            })
          )
        )
      );
    }
  );

  //***********************END EFFECTS**************************** */
}
