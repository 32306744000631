import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterfaceFieldsComponent } from './interface-fields/interface-fields.component';
import { InterfaceListComponent } from './interface-list/interface-list.component';

const routes: Routes = [
  { path: 'list', component: InterfaceListComponent },
  { path: ':id/fields', component: InterfaceFieldsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserInterfaceRoutingModule {}
