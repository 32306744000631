import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TemplateEditingBannerComponent } from './template-editing-banner.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TemplateEditingBannerComponent],
  imports: [CommonModule, MatIconModule],
  exports: [TemplateEditingBannerComponent],
})
export class TemplateEditingBannerModule {}
