import { Action, createReducer, on } from '@ngrx/store';

import * as ProjectsStoreActions from './actions';
import { initialState, State } from './state';

const projectsReducer = createReducer(
  initialState,
  on(ProjectsStoreActions.getProject, state => ({
    ...state,
    pending: true,
  })),
  on(ProjectsStoreActions.getProjectSuccess, (state, { project }) => ({
    ...state,
    currentProject: project,
    pending: false,
  })),
  on(ProjectsStoreActions.getProjectFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
  on(ProjectsStoreActions.getProjects, state => ({
    ...state,
    pending: true,
  })),
  on(ProjectsStoreActions.getProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
    pending: false,
  })),
  on(ProjectsStoreActions.getProjectsFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return projectsReducer(state, action);
}
