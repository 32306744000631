import { createAction, props } from '@ngrx/store';
import { Customer, NavigationItem } from './state';

export const loadNavigation = createAction('[Navigation] Load Navigation');
export const loadNavigationSuccess = createAction(
  '[Navigation] Load Navigation Success',
  props<{ items: NavigationItem[] }>()
);
export const loadNavigationFailure = createAction(
  '[Navigation] Load Navigation Failure',
  props<{ error: any }>()
);
export const updateAdminNavigation = createAction(
  '[Navigation] Update Admin Navigation',
  props<{ items: any[] }>()
);
export const resetNavigation = createAction('[Navigation] Reset Navigation');

export const loadCustomers = createAction('[Customer] Load Customers');
export const loadCustomersSuccess = createAction(
  '[Customer] Load Customers Success',
  props<{ customers: Customer[] }>()
);
export const loadCustomersFailure = createAction(
  '[Customer] Load Customers Failure',
  props<{ error: any }>()
);

export const loadProjects = createAction('[Projects] Load Navigation Projects');
export const loadProjectsSuccess = createAction(
  '[Projects] Load Navigation Projects Success',
  props<{ projects: any[] }>()
);
export const loadProjectsFailure = createAction(
  '[Projects] Load Navigation Projects Failure',
  props<{ error: any }>()
);
// actions.ts
export const loadObjects = createAction(
  '[Objects] Select Objects',
  props<{
    right_id: string;
    left_role: string;
    right_role: string;
  }>()
);

export const selectObjectsSuccess = createAction(
  '[Objects] Select Objects Success',
  props<{ data: any }>() // Adjust the type as necessary
);

export const selectObjectsFailure = createAction(
  '[Objects] Select Objects Failure',
  props<{ error: any }>()
);
