<div class="flex flex-col max-w-250 md:min-w-250 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 border-b border-slate-200 pl-6 sm:pl-8 b">
    <div class="text-lg font-medium">Activity settings</div>
    <button mat-icon-button [tabIndex]="-1" (click)="this.matDialogRef.close()">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  <!-- Compose form -->
  <div class="w-500px modal-body mt-10">
    <div class="mb-8 mb-lg-0">
      <div class="card-body">
        <div class="flex flex-row align-items-center p-5">
          <div class="mr-6">
            <span class="svg-icon svg-icon-success svg-icon-4x">
              <mat-slide-toggle
                (change)="checkToggled()"
                [ngClass]="'custom-green-toggle'"
                [(ngModel)]="checkedToggle">
              </mat-slide-toggle>
            </span>
          </div>
          <div class="d-flex flex-column">
            <div class="text-dark-75">Show color label in cards</div>
          </div>
        </div>
        <div class="flex flex-row align-items-center p-5">
          <div class="mr-6">
            <span class="svg-icon svg-icon-success svg-icon-4x">
              <mat-slide-toggle
                (change)="showMembersNames()"
                [ngClass]="'custom-green-toggle'"
                [(ngModel)]="showMembers">
              </mat-slide-toggle>
            </span>
          </div>
          <div class="d-flex flex-column">
            <div class="text-dark-75">Show who added the card</div>
          </div>
        </div>
        <div class="flex flex-row align-items-center p-5">
          <div class="mr-6">
            <span class="svg-icon svg-icon-success svg-icon-4x">
              <mat-slide-toggle
                (change)="checkShowAllCards()"
                [ngClass]="'custom-green-toggle'"
                [(ngModel)]="hideAllCards"></mat-slide-toggle>
            </span>
          </div>
          <div class="d-flex flex-column">
            <div class="text-dark-75">
              Participants can only see their own cards
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
