import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserNotificationPreferencesComponent } from './user-notification-preferences/user-notification-preferences.component';
import { UserProfileDetailsComponent } from './user-profile-details/user-profile-details.component';
import { UserProfileComponent } from './user-profile.component';
import { UserSecurityComponent } from './user-security/user-security.component';

@NgModule({
  imports: [
    CommonModule,
    //material
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
  ],
  declarations: [
    UserProfileComponent,
    UserSecurityComponent,
    UserProfileDetailsComponent,
    UserNotificationPreferencesComponent,
  ],
})
export class UserProfileModule {}
