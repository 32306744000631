import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'template-editing-banner',
  templateUrl: './template-editing-banner.component.html',
})
export class TemplateEditingBannerComponent implements OnInit {
  isHavingOriginalBoard: boolean = false;

  ngOnInit(): void {
    this.checkOriginalBoard();
  }

  checkOriginalBoard(): void {
    const originalBoardId = localStorage.getItem('originalBoardId');
    if (originalBoardId) {
      this.isHavingOriginalBoard = true;
    }
  }
}
