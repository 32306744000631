import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserInterfaceService } from '@excelway/services/user-interface.service';
import { InterfaceStore } from '../../user-interface.store';

@Component({
  selector: 'app-create-interface',
  templateUrl: './create-interface.component.html',
})
export class CreateInterfaceComponent implements OnInit {
  createInterfaceForm: FormGroup;
  appServicesModules: any;
  interfaces$ = this.interfaceStore.interfaces$;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateInterfaceComponent>,
    private interfaceStore: InterfaceStore,
    private _userInterfaceServices: UserInterfaceService
  ) {
    this.createInterfaceForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      path: [''],
      appServiceId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getAppServicesList();
  }

  onSubmit(): void {
    if (this.createInterfaceForm.valid) {
      const newInterface = this.createInterfaceForm.value;
      this.dialogRef.close(newInterface);
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  getAppServicesList(): void {
    this._userInterfaceServices.getAppServicesModules().subscribe(m => {
      this.appServicesModules = m;
    });
  }
}
