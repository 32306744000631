import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthzService {
  //TODO Move this to authorization service in the next authz PR
  private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  role$: Observable<string> = this.roleSubject.asObservable();

  constructor(private _httpClient: HttpClient) {}
  setRole(role: string): void {
    this.roleSubject.next(role);
  }

  getRole(): string {
    return this.roleSubject.value;
  }

  fetchUserRole(
    objectId: string,
    userId: string
  ): Observable<{ role: string }> {
    return this._httpClient.get<{ role: string }>(
      `${environment.backendUrl}/v1/can-access/${objectId}/${userId}`,
      { withCredentials: true }
    );
  }
}
