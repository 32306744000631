import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProjectService } from '@excelway/services/project.service';
import { checkDate } from '@excelway/validators/checkDate';
import { CommonService } from 'app/common-dialogs/common.services';
import { WorkshopService } from 'app/modules/workshops/workshop.activities';

@Component({
  selector: 'object-details',
  templateUrl: './object-details.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class ObjectDetailsComponent implements OnInit {
  accountForm: UntypedFormGroup;
  projectId: string;
  allProjects: any;

  objectSettingsform = new FormGroup({
    name: new FormControl(''),
    project: new FormControl(''),
    description: new FormControl(''),
    startDate: new FormControl(
      null,
      Validators.compose([Validators.required, checkDate])
    ),
    endDate: new FormControl(
      null,
      Validators.compose([Validators.required, checkDate])
    ),
    startTime: new FormControl<string>(''),
    endTime: new FormControl(),
    isPublic: new FormControl<boolean>(false),
    value: new FormControl({}),
  });

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<ObjectDetailsComponent>,
    private _commonService: CommonService,
    private datePipe: DatePipe,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _projectService: ProjectService,
    private _workshopService: WorkshopService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  // data is the object
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getAllProjects();

    this._workshopService
      .getWorkshopActivities({
        roleType: 'Workshop',
        id: this.data.id,
        fields: ['parentId'],
        relations: [
          {
            relation: 'Activity',
            fields: ['id', 'name'],
            relations: [
              {
                relation: 'ActivityList',
                fields: ['id', 'name'],
              },
            ],
          },
        ],
      })
      .subscribe(Response => {
        this.projectId = Response.parentId;
      });
    this.objectSettingsform.controls['name'].setValue(this.data.name);
    this.objectSettingsform.controls['description'].setValue(
      this.data.description
    );
    this.objectSettingsform.controls['startDate'].setValue(this.data.startDate);
    this.objectSettingsform.controls['endDate'].setValue(this.data.endDate);
    this.objectSettingsform.controls['startTime'].setValue(
      this.data.value?.startTime
    );
    this.objectSettingsform.controls['endTime'].setValue(
      this.data.value?.endTime
    );
  }

  duplicateWorkshop(): void {
    this._workshopService
      .moveActivitiesPostIt(
        'Project',
        [
          {
            objectToDuplicateId: this.data.id,
            objectParentId: this.projectId,
          },
        ],
        this.projectId
      )
      .subscribe(Response => {
        console.log(Response);
      });
    this._matDialogRef.close();
  }

  getAllProjects(): any {
    this._projectService.getAllProjectsByUser().subscribe(project => {
      this.allProjects = project;
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  moveToProject(): void {
    this._workshopService
      .moveActivitiesPostIt(
        'Project',
        [
          {
            objectToDuplicateId: this.data.id,
            objectParentId: this.projectId,
          },
        ],
        this.objectSettingsform.value.project
      )
      .subscribe(Response => {
        console.log(Response);
      });
    this._matDialogRef.close();
  }

  closeWorkshop(): void {
    const objectData = {
      name: this.objectSettingsform.value.name,
      description: this.objectSettingsform.value.description,
      startTime: this.objectSettingsform.value.startTime,
      status: 'closed',
    };
    this._commonService
      .updateObjectDetails('Workshop', objectData, this.data.id)
      .subscribe(() => {
        // Navigating back to the workshops page of the specific project
        this.router.navigate([
          '/projects/project',
          this.projectId,
          'workshops',
        ]);
      });
    this._matDialogRef.close();
  }

  saveChanges(): void {
    const objectData = {
      name: this.objectSettingsform.value.name,
      description: this.objectSettingsform.value.description,
      startTime: this.objectSettingsform.value.startTime,
      endTime: this.objectSettingsform.value.endTime,
      startDate: this.datePipe.transform(
        this.objectSettingsform.value.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        this.objectSettingsform.value.endDate,
        'yyyy-MM-dd'
      ),
      isPublic: true,
      isPrivate: false,
      status: 'planned',
    };
    this._commonService
      .updateObjectDetails('Workshop', objectData, this.data.id)
      .subscribe();
    this._matDialogRef.close(this.objectSettingsform.value.name);
  }
}
