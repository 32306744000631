import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectService } from '@excelway/services/object.service';
import { ReportService } from 'app/modules/workshops/report.service';

@Component({
  selector: 'data-export',
  templateUrl: './data-export.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataExportComponent implements OnInit {
  notificationsForm: UntypedFormGroup;
  filesTypes: any[] = [
    { id: 'pdf', name: 'PDF' },
    { id: 'docx', name: 'Microsoft Docx' },
    { id: 'google docs', name: 'Google Docs' },
  ];

  workshop: any;
  workshopId: string;
  board: any;
  activities: any;
  reportUsers: any[];
  reportTasks: any[] = [];
  reportLabels: any[] = [];
  reportActivities: any[] = [];

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _objectService: ObjectService,
    private _reportService: ReportService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  dataform = new FormGroup({
    selectedFileType: new FormControl(''),
  });

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.getWorkshop();
  }

  getWorkshop(): void {
    const query = {
      roleType: 'Workshop',
      id: this.data.id,
      isRead: true,
      fields: [
        'id',
        'name',
        'description',
        'startDate',
        'endDate',
        'startTime',
        'endTime',
        'isPublic',
        'status',
      ],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email', 'role', 'email'],
        },
        {
          relation: 'Activity',
          fields: [
            'id',
            'name',
            'description',
            'startDate',
            'endDate',
            'activityType',
            'color',
            'icon',
          ],
          relations: [
            {
              relation: 'Task',
              fields: ['id', 'name', 'description', 'dueDate'],
            },
            {
              relation: 'ActivityList',
              fields: ['id', 'name', 'description'],
              relations: [
                {
                  relation: 'PostIt',
                  fields: ['id', 'name', 'description', 'color'],
                },
              ],
            },
            {
              relation: 'Label',
              fields: ['id', 'name', 'color'],
            },
          ],
        },
      ],
    };
    this._objectService.getObject(query).subscribe(Response => {
      this.activities = Response.Activity;
      this.workshop = Response;
    });
  }

  generateReport(format: any): any {
    // Build the same task format for report v1
    this.workshop.Activity.map(activity =>
      activity.Task.map(task =>
        this.reportTasks.push({
          _id: task.id,
          name: task.name,
          description: task.description,
          // To add task responsible
          responsible: '2b87b1b2-1122-4acb-9717-941ffee2f375',
          dueDate: task.dueDate,
          comment: '',
          attachments: [],
          checklists: [],
          status: 'open',
          boards: [],
          statusExport: 'newly',
        })
      )
    );
    this.workshop.Activity.map(activity =>
      activity.Label.map(label =>
        this.reportLabels.push({
          id: label.id,
          name: label.name,
          color: label.color,
        })
      )
    );
    console.log(this.workshop);
    this.workshop.Activity.map(activity => {
      this.reportActivities.push({
        id: activity.id,
        name: activity.name,
        canvasType: activity.canvasType,
        lists: activity.ActivityList.map(list => ({
          id: list.id,
          name: list.name,
          description: list.description,
          idCards: list.PostIt.map(postIt => postIt.id.toString()), // Convert id to a string
        })),
        labels: activity.Label.map(label => ({
          id: label.id,
          name: label.name,
          color: label.color,
        })),
        cards: activity.ActivityList.flatMap(list =>
          list.PostIt.map(postIt => ({
            id: postIt.id,
            name: postIt.name,
            description: postIt.description,
            color: postIt.color,
            typeCard: 'jfPNaB',
            rates: [],
            sum: 0,
          }))
        ),
      });
    });
    // Send the same format of v1 report
    this._reportService.getDocxReport(
      // The session data
      {
        _id: this.workshop.id,
        name: this.workshop.name,
        description: this.workshop.description,
        token: 'qJJjeH',
        startDate: this.workshop.startDate,
        endDate: this.workshop.endDate,
        users: this.workshop.users.map(user => ({
          _id: user.id,
          firstName: user.name,
          lastName: '',
          email: user.email,
          profilePic: '',
          position: 'animator',
          role: user.role,
          isParticipant: false,
        })),
        participants: this.workshop.users.map(user => ({
          _id: user.i,
          firstName: user.name,
          lastName: '',
          fullName: user.name,
          profilePic: '',
          email: user.email,
        })),
        guests: [],
        status: this.workshop.status,
        detail: {
          line1: '',
          line2: '',
          postcode: 0,
          city: '',
          startDate: this.workshop.startDate,
          endDate: this.workshop.endDate,
          startTime: this.workshop.startTime,
          endTime: this.workshop.endTime,
          description: this.workshop.description,
        },
        tasks: this.reportTasks,
        notes: [],
        activities: this.workshop.Activity.map(activity => ({
          _id: activity.id,
          name: activity.name,
          activityType: activity.activityType,
          contentId: '',
          color: '',
          isArchived: false,
        })),
        categories: this.reportLabels,
        //  To change
        isPrivate: true,
        isActive: false,
        objectInfo: {
          ownedBy: '',
          createdBy: '',
          createdAt: '',
          modifiedBy: '',
          modifiedAt: '',
        },
        blamer: '',
        participantsLimit: 10,
        ownerId: '',
      },
      // The activities data
      [],
      // The detailed activities data
      this.reportActivities,
      // Language
      'en',
      // Format
      format.id
    );
  }
}
