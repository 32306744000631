<div class="w-full max-w-8xl">
  <!-- Form -->
  <form [formGroup]="accessInvitesForm">
    <!-- Section -->
    <div class="w-full">
      <div class="text-xl">Change access</div>
    </div>

    <!-- Divider -->
    <div class="mt-2 mb-10 border-t"></div>

    <!-- Members -->
    <settings-team-board></settings-team-board>

    <!-- Divider -->
    <div class="mt-11 mb-10 border-t"></div>

    <!-- Actions -->
    <div class="flex items-center justify-end">
      <button
        (click)="this._matDialogRef.close()"
        class="btn-tertiary"
        type="button">
        Cancel
      </button>
      <button (click)="saveChanges()" class="btn-primary-hover" type="button">
        Save
      </button>
    </div>
  </form>
</div>
