import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  // headers = new HttpHeaders().set('subjectId', 'clivl5cbj0002tb2s8vu3gs9q');

  constructor(private _httpClient: HttpClient) {}

  /**
   * get dynamic object
   *
   * @param data
   *
   */
  getObject(data: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  /**
   * Create object
   */
  createObject(
    RoleTypeParent: string,
    idRoleTypeParent: string,
    data: any,
    RoleType: string,
    Relation: string
  ): Observable<any> {
    console.log(data);

    return this._httpClient.post(
      `${environment.backendUrl}/v1/${RoleTypeParent}/${idRoleTypeParent}/${Relation}/${RoleType}`,
      data,
      { withCredentials: true }
    );
  }

  /**
   * Update object
   */
  updateObject(RoleType: any, idRoleType: any, data: any): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/${RoleType}/${idRoleType}`,
      data,
      { withCredentials: true }
    );
  }

  /**
   * Delete object
   */
  removeObject(objectId: string, roleTypeName: string): Observable<any> {
    return this._httpClient.delete(
      `${environment.backendUrl}/v1/${roleTypeName}/${objectId}`,
      {
        withCredentials: true,
      }
    );
  }

  duplicateObject(
    newParentRoleType: any,
    objectsIds: any,
    newParentId: any
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/duplicateObjects/${newParentRoleType}/${newParentId}`,
      objectsIds,
      { withCredentials: true }
    );
  }

  deleteObject(roleType: any, idRoleType: any): Observable<any> {
    return this._httpClient.delete(
      `${environment.backendUrl}/v1/${roleType}/${idRoleType}`,
      {
        withCredentials: true,
      }
    );
  }

  /**
   * get dynamic object
   *
   * @param data
   *
   */
  assignedTag(data: any, roleTypeObject: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/assigned_tag/${roleTypeObject}`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  unassignedTag(data: any, roleTypeObject: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/unassigned_tag/${roleTypeObject}`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  correctRanks(
    roleType: string,
    workspaceId: string,
    isFirst: boolean
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/reorder/correct-ranks/${roleType}/${workspaceId}`,
      { isFirst },
      {
        withCredentials: true,
      }
    );
  }
}
