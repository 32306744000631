<div
  class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
  <mat-drawer-container class="flex-auto sm:h-full">
    <!-- Drawer -->
    <mat-drawer
      class="sm:w-96 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer>
      <!-- Header -->
      <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
        <!-- Title -->
        <div
          class="text-xl w-full h-5 font-semibold tracking-tight leading-none">
          Workshop settings
        </div>
        <!-- Close button -->
        <div class="lg:hidden">
          <button mat-icon-button (click)="drawer.close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </div>
      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
          <div
            class="flex px-8 py-5 cursor-pointer"
            [ngClass]="{
              'hover:bg-gray-100 dark:hover:bg-hover':
                !selectedPanel || selectedPanel !== panel.id,
              'bg-primary-50 dark:bg-hover':
                selectedPanel && selectedPanel === panel.id
            }"
            (click)="goToPanel(panel.id)">
            <mat-icon
              [ngClass]="{
                'text-hint': !selectedPanel || selectedPanel !== panel.id,
                'text-primary dark:text-primary-500':
                  selectedPanel && selectedPanel === panel.id
              }"
              [svgIcon]="panel.icon"></mat-icon>
            <div class="ml-3">
              <div
                class="font-medium leading-6"
                [ngClass]="{
                  'text-primary dark:text-primary-500':
                    selectedPanel && selectedPanel === panel.id
                }">
                {{ panel.title }}
              </div>
              <div class="mt-0.5 text-secondary">
                {{ panel.description }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="flex flex-col">
      <!-- Main -->
      <div class="flex-auto px-6 pt-3 pb-12">
        <!-- Panel header -->
        <div class="flex flex-row justify-end">
          <!-- Close button -->
          <button mat-icon-button (click)="this._matDialogRef.close()">
            <mat-icon aria-label="Close panel">close</mat-icon>
          </button>
        </div>

        <!-- Load settings panel -->
        <div class="mt-1.5">
          <ng-container [ngSwitch]="selectedPanel">
            <!-- Object details -->
            <ng-container *ngSwitchCase="'object-details'">
              <object-details></object-details>
            </ng-container>
            <!-- Access & Invites -->
            <ng-container *ngSwitchCase="'access-invites'">
              <access-and-invites></access-and-invites>
            </ng-container>
            <!-- Data Export -->
            <ng-container *ngSwitchCase="'data-export'">
              <data-export></data-export>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
