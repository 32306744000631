<div class="w-full max-w-8xl">
  <!-- Form -->
  <!-- Section -->
  <div class="w-full flex flex-col gap-3">
    <div class="text-xl font-bold">Data Export</div>
    <div class="text-base">
      Simply choose the file type you want and click download to get the
      workshop report.
    </div>
    <form [formGroup]="dataform">
      <div class="w-full flex flex-row">
        <div class="flex mt-3 w-72">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-select formControlName="selectedFileType" required>
              <mat-option *ngFor="let file of filesTypes" [value]="file.id">{{
                file.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          [disabled]="!this.dataform.valid"
          (click)="generateReport(dataform.value.selectedFileType)"
          class="w-30 h-13 mt-3 btn-secondary-disabled"
          type="button">
          Download
        </button>
      </div>
    </form>
  </div>
</div>
