import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponse } from '@excelway/models/api/create-response';
import { QueryModel } from '@excelway/models/api/query';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UserWorkspaceService } from './user-workspace.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private _data: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService,
    private _userWorkspaceService: UserWorkspaceService
  ) {}

  /**
   * Getter for data
   */
  get data$(): Observable<any> {
    return this._data.asObservable();
  }

  getProjects(): Observable<any> {
    const activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();
    if (!activeWorkspaceId) {
      throw new Error('Active workspace ID not found in local storage.');
    }
    const query: QueryModel = {
      roleType: 'Workspace',
      id: activeWorkspaceId,
      fields: [],
      relations: [
        {
          relation: 'project',
          fields: [
            'id',
            'name',
            'description',
            'roleTypeName',
            'color',
            'rank',
            'index',
            'icon',
          ],
          relations: [
            {
              relation: 'users',
              fields: ['id', 'name', 'email', 'role'],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<any>(query);
  }

  getHierarchyProjectsTree(): Observable<any> {
    const activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();
    if (!activeWorkspaceId) {
      throw new Error('Active workspace ID not found in local storage.');
    }
    const query = {
      roleType: 'Workspace',
      id: activeWorkspaceId,
      fields: ['id', 'name', 'parentId'],
      relations: [
        {
          relation: 'Project',
          fields: ['id', 'name'],
          relations: [
            {
              relation: 'Project',
              fields: ['id', 'name'],
              relations: [
                {
                  relation: 'Project',
                  fields: ['id', 'name'],

                  relations: [
                    {
                      relation: 'Project',
                      fields: ['id', 'name'],

                      relations: [
                        {
                          relation: 'Project',
                          fields: ['id', 'name'],

                          relations: [
                            {
                              relation: 'Project',
                              fields: ['id', 'name'],

                              relations: [
                                {
                                  relation: 'Project',
                                  fields: ['id', 'name'],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<any>(query);
  }

  //get projects tree
  getProjectsTree(): Observable<any> {
    const activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();
    if (!activeWorkspaceId) {
      throw new Error('Active workspace ID not found in local storage.');
    }
    const query = {
      roleType: 'Workspace',
      id: activeWorkspaceId,
      fields: ['id', 'name', 'description', 'endDate', 'parentId'],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email', 'role'],
        },
        {
          relation: 'Project',
          fields: [
            'id',
            'context',
            'name',
            'description',
            'endDate',
            'color',
            'icon',
            'logo',
            'parentId',
            'rank',
            'index',
            'roleTypeName',
          ],
          relations: [
            {
              relation: 'users',
              fields: ['id', 'name', 'email', 'role'],
            },
            {
              relation: 'Project',
              fields: [
                'id',
                'name',
                'description',
                'endDate',
                'color',
                'icon',
                'parentId',
                'rank',
                'index',
                'roleTypeName',
              ],
              relations: [
                {
                  relation: 'users',
                  fields: ['id', 'name', 'email', 'role'],
                },
                {
                  relation: 'Project',
                  fields: [
                    'id',
                    'name',
                    'description',
                    'dueDate',
                    'color',
                    'icon',
                    'parentId',
                    'rank',
                    'index',
                    'roleTypeName',
                  ],
                  relations: [
                    {
                      relation: 'users',
                      fields: ['id', 'name', 'email', 'role'],
                    },
                    {
                      relation: 'Project',
                      fields: [
                        'id',
                        'name',
                        'description',
                        'dueDate',
                        'color',
                        'icon',
                        'parentId',
                        'rank',
                        'index',
                        'roleTypeName',
                      ],
                      relations: [
                        {
                          relation: 'users',
                          fields: ['id', 'name', 'email', 'role'],
                        },
                        {
                          relation: 'Project',
                          fields: [
                            'id',
                            'name',
                            'description',
                            'dueDate',
                            'color',
                            'icon',
                            'parentId',
                            'rank',
                            'index',
                            'roleTypeName',
                          ],
                          relations: [
                            {
                              relation: 'users',
                              fields: ['id', 'name', 'email', 'role'],
                            },
                            {
                              relation: 'Project',
                              fields: [
                                'id',
                                'name',
                                'description',
                                'dueDate',
                                'color',
                                'icon',
                                'parentId',
                                'rank',
                                'index',
                                'roleTypeName',
                              ],
                              relations: [
                                {
                                  relation: 'users',
                                  fields: ['id', 'name', 'email', 'role'],
                                },
                                {
                                  relation: 'Project',
                                  fields: [
                                    'id',
                                    'name',
                                    'description',
                                    'dueDate',
                                    'color',
                                    'icon',
                                    'parentId',
                                    'rank',
                                    'index',
                                    'roleTypeName',
                                  ],
                                  relations: [
                                    {
                                      relation: 'users',
                                      fields: ['id', 'name', 'email', 'role'],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<any>(query);
  }

  getAllProjects(workspaceId: string): Observable<any> {
    const query: QueryModel = {
      roleType: 'Workspace',
      id: workspaceId,
      fields: [],
      relations: [
        {
          relation: 'project',
          fields: ['id', 'name', 'description'],
        },
      ],
    };
    return this._apiService.getObject<any>(query);
  }

  createProject(
    parentId: string,
    name: string,
    description: string
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Project',
      parentId,
      relationship: 'is_component_of',
      roleType: 'Board',
      body: {
        name,
        description,
      },
    };
    return this._apiService.createObject(payload);
  }
}
