<div class="w-full max-w-8xl">
  <!-- Form -->
  <form>
    <!-- Section -->
    <form [formGroup]="objectSettingsform">
      <div class="grid sm:grid-cols-4 gap-6 w-full mt-3">
        <!-- Name -->
        <div class="sm:col-span-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Name
          </label>
          <input
            formControlName="name"
            class="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Name" />
        </div>
        <!-- About -->
        <div class="sm:col-span-4 mt-">
          <div class="sm:col-span-4 h-24">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="description">
              Description
            </label>
            <textarea
              id="description"
              type="text"
              placeholder="Description"
              formControlName="description"
              class="bg-white h-full shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-y rounded-md"></textarea>
          </div>
        </div>
        <!-- Date -->
        <div class="sm:col-span-4 w-full flex flex-row gap-2 mt-10">
          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label class="block text-gray-700 text-sm font-bold mb-2"
              >Start date</mat-label
            >
            <mat-error
              *ngIf="objectSettingsform.get('startDate')?.errors?.required">
              The start date is obligatory.
            </mat-error>
            <mat-error
              *ngIf="
                objectSettingsform.get('startDate')?.errors
                  ?.startDateBeforeEnd &&
                !objectSettingsform.get('startDate')?.errors?.required
              ">
              Start date is greater than end date
            </mat-error>
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="picker1" />
            <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label class="block text-gray-700 text-sm font-bold mb-2"
              >End date</mat-label
            >
            <mat-error
              *ngIf="objectSettingsform.get('endDate')?.errors?.required">
              The end date is obligatory.
            </mat-error>
            <mat-error
              *ngIf="
                objectSettingsform.get('endDate')?.errors?.startDateBeforeEnd &&
                !objectSettingsform.get('endDate')?.errors?.required
              ">
              Start date is greater than end date
            </mat-error>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="endDate" />
            <mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Time -->
        <!-- <div class="sm:col-span-4 w-full flex flex-row gap-4">
          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label>Start time</mat-label>
            <input matInput formControlName="startTime" />
          </mat-form-field>
          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label>End time</mat-label>
            <input matInput formControlName="endTime" />
          </mat-form-field>
        </div> -->
      </div>
      <!-- Divider -->
      <div class="mt-3 mb-3 border-t"></div>

      <!-- <div class="flex flex-col">
        <div class="text-lg font-semibold">Close workshop</div>
        <div class="mt-2">
          This will prevent participants from accessing the workshop, You can
          reopen the workshop any Time
        </div>
        <button
          (click)="closeWorkshop()"
          class="mt-4 w-40 btn-tertiary-danger-hover"
          type="button">
          Close workshop
        </button>
      </div> -->

      <!-- Divider -->
      <!-- <div class="mt-3 mb-3 border-t"></div> -->

      <!-- <div class="flex flex-col">
        <div class="text-lg font-semibold">Duplicate workshop</div>
        <div class="mt-2">
          This will duplicate workshop, its activities and colors settings,
          Choose whether you want to duplicate the cards as weel or not
        </div>
        <div class="flex mt-3">
          <mat-radio-group class="flex flex-col" [color]="'primary'">
            <mat-radio-button disabled>
              <span>Include cards</span>
            </mat-radio-button>
            <mat-radio-button>
              <span>Exclude cards</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <button
          (click)="duplicateWorkshop()"
          class="w-50 btn-secondary-hover"
          type="button">
          Duplicate workshop
        </button>
      </div> -->

      <!-- Divider -->
      <!-- <div class="mt-3 mb-3 border-t"></div> -->

      <!-- <div class="flex flex-col">
        <div class="text-lg font-semibold">Move to project</div>
        <div class="mt-2">
          This will move the workshop, its paticipants and content to another
          project
        </div>
        <div class="flex mt-3 w-72">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-select formControlName="project">
              <mat-option
                *ngFor="let project of allProjects"
                [value]="project.id"
                >{{ project.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <button
          (click)="moveToProject()"
          class="w-50 btn-secondary-hover"
          type="button">
          Move to project
        </button>
      </div> -->

      <!-- Divider -->
      <!-- <div class="mt-3 mb-3 border-t"></div> -->
    </form>
    <!-- <div class="flex flex-col">
      <div class="text-lg font-semibold">Delete workshop</div>
      <div class="mt-2">This will delete permanently the workshop,</div>
      <button
        (click)="saveChanges()"
        class="mt-4 w-40 btn-danger-hover"
        type="button">
        Delete Workshop
      </button>
    </div> -->

    <!-- Actions -->
    <div class="flex items-center justify-end">
      <button (click)="saveChanges()" class="btn-primary-hover" type="button">
        Save
      </button>
    </div>
  </form>
</div>
