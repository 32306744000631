import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthUser } from '@excelway/types/auth-user.types';
import { Store } from '@ngrx/store';
import { AuthStoreSelectors } from 'app/store/auth';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkshopService {
  userId = '';

  constructor(
    private _httpClient: HttpClient,
    private readonly _store: Store
  ) {
    this._store
      .select(AuthStoreSelectors.selectLoggedUser)
      .pipe()
      .subscribe((user: AuthUser) => (this.userId = user?.id));
  }

  //add workshop
  createWorkshop(workshopData: any, projectId: string): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/project/${projectId}/is_component_of/workshop`,
      workshopData,
      { withCredentials: true }
    );
  }

  //add activity
  createActivity(projectData: any, projectId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/workshop/${projectId}/is_component_of/activity`,
      projectData,
      { withCredentials: true }
    );
  }

  // Get all workshops by user
  getAllWorkshops(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/project`,
      { withCredentials: true }
    );
  }

  // Get all workshops by user
  accessWorkshop(id: any): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/levels/project/` + id,
      { withCredentials: true }
    );
  }

  // Get all workshops by user
  getActivityColors(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      { withCredentials: true }
    );
  }

  // Get all workshops activities
  getWorkshopActivities(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  // Move cards
  moveActivitiesPostIt(
    newParentRoleType: any,
    cardIds: any,
    newParentId: any
  ): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/duplicateObjects/${newParentRoleType}/${newParentId}`,
      cardIds,
      { withCredentials: true }
    );
  }

  /**
   * Update object
   */
  updateObject(RoleType: any, idRoleType: any, data: any): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/${RoleType}/${idRoleType}`,
      data,
      { withCredentials: true }
    );
  }

  // Turn cards into lists
  turnCardsIntoLists(body: any, newParentId: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/transformObjects/Activity/${newParentId}`,
      body,
      { withCredentials: true }
    );
  }

  // Turn cards into lists
  importCardsFromBoard(body: any, newParentId: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/transformObjects/ActivityList/${newParentId}`,
      body,
      { withCredentials: true }
    );
  }

  // Get all workshops activities
  getBoards(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  // Get all workshops by user
  //
  getActivity(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  createListCustom(ActivityId: string, ListData: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/activity/${ActivityId}/is_component_of/activityList`,
      ListData,
      { withCredentials: true }
    );
  }

  getActivityTasks(activityData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      activityData,
      { withCredentials: true }
    );
  }

  //
  addTask(taskData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/activity/${acitivityId}/is_component_of/task`,
      taskData,
      { withCredentials: true }
    );
  }

  updateTask(taskData: any, taskId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/task/${taskId}`,
      taskData,
      { withCredentials: true }
    );
  }

  getSectionCards(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      { withCredentials: true }
    );
  }
  //
  //
  addActivityColor(colorData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/activity/${acitivityId}/is_component_of/label`,
      colorData,
      { withCredentials: true }
    );
  }

  //update workshop
  //
  updateProject(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/project/${projectId}`,
      projectData,
      { withCredentials: true }
    );
  }

  //Drop card
  //
  dropCard(
    previousListId: any,
    newListId: any,
    postItId: any
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/moveObject/${previousListId}/ActivityList/${newListId}/PostIt/${postItId}`,
      { withCredentials: true }
    );
  }

  //update workshop
  //
  updateWorkshop(workshopData: any, worksopId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/project/${worksopId}`,
      workshopData,
      { withCredentials: true }
    );
  }

  //update workshop
  //
  updateBoard(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/board/${projectId}`,
      projectData,
      { withCredentials: true }
    );
  }

  //Update Activity
  updateActivity(activityData: any, activityId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/Activity/${activityId}`,
      activityData,
      { withCredentials: true }
    );
  }

  getworkshopBoard(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  //get project and users (dynamic webservice )
  //
  getProjectsAndUsers(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }
  //
  // Add member
  //
  addMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    //header
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/add_member`,
      memberdata,
      { withCredentials: true }
    );
  }
  //
  // update member
  //
  updateMember(memberdata: any, roleTypeObjectId): Observable<any> {
    const roleTypeObject = 'Project';
    //header
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/edit_member`,
      memberdata,
      { withCredentials: true }
    );
  }
  //
  // remove member
  //
  removeMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    //header
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/remove_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  getSessionRole(sessionUsers: any): string {
    const animator = sessionUsers.find(
      user => user._id === this.userId && user.position === 'admin'
    );
    if (animator) {
      return 'animator';
    } else {
      return 'participant';
    }
  }
}
