/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IActivity,
  ICard,
  ILabel,
  IList,
  IMember,
} from 'app/modules/workshops/scrumboard/scrumboard.types';

// -----------------------------------------------------------------------------------------------------
// @ Board
// -----------------------------------------------------------------------------------------------------
export class Board implements Required<IActivity> {
  id: string | null;
  name: string;
  description: string | null;
  icon: string | null;
  lastActivity: string | null;
  asRightObject: List[] | null;
  labels: Label[];
  members: Member[];
  value: any;

  /**
   * Constructor
   */
  constructor(board: IActivity) {
    // Labels
    if (board.labels) {
      this.labels = board.labels.map(label => {
        if (!(label instanceof Label)) {
          return new Label(label);
        }

        return label;
      });
    }

    // Members
    if (board.members) {
      this.members = board.members.map(member => {
        if (!(member instanceof Member)) {
          return new Member(member);
        }

        return member;
      });
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ List
// -----------------------------------------------------------------------------------------------------
export class List implements Required<IList> {
  leftObject: {
    id: string | undefined;
    name: string;
    description: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    asRightObject: ICard[] | undefined;
  };

  /**
   * Constructor
   */
  constructor(list: IList) {}
}

// -----------------------------------------------------------------------------------------------------
// @ Card
// -----------------------------------------------------------------------------------------------------
export class Card implements Required<ICard> {
  leftObject: {
    id: string | undefined;
    name: string;
    description: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    priority: string | undefined;
    isCompleted: string | undefined;
    completedDate: string | undefined;
  };
  /**
   * Constructor
   */
  constructor(card: ICard) {}
}

// -----------------------------------------------------------------------------------------------------
// @ Member
// -----------------------------------------------------------------------------------------------------
export class Member implements Required<IMember> {
  id: string | null;
  name: string;
  avatar: string | null;

  /**
   * Constructor
   */
  constructor(member: IMember) {
    this.id = member.id || null;
    this.name = member.name;
    this.avatar = member.avatar || null;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Label
// -----------------------------------------------------------------------------------------------------
export class Label implements Required<ILabel> {
  id: string | null;
  boardId: string;
  title: string;

  /**
   * Constructor
   */
  constructor(label: ILabel) {
    this.id = label.id || null;
    this.boardId = label.boardId;
    this.title = label.title;
  }
}
