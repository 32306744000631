import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ScrumboardService } from 'app/modules/workshops/scrumboard/scrumboard.service';
import { WorkshopService } from 'app/modules/workshops/workshop.activities';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { WorkshopsStoreSelectors } from '.';
import { RootStoreState } from '..';
import * as WorkshopsStoreActions from './actions';

@Injectable()
export class WorkshopsStoreEffects {
  constructor(
    private _store: Store<RootStoreState.State>,
    private _matDialog: MatDialog,
    private _workshopService: WorkshopService,
    private _scrumboardService: ScrumboardService,
    private _actions$: Actions,
    private _router: Router
  ) {}

  loadBoard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.loadBoard),
      exhaustMap(action =>
        this._workshopService
          .getActivity({
            roleType: 'Activity',
            id: action.boardId,
            fields: [
              'id',
              'name',
              'activityType',
              'canvasType',
              'workFlowStep',
              'maxVote',
              'maxVoteParticipant',
              'brainstormColors',
              'value',
            ],
            relations: [
              {
                relation: 'users',
                fields: ['id', 'name', 'email'],
              },
              {
                relation: 'Label',
                fields: ['id', 'name', 'color'],
              },
              {
                relation: 'ActivityList',
                fields: [
                  'id',
                  'name',
                  'description',
                  'x',
                  'y',
                  'h',
                  'w',
                  'minH',
                  'minW',
                ],
                relations: [
                  {
                    relation: 'PostIt',
                    fields: ['id', 'name', 'description', 'color', 'parentId'],
                    relations: [
                      {
                        relation: 'users',
                        fields: ['id', 'name', 'email', 'role'],
                      },
                      {
                        relation: 'tag',
                        fields: ['id', 'name', 'color'],
                      },
                    ],
                  },
                ],
              },
            ],
          })
          .pipe(
            map(board => WorkshopsStoreActions.loadBoardSuccess({ board })),
            catchError(() =>
              of(
                WorkshopsStoreActions.loadBoardFailure({
                  error: 'Échec de chargement du tableau',
                })
              )
            )
          )
      )
    )
  );

  loadBoardFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorkshopsStoreActions.loadBoardFailure),
        tap(() => this._router.navigate([`workshop/brainstorming`]))
      ),
    { dispatch: false }
  );

  updateDisplayLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.updateDisplayLabel),
      withLatestFrom(
        this._store.pipe(select(WorkshopsStoreSelectors.selectCurrentActivity))
      ),
      exhaustMap(([_, board]) => {
        return this._workshopService
          .updateActivity(
            {
              showLabels: _.label,
            },
            board.id
          )
          .pipe(
            map(() => {
              return WorkshopsStoreActions.updateDisplayLabelSuccess({
                label: _.label,
              });
            }),
            catchError(error =>
              of(
                WorkshopsStoreActions.updateDisplayLabelFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  addActivityPostIt$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.addActivityPostIt),
      withLatestFrom(
        this._store.pipe(select(WorkshopsStoreSelectors.selectCurrentActivity))
      ),
      exhaustMap(([_]) => {
        return this._scrumboardService
          .createActivityCard(_.selectedColor, _.listId, _.title)
          .pipe(
            map(response => {
              return WorkshopsStoreActions.addActivityPostItSuccess({
                card: response,
                listId: _.listId,
              });
            }),
            catchError(error =>
              of(
                WorkshopsStoreActions.addActivityPostItFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  toggleShowMembersName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.toggleShowMembersName),
      withLatestFrom(
        this._store.pipe(select(WorkshopsStoreSelectors.selectCurrentActivity))
      ),
      exhaustMap(([_, board]) => {
        return this._workshopService
          .updateActivity(
            {
              showAuthors: _.enabled,
            },
            board.id
          )
          .pipe(
            map(() => {
              return WorkshopsStoreActions.toggleShowMembersNameSuccess({
                enabled: _.enabled,
              });
            }),
            catchError(error =>
              of(
                WorkshopsStoreActions.toggleShowMembersNameFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  usersNavigateChapters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.usersNavigateChapters),
      withLatestFrom(
        this._store.pipe(select(WorkshopsStoreSelectors.selectCurrentActivity))
      ),
      exhaustMap(([_, board]) => {
        return this._workshopService.updateActivity(
          {
            cardboardUsersNavigate: _.navigate,
          },
          board.id
        );
      })
    )
  );

  toggleShowAllCards$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopsStoreActions.toggleShowAllCards),
      withLatestFrom(
        this._store.pipe(select(WorkshopsStoreSelectors.selectCurrentActivity))
      ),
      exhaustMap(([_, board]) => {
        return this._workshopService
          .updateActivity(
            {
              hideAllCards: _.enabled,
            },
            board.id
          )
          .pipe(
            map(() => {
              return WorkshopsStoreActions.toggleShowAllCardsSuccess({
                enabled: _.enabled,
              });
            }),
            catchError(error =>
              of(
                WorkshopsStoreActions.toggleShowAllCardsFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );
}
