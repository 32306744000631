/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const labels = [
  {
    id: 'f47c92e5-20b9-44d9-917f-9ff4ad25dfd0',
    title: 'Brainstorming',
  },
  {
    id: 'e2f749f5-41ed-49d0-a92a-1c83d879e371',
    title: 'Agile workflows',
  },
  {
    id: 'b1cde9ee-e54d-4142-ad8b-cf55dafc9528',
    title: 'Product management',
  },
  {
    id: '6c288794-47eb-4605-8bdf-785b61a449d3',
    title: 'Team building',
  },
  {
    id: 'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
    title: 'Problem solving',
  },
  {
    id: '2dc11344-3507-48e0-83d6-1c047107f052',
    title: 'Strategy',
  },
  {
    id: '2dc11344-3507-48e0-83d6-124356842345',
    title: 'Maturity assessment',
  },
  {
    id: 'customTemplates',
    title: 'Custom templates',
  },
];

export const notes = [
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Blank Brainstorm',
    content: 'Create your own activity with unlimited lists.',
    longContent: 'Create your own activity with unlimited lists.',
    tasks: null,
    image: 'assets/images/canvas/user-persona.png',
    reminder: null,
    labels: [
      'f47c92e5-20b9-44d9-917f-9ff4ad25dfd0',
      'e2f749f5-41ed-49d0-a92a-1c83d879e371',
      'b1cde9ee-e54d-4142-ad8b-cf55dafc9528',
      '6c288794-47eb-4605-8bdf-785b61a449d3',
      'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
      '2dc11344-3507-48e0-83d6-1c047107f052',
    ],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85s2',
    title: 'Reverse Brainstorming',
    content:
      'Flip brainstorming upside down and solve problems by seeing challenges before solutions.',
    tasks: null,
    image: 'assets/images/canvas/reverse-canvas.png',
    reminder: null,
    labels: ['f47c92e5-20b9-44d9-917f-9ff4ad25dfd0'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d8523w',
    title: 'Crazy Eights',
    content: 'Brainstorm 8 solutions in 8 minutes.',
    tasks: null,
    image: 'assets/images/canvas/crazy-8.png',
    reminder: null,
    labels: ['f47c92e5-20b9-44d9-917f-9ff4ad25dfd0'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85dsz',
    title: '4L Retrospective',
    content: 'Reflect on what the team liked, learned, lacked, and longed for.',
    tasks: null,
    image: 'assets/images/canvas/4l.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d8cssz',
    title: 'Retrospective Start, Stop, Continue',
    content:
      'See what the team wants to start, stop and continue doing in the next sprint.',
    tasks: null,
    image: 'assets/images/canvas/start-stop-continue.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7dcsz',
    title: 'Daily Standup',
    content: 'Reveal roadblocks and plan the day of work collaboratively.',
    tasks: null,
    image: 'assets/images/canvas/daily-standup.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcdfds',
    title: 'DAKI retrospective',
    content: 'Identify as a team what you should drop, add, keep, and improve.',
    tasks: null,
    image: 'assets/images/canvas/daki-retro.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcdcdsfr',
    title: 'FLAP retrospective',
    content:
      'Reflect on future considerations, lessons learned, accomplishments, and problems.',
    tasks: null,
    image: 'assets/images/canvas/retro-flap.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7cdser4',
    title: 'Sailboat Retrospective',
    content:
      'Evaluate your previous sprint and determine the best way forward.',
    tasks: null,
    image: 'assets/images/canvas/sailboat.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7cds',
    title: 'Mad, Sad, Glad retrospective',
    content:
      "Dive into the team's feelings and identify ways to improve morale.",
    tasks: null,
    image: 'assets/images/canvas/mad-sad-glad.png',
    reminder: null,
    labels: ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Empathy Map',
    content:
      'Gain a deeper insight into your customers to offer them better services and products.',
    tasks: null,
    image: 'assets/images/canvas/empathy-map.png',
    reminder: null,
    labels: ['b1cde9ee-e54d-4142-ad8b-cf55dafc9528'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'User Persona',
    content:
      'Create a target user profile to better understand their needs and characteristics.',
    tasks: null,
    image: 'assets/images/canvas/user-persona.png',
    reminder: null,
    labels: ['b1cde9ee-e54d-4142-ad8b-cf55dafc9528'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Hopes and fears',
    content:
      "Gauge participants' attitude towards a project or any collaborative engagement.",
    tasks: null,
    image: 'assets/images/canvas/hopes-fears.png',
    reminder: null,
    labels: ['6c288794-47eb-4605-8bdf-785b61a449d3'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: "5 Why's",
    content: 'Analyze and understand the root cause of a problem.',
    tasks: null,
    image: 'assets/images/canvas/five-whys.png',
    reminder: null,
    labels: ['bbc73458-940b-421c-8d5f-8dcd23a9b0d6'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Pros and cons',
    content: 'List the advantages and disadvantages of a decision or idea.',
    tasks: null,
    image: 'assets/images/canvas/pros-cons.png',
    reminder: null,
    labels: ['bbc73458-940b-421c-8d5f-8dcd23a9b0d6'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Problem-Solution Fit Canvas',
    content:
      'Translate problems into solutions with higher chances of solution adoption probability.',
    tasks: null,
    image: 'assets/images/canvas/problem-solution-fit.png',
    reminder: null,
    labels: ['bbc73458-940b-421c-8d5f-8dcd23a9b0d6'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Business Model Canvas',
    content:
      'Get a holistic view of your business to clearly see how it creates and delivers value.',
    tasks: null,
    image: 'assets/images/canvas/business-model.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'SWOT Analysis',
    content:
      "Analyze your company's strengths, weaknesses, opportunities, and threats.",
    tasks: null,
    image: 'assets/images/canvas/swot-analysis.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Lean Canvas',
    content:
      'Brainstorm possible business models following the Lean Startup Methodology',
    tasks: null,
    image: 'assets/images/canvas/lean.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Project Canvas',
    content:
      'Summarize, visualize and share all necessary information about a project.',
    tasks: null,
    image: 'assets/images/canvas/business-model.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'SOAR Analysis',
    content:
      'Focus on your strengths and opportunities, and create a vision of future aspirations and results.',
    tasks: null,
    image: 'assets/images/canvas/soar-analysis.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'PESTLE Analysis',
    content:
      'Analyze the macro-environmental factors that can have an impact on your performance',
    tasks: null,
    image: 'assets/images/canvas/pestle-analysis.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Objectives and Key Results',
    content:
      'Set objectives and key results to create alignment and engagement.',
    tasks: null,
    image: 'assets/images/canvas/okr-canvas.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Experiment Canvas',
    content:
      'Break down your riskiest assumptions into measurable and observable experiments.',
    tasks: null,
    image: 'assets/images/canvas/experiment-canvas.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Product-Market Fit Canvas',
    content:
      'Define, validate and reach your customers to achieve market validation with your product.',
    tasks: null,
    image: 'assets/images/canvas/market-fit.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: "Porter's 5 Forces",
    content: 'Assess direct and indirect competitors based on their strength.',
    tasks: null,
    image: 'assets/images/canvas/five-forces.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Pitch Canvas',
    content: 'Brainstorm, structure and visualize your pitch.',
    tasks: null,
    image: 'assets/images/canvas/pitch-canvas.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
    title: 'Value Chain Canvas',
    content:
      'Identify the relevant stakeholders in your value chains and optimize your business model.',
    tasks: null,
    image: 'assets/images/canvas/value-pitch-canvas.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-1c047107f052'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
  {
    id: '8f011ac5-b71c-4cd7-a317-tsdfrs654',
    title: 'Scrum Team Radar',
    type: 'scrum_team_radar',
    content:
      'Self-assess your scrum practices as a team and plan ways to improve',
    tasks: null,
    image: 'assets/images/canvas/value-pitch-canvas.png',
    reminder: null,
    labels: ['2dc11344-3507-48e0-83d6-124356842345'],
    archived: false,
    createdAt: now.set({ hour: 10, minute: 19 }).minus({ day: 98 }).toISO(),
    updatedAt: null,
  },
];
