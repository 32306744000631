<div class="relative overflow-hidden bg-[#4F46E5] px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
    <!-- Content of the banner -->
    <div class="flex justify-center items-center">
      <div class="flex flex-row items-center gap-x-4 gap-y-2">
        <mat-icon 
           class="icon-size-6 text-white mr-2" 
           [svgIcon]="'heroicons_outline:exclamation-triangle'">
        </mat-icon>
        <p class="text-base leading-6 text-white font-semibold ">
          You are currently editing a template.
          <span *ngIf="isHavingOriginalBoard"> Changes made here will not affect the original board.</span>
        </p>
      </div>
    </div>
  </div>
  