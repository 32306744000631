import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutorizationService {
  constructor(private _httpClient: HttpClient) {}

  getAccessLevel(
    objectId: string,
    userId: string
  ): Observable<{ role: string }> {
    return this._httpClient.get<{ role: string }>(
      `${environment.backendUrl}/v1/can-access/${objectId}/${userId}`,
      { withCredentials: true }
    );
  }
}
