export interface State {
  workshops: any[];
  currentWorkshop?: any;
  currentActivity?: any;
  navbarActions?: any[];
  pending?: boolean;
  error?: string | null;
}

export const initialState: State = {
  workshops: [],
  currentWorkshop: null,
  currentActivity: null,
  navbarActions: [],
  pending: false,
  error: null,
};
