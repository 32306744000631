import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { saveAs as importedSaveAs } from 'file-saver-es';
import { map, tap } from 'rxjs/operators';

import { WorkshopCardboardStoreActions } from '.';
import { RootStoreState } from '../..';
import { CardboardStateModel } from './models/socket-io/cardboard-state.model';
import { CardBoardService } from './services/cardboard.service';

@Injectable()
export class WorkshopCardboardStoreEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<RootStoreState.State>,
    private _matDialog: MatDialog,
    private _cardboardService: CardBoardService
  ) {}

  loadCardboardSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorkshopCardboardStoreActions.loadCardboardSuccess),
        map(action => {
          console.log(action);
          const newState: CardboardStateModel | null =
            this._cardboardService.getItemWithoutRates(action.dimensions);
          if (newState) {
            this._store.dispatch(
              WorkshopCardboardStoreActions.updateState({
                newState,
              })
            );
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  selectArticle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorkshopCardboardStoreActions.selectArticle),
      map(_ =>
        WorkshopCardboardStoreActions.sendRate({
          value: _.rank,
        })
      )
    )
  );

  downloadReportSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WorkshopCardboardStoreActions.downloadReportSuccess),
        tap(_ => {
          importedSaveAs(
            _.report,
            'RAPPORT_' + format(new Date(), 'MMddyyyy_HH:mm:ss')
          );
        })
      ),
    { dispatch: false }
  );
}
