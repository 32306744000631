import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QueryModel } from '@excelway/models/api/query';
import { HeaderModel } from '@excelway/models/layout/header.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private _apiService: ApiService) {}

  getHeader(id: string, roleType: string): Observable<HeaderModel> {
    const query: QueryModel = {
      roleType,
      id,
      fields: [
        'roleTypeName',
        'ParentId',
        'parentId',
        'id',
        'name',
        'description',
        'currency',
        'durationUnit',
        'enableCardCost',
        'color',
        'enableCardDuration',
        'startDate',
        'endDate',
        'activityType',
        'canvasType',
        'startTime',
        'endTime',
        'isPublic',
        'status',
        'value',
        'workFlowStep',
        'context',
      ],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email', 'role', 'email'],
        },
        {
          relation: 'section',
          fields: ['id'],
          relations: [
            {
              relation: 'card',
              fields: ['id', 'cost', 'duration'],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<HeaderModel>(query);
  }
}
