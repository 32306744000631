import * as _ from 'lodash';
import moment from 'moment';

import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  Header,
  ImageRun,
  Packer,
  PageBreak,
  PageNumber,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
} from 'docx';
import { forkJoin } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver-es';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private _httpClient: HttpClient) {
    moment.locale('ar_DZ');
  }

  async docConstruction(
    session: any,
    activities: any,
    detailedActivities: any,
    excelwayLogo: any,
    lang: string
  ): Promise<Document> {
    const ideasAddedByActivity: any[] = [];
    let numberofCardsTotal = 0;
    let sumfVotesTotal = 0;
    const uniqVoters: any[] = [];
    let ideadsThatHaveRatesCount = 0;
    let partcipationPercent;
    let allVotes: any[] = [];
    let brainstomVotes: any[] = [];
    let canvasVotes: any[] = [];
    const firstPageSpaces: any[] = [];
    // let boardObject = null;
    const boardsTasks$: any[] = [];
    const boardsNotes$: any[] = [];
    let boards = [];
    const activityData: any[] = [];
    const colorsData: any[] = [];
    const tasksData: any[] = [];
    const notesData: any[] = [];
    lang == 'fr' ? moment.locale('fr') : moment.locale('en');

    //const activities = session.activities;
    activities.forEach(activity => {
      if (activity.activityType === 'brainstorm') {
        boardsTasks$.push([]);
        boardsNotes$.push([]);
      } else {
        if (activity.activityType === 'canvas') {
          boardsTasks$.push([]);
          boardsNotes$.push([]);
        }
      }
    });
    for (let index = 0; index < 16; index++) {
      firstPageSpaces.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              bold: true,
              allCaps: true,
              size: 66,
              color: '#38CC9D',
            }),
          ],
        })
      );
    }

    session.notes.forEach((note, index) => {
      if (index === 0) {
        notesData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: '____________________________________________________________',
                size: 30,
                color: '#ECECEC',
              }),
            ],
          })
        );
      }
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text:
                lang == 'en'
                  ? 'Added by: ' +
                    session.users.filter(user => user._id === note.userId)[0]
                      ?.firstName +
                    ' ' +
                    session.users.filter(user => user._id === note.userId)[0]
                      ?.lastName +
                    ' at: ' +
                    moment(note.time).format('LT')
                  : 'Ajouté par: ' +
                    session.users.filter(user => user._id === note.userId)[0]
                      ?.firstName +
                    ' ' +
                    session.users.filter(user => user._id === note.userId)[0]
                      ?.lastName +
                    '  à: ' +
                    moment(note.time).format('LT'),
              size: 26,
              color: '#85929E',
              font: 'Helvetica',
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      const html = note.description;
      const div = document.createElement('div');
      div.innerHTML = html;
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: note.title,
              size: 28,
              font: 'Helvetica',
              bold: true,
            }),
          ],
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: div.textContent || div.innerText || '',
              size: 26,
              font: 'Helvetica',
            }),
          ],
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
    });

    session.tasks.forEach((task, index) => {
      if (index === 0) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: '____________________________________________________________',
                size: 30,
                color: '#ECECEC',
              }),
            ],
          })
        );
      }
      const checklists: any[] = [];
      const boardsSent: any[] = [];
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              bold: true,
              size: 20,
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: task.name,
              size: 28,
              font: 'Helvetica',
              bold: true,
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );

      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: task.description,
              size: 26,
              font: 'Helvetica',
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      if (task.dueDate) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: lang == 'en' ? 'Due date: ' : "Date d'échéance: ",
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
              new TextRun({
                text: task.dueDate,
                size: 24,
                font: 'Helvetica',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
                size: 25,
              }),
            ],
          })
        );
      }

      if (task.responsible) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: lang == 'en' ? 'Owner: ' : 'Responsable: ',
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
              new TextRun({
                text:
                  session.users.filter(user => user._id === task.responsible)
                    .length > 0
                    ? session.users.filter(
                        user => user._id === task.responsible
                      )[0].firstName +
                      ' ' +
                      session.users.filter(
                        user => user._id === task.responsible
                      )[0].lastName
                    : lang == 'en'
                    ? 'None'
                    : 'Rien',
                size: 24,
                font: 'Helvetica',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
                size: 25,
              }),
            ],
          })
        );
      }
      task.checklists.forEach(checklist => {
        checklists.push(
          new Paragraph({
            children: [
              new TextRun({
                text: checklist.name,
                size: 24,
                font: 'Helvetica',
              }),
            ],
            bullet: {
              level: 0,
            },
          })
        );
        checklist.checkItems.forEach(checkItem => {
          checklists.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: checkItem.checked ? '✅' : '',
                  size: 24,
                  font: 'Helvetica',
                }),
                new TextRun({
                  text: ' ' + checkItem.name,
                  size: 24,
                  font: 'Helvetica',
                }),
              ],
              bullet: {
                level: 1,
              },
            })
          );
        });
      });
      task.boards.forEach(boardSent => {
        boardsSent.push(
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text:
                  lang == 'en'
                    ? 'Sent to ' + boardSent.name
                    : 'Envoyé à ' + boardSent.name,
                italics: true,
                size: 24,
                font: 'Helvetica',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          })
        );
      });

      if (checklists.length > 0) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: 'Checklist: ',
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          })
        );
        tasksData.push(...checklists);
      }
      tasksData.push(...boardsSent);

      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '____________________________________________________________',
              size: 30,

              color: '#ECECEC',
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ' ',
            }),
          ],
        })
      );
    });
    return forkJoin(...boardsTasks$)
      .toPromise()
      .then(() => {
        boards = detailedActivities;
        if (boards) {
          boards.map((_: any) => {
            ideasAddedByActivity.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: _.name,
                    bold: true,
                    size: 24,
                    font: 'Helvetica',
                  }),
                  new TextRun({
                    text:
                      lang == 'en'
                        ? _.cards.length > 1
                          ? ' ' + _.cards.length + ' Ideas added'
                          : ' ' + _.cards.length + ' Idea added'
                        : _.cards.length > 1
                        ? ' ' + _.cards.length + ' Idées ajoutées'
                        : ' ' + _.cards.length + ' Idée ajoutée',
                    color: '#7E8299',
                    size: 24,
                    font: 'Helvetica',
                  }),
                ],
              })
            );
            if (_.canvasType) {
              _.cards?.map((card: { rates: any[] }) => {
                numberofCardsTotal++;
                if (card.rates.length > 0) {
                  ideadsThatHaveRatesCount++;
                }
                allVotes = [...allVotes, ...card.rates];
                canvasVotes = [...canvasVotes, ...card.rates];
                sumfVotesTotal += card.rates.reduce(
                  (a: any, b: { [x: string]: any }) => a + (b['rate'] || 0),
                  0
                );
                card.rates.forEach(rate => {
                  if (!uniqVoters.includes(rate.user.email)) {
                    uniqVoters.push(rate.user.email);
                  }
                });
              });
            } else {
              _.cards?.map(card => {
                numberofCardsTotal++;
                if (card.rates.length > 0) {
                  ideadsThatHaveRatesCount++;
                }
                allVotes = [...allVotes, ...card.rates];
                brainstomVotes = [...brainstomVotes, ...card.rates];
                sumfVotesTotal += card.rates.reduce(
                  (a, b) => a + (b['rate'] || 0),
                  0
                );
                card.rates.forEach(rate => {
                  if (!uniqVoters.includes(rate.user.email)) {
                    uniqVoters.push(rate.user.email);
                  }
                });
              });
            }
          });

          partcipationPercent = Math.round(
            (uniqVoters.length * 100) / session.participants.length
          );

          boards.forEach((board: any, index) => {
            if (index !== 0) {
              activityData.push(
                new Paragraph({
                  children: [new PageBreak()],
                })
              );
            }
            const activityColors: any[] = [];

            board.labels.forEach(label => {
              activityColors.push(
                new TextRun({
                  text: '⬤',
                  color: label.color,
                  size: 30,
                })
              );

              activityColors.push(
                new TextRun({
                  text: ' ' + label.name + '     ',
                  size: 24,
                  font: 'Helvetica',
                })
              );
            });
            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: lang == 'en' ? 'ACTIVITY' : 'ACTIVITÉ',
                    size: 28,
                    font: 'Helvetica',
                    color: '#7E8299',
                  }),
                ],
              })
            );
            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: board.name,
                    size: 32,
                    font: 'Helvetica',
                    bold: true,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: lang == 'en' ? 'COLOR LABEL' : 'ÉTIQUETTE DE COULEUR',
                    size: 28,
                    font: 'Helvetica',
                    color: '#7E8299',
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [...activityColors],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            board.lists.forEach(list => {
              const tableRows: any[] = [];
              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                })
              );
              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: list.name,
                      bold: true,
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                })
              );

              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                })
              );
              tableRows.push(
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: lang == 'en' ? 'Color' : 'Couleur',
                              size: lang == 'en' ? 24 : 20,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: lang == 'en' ? 'Title' : 'Titre',
                              size: 24,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'Score',
                              size: 24,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                  ],
                })
              );
              _.uniq(list.idCards).forEach(cardId => {
                console.log(board.cards.filter(_ => _.id === cardId)[0]?.color);
                console.log(board.cards.filter(_ => _.id === cardId)[0]);
                if (!board.cards.filter(_ => _.id === cardId)[0]?.color) {
                  tableRows.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: '⬤',
                                  color: '#FFF3C7',
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].name,
                                  size: 24,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.LEFT,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    board.cards.filter(_ => _.id === cardId)[0]
                                      .sum === 0
                                      ? ''
                                      : board.cards
                                          .filter(_ => _.id === cardId)[0]
                                          .sum.toString(),
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ],
                    })
                  );
                } else {
                  tableRows.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: '⬤',
                                  color: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].color,
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].name,
                                  size: 24,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.LEFT,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    board.cards.filter(_ => _.id === cardId)[0]
                                      .sum === 0
                                      ? ''
                                      : board.cards
                                          .filter(_ => _.id === cardId)[0]
                                          .sum.toString(),
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ],
                    })
                  );
                }
              });

              activityData.push(
                new Table({
                  columnWidths: [1000, 7500, 1000],
                  rows: tableRows,
                })
              );
            });
          });

          session.categories.forEach(category => {
            if (!category.Color) {
              colorsData.push(
                new TextRun({
                  text: '⬤',
                  color: '#FFFFFF',
                  size: 30,
                })
              );
            } else {
              colorsData.push(
                new TextRun({
                  text: '⬤',
                  color: category.color,
                  size: 30,
                })
              );
            }

            colorsData.push(
              new TextRun({
                text: ' ' + category.name + '     ',
                size: 24,
                font: 'Helvetica',
              })
            );
          });
        }
        const doc = new Document({
          sections: [
            {
              headers: {
                default: new Header({
                  // The standard default header on every page or header on odd pages when the 'Different Odd & Even Pages' option is activated
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: lang == 'en' ? 'Your logo' : 'Votre logo',
                          size: 50,
                          color: '#DADADA',
                        }),
                      ],
                      alignment: AlignmentType.START,
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '____________________________________________________________',
                          size: 30,

                          color: '#ECECEC',
                        }),
                      ],
                      alignment: AlignmentType.START,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          size: 30,

                          color: '#DADADA',
                        }),
                      ],
                      alignment: AlignmentType.START,
                    }),
                  ],
                }),
              },
              footers: {
                default: new Footer({
                  children: [
                    new Table({
                      columnWidths: [1000, 5000, 3500],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      children: [PageNumber.CURRENT],
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: session.name,
                                      font: 'Helvetica',
                                      size: 20,
                                      color: '#7E8299',
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new ImageRun({
                                      data: excelwayLogo,
                                      transformation: {
                                        width: 180,
                                        height: 50,
                                      },
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.BOTTOM,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                // first: new Footer({
                //   children: [
                //     new TextRun({
                //       children: [''],
                //     }),
                //   ],
                // }),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en' ? 'WORKSHOP REPORT' : "RAPPORT D'ATELIER",
                      size: 70,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: moment(Date.now()).format('MMMM Do, YYYY'),
                      size: 22,
                      font: 'Helvetica',
                      color: '#4A4A4A',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 10,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 10,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                ...firstPageSpaces,
                new Paragraph({
                  children: [
                    new TextRun({
                      text: session.name,
                      font: 'Helvetica',
                      bold: true,
                      allCaps: true,
                      size: 66,
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: session.description,
                      size: 30,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                ...firstPageSpaces,
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en'
                          ? 'Workshop date: '
                          : "Date de l'atelier :",
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text:
                        lang == 'en'
                          ? moment(session.startDate).format('MM/DD/YYYY')
                          : moment(session.startDate).format('DD/MM/YYYY'),
                      size: 25,
                      font: 'Helvetica',
                    }),
                    new TextRun({
                      text: lang == 'en' ? ' to ' : ' à ',
                      font: 'Helvetica',
                      size: 25,
                    }),
                    new TextRun({
                      text:
                        lang == 'en'
                          ? moment(session.endDate).format('MM/DD/YYYY')
                          : moment(session.endDate).format('DD/MM/YYYY'),
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en'
                          ? 'Number of participants: '
                          : 'Nombre de participants:',
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text: session.participants.length,
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en'
                          ? 'Number of activities: '
                          : "Nombre d'activités :",
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text: activities.length,
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: lang == 'en' ? 'SUMMARY' : 'SOMMAIRE',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 40,
                      bold: true,
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 40,
                      bold: true,
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en'
                          ? '' +
                            (numberofCardsTotal > 1
                              ? numberofCardsTotal + ' Ideas'
                              : numberofCardsTotal + ' Idea')
                          : '💡 ' +
                            (numberofCardsTotal > 1
                              ? numberofCardsTotal + ' Idées'
                              : numberofCardsTotal + ' Idée'),
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        lang == 'en'
                          ? '' +
                            (session.tasks.length > 1
                              ? session.tasks.length + ' Tasks'
                              : session.tasks.length + ' Task')
                          : '✔️ ' +
                            (session.tasks.length > 1
                              ? session.tasks.length + ' Tâches'
                              : session.tasks.length + ' Tâche'),
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        '' +
                        (sumfVotesTotal > 1
                          ? sumfVotesTotal + ' Votes'
                          : sumfVotesTotal + ' Vote'),
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                    new TextRun({
                      text:
                        lang == 'en'
                          ? ' for ' +
                            ideadsThatHaveRatesCount +
                            ' ideas by ' +
                            uniqVoters.length +
                            ' participant(s)'
                          : ' pour ' +
                            ideadsThatHaveRatesCount +
                            '  idées de ' +
                            uniqVoters.length +
                            ' participant(s)',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: partcipationPercent
                        ? '' + partcipationPercent + '% participation'
                        : '' + 0 + '% participation',
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                    new TextRun({
                      text:
                        ' (' +
                        uniqVoters.length +
                        '/' +
                        session.participants.length +
                        ') participants.',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,
                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        session.users?.filter(
                          user => user?.position === 'animator'
                        ).length > 1
                          ? lang == 'en'
                            ? 'FACILITATORS'
                            : 'ANIMATEURS'
                          : lang == 'en'
                          ? 'FACILITATOR'
                          : 'ANIMATEUR',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        '' +
                        session.users
                          ?.filter(user => user?.position === 'animator')
                          .map(user =>
                            user?.firstName
                              ? user?.firstName + ' ' + user?.lastName
                              : user?.email
                          ) +
                        ' ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,
                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'PARTICIPANTS',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        ' ' +
                        session.participants.map(user =>
                          ' ' + user?.firstName && user.lastName
                            ? ' ' + user?.firstName + ' ' + user?.lastName
                            : ' ' + user?.email
                        ),
                      font: 'Helvetica',
                      size: 24,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,

                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: lang == 'en' ? 'ACTIVITIES' : 'ACTIVITÉS',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                ...ideasAddedByActivity,
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: lang == 'en' ? 'ACTIVITIES' : 'ACTIVITÉS',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,

                      color: '#ECECEC',
                    }),
                  ],
                }),
                new TextRun({
                  text: '',
                  bold: true,
                  size: 40,

                  color: '#7E8299',
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...activityData,
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: lang == 'en' ? 'TASKS' : 'TÂCHES',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...tasksData,
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: lang == 'en' ? 'NOTES' : 'NOTES',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...notesData,
              ],
            },
          ],
        });
        return doc;
      });
  }

  async docConstructionAr(
    session: any,
    activities: any,
    detailedActivities: any,
    excelwayLogo: any
  ): Promise<any> {
    const ideasAddedByActivity: any[] = [];
    let numberofCardsTotal = 0;
    let sumfVotesTotal = 0;
    const uniqVoters: any[] = [];
    let ideadsThatHaveRatesCount = 0;
    let partcipationPercent;
    let allVotes: any[] = [];
    let brainstomVotes: any[] = [];
    let canvasVotes: any[] = [];
    const firstPageSpaces: any[] = [];
    // let boardObject = null;
    const boardsTasks$: any[] = [];
    const boardsNotes$: any[] = [];
    let boards = [];
    const activityData: any[] = [];
    const colorsData: any[] = [];
    const tasksData: any[] = [];
    const notesData: any[] = [];
    moment.locale('ar_DZ');

    // const activities = session.activities;
    activities.forEach(activity => {
      if (activity.activityType === 'brainstorm') {
        boardsTasks$.push([]);
        boardsNotes$.push([]);
      } else {
        if (activity.activityType === 'canvas') {
          boardsTasks$.push([]);
          boardsNotes$.push([]);
        }
      }
    });
    for (let index = 0; index < 16; index++) {
      firstPageSpaces.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              bold: true,
              allCaps: true,
              size: 66,
              color: '#38CC9D',
            }),
          ],
        })
      );
    }

    session.notes.forEach((note, index) => {
      if (index === 0) {
        notesData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: '____________________________________________________________',
                size: 30,
                color: '#ECECEC',
              }),
            ],
          })
        );
      }
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text:
                'تمت إضافته بواسطة: ' +
                session.users.filter(user => user._id === note.userId)[0]
                  ?.firstName +
                ' ' +
                session.users.filter(user => user._id === note.userId)[0]
                  ?.lastName +
                ' الساعة: ' +
                moment(note.time).format('LT'),
              size: 26,
              color: '#85929E',
              font: 'Helvetica',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      const html = note.description;
      const div = document.createElement('div');
      div.innerHTML = html;
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: note.title,
              size: 28,
              font: 'Helvetica',
              bold: true,
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: div.textContent || div.innerText || '',
              size: 26,
              font: 'Helvetica',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      notesData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
    });

    session.tasks.forEach((task, index) => {
      if (index === 0) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: '____________________________________________________________',
                size: 30,
                color: '#ECECEC',
              }),
            ],
          })
        );
      }
      const checklists: any[] = [];
      const boardsSent: any[] = [];
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              bold: true,
              size: 20,
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: task.name,
              size: 28,
              font: 'Helvetica',
              bold: true,
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );

      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: task.description,
              size: 26,
              font: 'Helvetica',
            }),
          ],
          alignment: AlignmentType.RIGHT,
        })
      );
      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              size: 40,
              color: '#85929E',
            }),
          ],
        })
      );
      if (task.dueDate) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: ' تاريخ الاستحقاق: ',
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
              new TextRun({
                text: task.dueDate,
                size: 24,
                font: 'Helvetica',
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
                size: 25,
              }),
            ],
          })
        );
      }

      if (task.responsible) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: 'المالك: ',
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
              new TextRun({
                text:
                  session.users.filter(user => user._id === task.responsible)
                    .length > 0
                    ? session.users.filter(
                        user => user._id === task.responsible
                      )[0].firstName +
                      ' ' +
                      session.users.filter(
                        user => user._id === task.responsible
                      )[0].lastName
                    : 'لا أحد',
                size: 24,
                font: 'Helvetica',
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
                size: 25,
              }),
            ],
          })
        );
      }
      task.checklists.forEach(checklist => {
        checklists.push(
          new Paragraph({
            children: [
              new TextRun({
                text: checklist.name,
                size: 24,
                font: 'Helvetica',
              }),
              new TextRun({
                text: ' ● ',
                size: 24,
                font: 'Helvetica',
              }),
            ],
            alignment: AlignmentType.RIGHT,
          })
        );
        checklist.checkItems.forEach(checkItem => {
          checklists.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: ' ' + checkItem.name,
                  size: 24,
                  font: 'Helvetica',
                }),
                new TextRun({
                  text: checkItem.checked ? ' ✅ ' : '',
                  size: 24,
                  font: 'Helvetica',
                }),
                new TextRun({
                  text: ' ○ ',
                  size: 24,
                  font: 'Helvetica',
                }),
              ],
              alignment: AlignmentType.RIGHT,
            })
          );
        });
      });
      task.boards.forEach(boardSent => {
        boardsSent.push(
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' مرسلة إلى ' + boardSent.name,
                italics: true,
                size: 24,
                font: 'Helvetica',
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          })
        );
      });

      if (checklists.length > 0) {
        tasksData.push(
          new Paragraph({
            children: [
              new TextRun({
                text: ':قائمة مرجعية',
                size: 24,
                font: 'Helvetica',
                color: '#7E8299',
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: ' ',
              }),
            ],
          })
        );
        tasksData.push(...checklists);
      }
      tasksData.push(...boardsSent);

      tasksData.push(
        new Paragraph({
          children: [
            new TextRun({
              text: '____________________________________________________________',
              size: 30,

              color: '#ECECEC',
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ' ',
            }),
          ],
        })
      );
    });
    return forkJoin(...boardsTasks$)
      .toPromise()
      .then(() => {
        boards = detailedActivities;
        if (boards) {
          boards.map((_: any) => {
            ideasAddedByActivity.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: _.name,
                    bold: true,
                    size: 24,
                    font: 'Helvetica',
                  }),
                  new TextRun({
                    text:
                      _.cards.length > 1
                        ? ' ' + _.cards.length + ' أفكار مضافة '
                        : ' ' + _.cards.length + ' فكرة مضافة',
                    color: '#7E8299',
                    size: 20,
                    font: 'Helvetica',
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              })
            );
            if (_.canvasType) {
              _.cards?.map((card: { rates: any[] }) => {
                numberofCardsTotal++;
                if (card.rates.length > 0) {
                  ideadsThatHaveRatesCount++;
                }
                allVotes = [...allVotes, ...card.rates];
                canvasVotes = [...canvasVotes, ...card.rates];
                sumfVotesTotal += card.rates.reduce(
                  (a: any, b: { [x: string]: any }) => a + (b['rate'] || 0),
                  0
                );
                card.rates.forEach((rate: any) => {
                  if (!uniqVoters.includes(rate?.user?.email)) {
                    uniqVoters.push(rate.user.email);
                  }
                });
              });
            } else {
              _.cards?.map(card => {
                numberofCardsTotal++;
                if (card.rates.length > 0) {
                  ideadsThatHaveRatesCount++;
                }
                allVotes = [...allVotes, ...card.rates];
                brainstomVotes = [...brainstomVotes, ...card.rates];
                sumfVotesTotal += card.rates.reduce(
                  (a, b) => a + (b['rate'] || 0),
                  0
                );
                card.rates.forEach(rate => {
                  if (!uniqVoters.includes(rate.user.email)) {
                    uniqVoters.push(rate.user.email);
                  }
                });
              });
            }
          });

          partcipationPercent = Math.round(
            (uniqVoters.length * 100) / session.participants.length
          );

          boards.forEach((board: any, index) => {
            if (index !== 0) {
              activityData.push(
                new Paragraph({
                  children: [new PageBreak()],
                })
              );
            }
            const activityColors: any[] = [];
            board.labels.forEach(label => {
              activityColors.push(
                new TextRun({
                  text: '⬤',
                  color: label.color,
                  size: 30,
                })
              );

              activityColors.push(
                new TextRun({
                  text: ' ' + label.name + '     ',
                  size: 24,
                  font: 'Helvetica',
                })
              );
            });
            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'النشاط',
                    size: 28,
                    font: 'Helvetica',
                    color: '#7E8299',
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              })
            );
            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: board.name,
                    size: 32,
                    font: 'Helvetica',
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'تسمية اللون',
                    size: 28,
                    font: 'Helvetica',
                    color: '#7E8299',
                  }),
                ],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            activityData.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              }),
              new Paragraph({
                children: [...activityColors],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                    size: 30,
                  }),
                ],
              })
            );

            board.lists.forEach(list => {
              const tableRows: any[] = [];
              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                })
              );
              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: list.name,
                      bold: true,
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                })
              );

              activityData.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                })
              );
              tableRows.push(
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'النتيجة',
                              size: 24,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'العنوان',
                              size: 24,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'اللون',
                              size: 24,
                              font: 'Helvetica',
                              color: '#7E8299',
                              bold: true,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        bottom: 25,
                      },
                    }),
                  ],
                })
              );
              _.uniq(list.idCards).forEach(cardId => {
                console.log(board.cards.filter(_ => _.id === cardId)[0]?.color);
                console.log(board.cards.filter(_ => _.id === cardId)[0]);
                if (!board.cards.filter(_ => _.id === cardId)[0]?.color) {
                  tableRows.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    board.cards.filter(_ => _.id === cardId)[0]
                                      .sum === 0
                                      ? ''
                                      : board.cards
                                          .filter(_ => _.id === cardId)[0]
                                          .sum.toString(),
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].name,
                                  size: 24,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: '⬤',
                                  color: '#FFF3C7',
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ],
                    })
                  );
                } else {
                  tableRows.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    board.cards.filter(_ => _.id === cardId)[0]
                                      .sum === 0
                                      ? ''
                                      : board.cards
                                          .filter(_ => _.id === cardId)[0]
                                          .sum.toString(),
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].name,
                                  size: 24,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: '⬤',
                                  color: board.cards.filter(
                                    _ => _.id === cardId
                                  )[0].color,
                                  size: 25,
                                  font: 'Helvetica',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ],
                    })
                  );
                }
              });

              activityData.push(
                new Table({
                  columnWidths: [1000, 7500, 1000],
                  rows: tableRows,
                })
              );
            });
          });

          session.categories.forEach(category => {
            if (!category.Color) {
              colorsData.push(
                new TextRun({
                  text: '⬤',
                  color: '#FFFFFF',
                  size: 30,
                })
              );
            } else {
              colorsData.push(
                new TextRun({
                  text: '⬤',
                  color: category.color,
                  size: 30,
                })
              );
            }

            colorsData.push(
              new TextRun({
                text: ' ' + category.name + '     ',
                size: 24,
                font: 'Helvetica',
              })
            );
          });
        }
        const doc = new Document({
          sections: [
            {
              headers: {
                default: new Header({
                  // The standard default header on every page or header on odd pages when the 'Different Odd & Even Pages' option is activated
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: 'شعارك',
                          size: 50,
                          color: '#DADADA',
                        }),
                      ],
                      alignment: AlignmentType.END,
                    }),

                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '____________________________________________________________',
                          size: 30,

                          color: '#ECECEC',
                        }),
                      ],
                      alignment: AlignmentType.START,
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          size: 30,

                          color: '#DADADA',
                        }),
                      ],
                      alignment: AlignmentType.START,
                    }),
                  ],
                }),
              },
              footers: {
                default: new Footer({
                  children: [
                    new Table({
                      columnWidths: [1000, 5000, 3500],
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      children: [PageNumber.CURRENT],
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: session.name,
                                      font: 'Helvetica',
                                      size: 20,
                                      color: '#7E8299',
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new ImageRun({
                                      data: excelwayLogo,
                                      transformation: {
                                        width: 180,
                                        height: 50,
                                      },
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.BOTTOM,
                              borders: {
                                top: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: 'DADADA',
                                },
                                bottom: {
                                  style: BorderStyle.DOUBLE,
                                  size: 0,
                                  color: '0000FF',
                                },
                                left: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '00FF00',
                                },
                                right: {
                                  style: BorderStyle.DASH_DOT_STROKED,
                                  size: 0,
                                  color: '#ff8000',
                                },
                              },
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                // first: new Footer({
                //   children: [
                //     new TextRun({
                //       children: [''],
                //     }),
                //   ],
                // }),
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'تقرير ورشة العمل',
                      size: 70,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: moment(Date.now()).format('MMMM Do, YYYY'),
                      size: 22,
                      font: 'Helvetica',
                      color: '#4A4A4A',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 10,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 10,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                }),
                ...firstPageSpaces,
                new Paragraph({
                  children: [
                    new TextRun({
                      text: session.name,
                      font: 'Helvetica',
                      bold: true,
                      allCaps: true,
                      size: 66,
                      color: '#38CC9D',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: session.description,
                      size: 30,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                ...firstPageSpaces,
                new Paragraph({
                  children: [
                    new TextRun({
                      text: ' تاريخ الورشة : ',
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text: moment(session.startDate).format('MMMM Do, YYYY'),
                      size: 25,
                      font: 'Helvetica',
                    }),
                    new TextRun({
                      text: '  إلى  ',
                      font: 'Helvetica',
                      size: 25,
                    }),
                    new TextRun({
                      text: moment(session.endDate).format('MMMM Do, YYYY'),
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: ' عدد المشاركين : ',
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text: session.participants.length,
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'عدد الأنشطة : ',
                      size: 25,
                      font: 'Helvetica',
                      color: '#85929E',
                    }),
                    new TextRun({
                      text: activities.length,
                      size: 25,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'ملخص',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 40,
                      bold: true,
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      size: 40,
                      bold: true,
                      color: '#38CC9D',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: numberofCardsTotal + ' : الأفكار ' + ' 💡 ',
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: session.tasks.length + ' : المهام ' + ' ✔️ ',
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: ' الأصوات : ' + sumfVotesTotal,
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                    new TextRun({
                      text: ' ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                    new TextRun({
                      text:
                        ' ل ' +
                        (ideadsThatHaveRatesCount == 1
                          ? ' فكرة واحدة بواسطة '
                          : ideadsThatHaveRatesCount + ' أفكار بواسطة ') +
                        (uniqVoters.length != 1
                          ? uniqVoters.length + ' مشاركين '
                          : 'مشارك واحد') +
                        ' 🗳️ ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: partcipationPercent
                        ? ' مشاركة ' + '%' + partcipationPercent
                        : ' مشاركة ' + '%' + 0,
                      size: 28,
                      bold: true,
                      font: 'Helvetica',
                      color: '#38CC9D',
                    }),
                    new TextRun({
                      text: ' ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                    new TextRun({
                      text:
                        '(' +
                        uniqVoters.length +
                        '/' +
                        session.participants.length +
                        ') مشاركين' +
                        '  📈  ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,
                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        session.users?.filter(
                          user => user?.position === 'animator'
                        ).length > 1
                          ? 'الميسرين'
                          : 'الميسّرْ',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        '' +
                        session.users
                          ?.filter(user => user?.position === 'animator')
                          .map(user =>
                            user?.firstName
                              ? user?.firstName + ' ' + user?.lastName
                              : user?.email
                          ) +
                        ' ',
                      size: 24,
                      font: 'Helvetica',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,
                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,

                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'المشاركين',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#85929E',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        ' ' +
                        session.participants.map(user =>
                          user?.firstName && user.lastName
                            ? user?.firstName + ' ' + user?.lastName
                            : user?.email
                        ),
                      font: 'Helvetica',
                      size: 24,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,

                      color: '#ECECEC',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                    }),
                  ],
                }),

                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'الأنشطة',
                      size: 28,
                      font: 'Helvetica',
                      color: '#7E8299',
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                      bold: true,
                      size: 40,
                      color: '#7E8299',
                    }),
                  ],
                }),
                ...ideasAddedByActivity,
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'الأنشطة',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '____________________________________________________________',
                      size: 30,

                      color: '#ECECEC',
                    }),
                  ],
                }),
                new TextRun({
                  text: '',
                  bold: true,
                  size: 40,

                  color: '#7E8299',
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...activityData,
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'المهام',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...tasksData,
                new PageBreak(),
              ],
            },
            {
              properties: {},
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: 'الملاحظات',
                      size: 52,
                      font: 'Helvetica',
                      color: '#85929E',
                      characterSpacing: 2,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
                ...notesData,
              ],
            },
          ],
        });
        return doc;
      });
  }

  getDocxReport(
    session: any,
    activity: any,
    activities: any,
    lang: string,
    format: string
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    console.log(session);
    console.log(activity);
    console.log(lang);
    console.log(format);
    this._httpClient
      .get('assets/images/logo/logo-color-h.png', { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = (): void => {
          const base64data = reader.result;
          if (lang == 'ar') {
            that
              .docConstructionAr(session, activity, activities, base64data)
              .then(result => {
                Packer.toBuffer(result).then(buffer => {
                  const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  });
                  const arrayOfBlob = new Array<Blob>();
                  arrayOfBlob.push(blob);

                  const formData = new FormData();

                  // The third parameter is required for server
                  formData.append('file', blob, 'example007.docx');
                  formData.append('format', format);
                  const file = new File([blob], 'example007.doc');
                  const formData2 = new FormData();

                  // The third parameter is required for server
                  formData2.append('file_test', file);
                  if (format === 'google docs') {
                    importedSaveAs(blob, session.name + '_report.docx');
                  } else {
                    if (format === 'pdf') {
                      this._httpClient
                        .post(
                          'https://document-converter-api.excelway.co/convert',
                          formData,
                          { responseType: 'blob' }
                        )
                        .subscribe(file => {
                          importedSaveAs(
                            file,
                            session.name + '_report.' + format
                          );
                        });
                    } else {
                      this._httpClient
                        .post(
                          'https://report-fixer.excelway.co/doc',
                          formData2,
                          { responseType: 'blob' }
                        )
                        .subscribe(file => {
                          importedSaveAs(
                            file,
                            session.name + '_report.' + format
                          );
                        });
                    }
                  }
                });
              });
          }
          if (lang != 'ar') {
            that
              .docConstruction(session, activity, activities, base64data, lang)
              .then(result => {
                Packer.toBlob(result).then(blob => {
                  // const blob = new Blob(buffer, {
                  //     type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  // });
                  const formData = new FormData();

                  // The third parameter is required for server
                  formData.append('file', blob, 'example007.docx');
                  formData.append('format', format);
                  const file = new File([blob], 'example007.doc');
                  const formData2 = new FormData();

                  // The third parameter is required for server
                  formData2.append('file_test', file);
                  if (format === 'google docs') {
                    importedSaveAs(blob, session.name + '_report.doc');
                  } else {
                    if (format === 'pdf') {
                      this._httpClient
                        .post(
                          'https://document-converter-api.excelway.co/convert',
                          formData,
                          { responseType: 'blob' }
                        )
                        .subscribe(file => {
                          importedSaveAs(
                            file,
                            session.name + '_report.' + format
                          );
                        });
                    } else {
                      this._httpClient
                        .post(
                          'https://report-fixer.excelway.co/doc',
                          formData2,
                          { responseType: 'blob' }
                        )
                        .subscribe(file => {
                          importedSaveAs(
                            file,
                            session.name + '_report.' + format
                          );
                        });
                    }
                  }
                });
              });
          }
        };
        reader.readAsDataURL(res);
      });
  }
}
