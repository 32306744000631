import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'missing-verification-banner',
  templateUrl: './missing-verification-banner.component.html',
})
export class MissingVerificationBannerComponent {
  redirect(): void {
    const email = localStorage.getItem('email');
    window.open(`${environment.verificationUrl}?email=${email}`);
  }
}
