<form [formGroup]="cardFieldsForm">
  <div class="flex flex-col gap-y-4 px-20">
    <div class="flex fex-row justify-between">
      <span>Cost</span>
      <mat-slide-toggle
        formControlName="cost"
        (change)="updateCost(costButton.checked)"
        [ngClass]="'custom-green-toggle'"
        #costButton></mat-slide-toggle>
    </div>
    <div class="flex fex-row justify-between">
      <span>Duration</span>
      <mat-slide-toggle
        formControlName="duration"
        (change)="updateDuration(durationButton.checked)"
        [ngClass]="'custom-green-toggle'"
        #durationButton></mat-slide-toggle>
    </div>
  </div>
</form>
