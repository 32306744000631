import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, from, of, switchMap } from 'rxjs';

import { AuthService } from '@excelway/services/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.check(window.location.hash);
  }

  private check(fragment: string): Observable<boolean | UrlTree> {
    // When in production mode, check the authentication status
    if (environment.production) {
      return from(this._authService.checkSession()).pipe(
        switchMap(authenticated => {
          // If the user is not authenticated...
          if (!authenticated) {
            const redirectionPath = `${environment.appBaseUrl}/${fragment}`;

            // Redirect to the login page with the intended path as a query parameter
            const loginUrlWithRedirect = `${environment.loginUrl}?return_to=${redirectionPath}`;

            window.location.href = loginUrlWithRedirect;
          }
          // Allow the access
          return of(true);
        })
      );
    } else return from(this._authService.mockCheck());
  }
}
