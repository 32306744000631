import { Component, Inject, OnInit } from '@angular/core';
import { UserModel } from '@excelway/models/user/user.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/common-dialogs/common.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { coEditorsInputConfig } from '@excelway/constants/taggychips.config';
import { addMemberEvent } from 'app/modules/scrumboard/card/details/details.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'template-team',
  templateUrl: './team.component.html',
})
export class TemplateTeamComponent implements OnInit {
  constructor(
    private _commonService: CommonService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    this.memberForm = this.formBuilder.group({
      memberEmail: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/),
        ],
      ],
    });
  }
  memberForm: FormGroup;
  templateUsers: UserModel[];
  projectId: string;
  workspaceUsers: UserModel[];
  email: string;
  roleTypeParentId: string;
  coEditorsInputConfig = coEditorsInputConfig;

  ngOnInit(): void {
    this.getTemplateBoardUsers(this.data.id);
    this.getWorkspaceUsers();
  }

  getTemplateBoardUsers(id: string): void {
    const query = {
      roleType: 'Board',
      id: id,
      fields: ['id', 'name'],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email'],
        },
      ],
    };

    this._commonService.getObjectAndUsers(query).subscribe(data => {
      this.templateUsers = data.users;
    });
  }

  getWorkspaceUsers(): void {
    const query = {
      roleType: 'Workspace',
      id: 'cllvf0xr0002itbywx6naxhod',
      fields: ['id', 'name'],
      relations: [
        {
          relation: 'users',
          fields: ['id', 'name', 'email'],
        },
      ],
    };

    this._commonService.getObjectAndUsers(query).subscribe(data => {
      this.workspaceUsers = data.users;
    });
  }

  addMember(
    id: string,
    roleTypeParentName?: string,
    roleTypeParentId?: string
  ): void {
    if (this.memberForm.valid) {
      const memberData = {
        id: id,
        email: this.email,
        relation: 'edits',
        redirectTo: window.location.href,
        roleTypeParentName: roleTypeParentName,
        roleTypeParentId: roleTypeParentId,
      };
      this._commonService
        .addMember(memberData, this.data.id, 'Board')
        .subscribe(
          () => {
            this.getTemplateBoardUsers(this.data.id);
            this.memberForm.reset();
            this.toastr.success('Member invited successfully');
          },
          () => {
            this.toastr.error(
              'Oops! Something went wrong. Please try again later.'
            );
          }
        );
    }
  }

  onAddMember(event: addMemberEvent): void {
    this.email = event.value.email;
    this.memberForm.get('memberEmail')?.setValue(event.value.email);
  }
  removeMember(memberEmail: string, memberId: string): any {
    const removememberData = {
      email: memberEmail,
      userId: memberId,
    };
    this._commonService
      .removeMember(removememberData, this.data.id, 'Board')
      .toPromise()
      .then(() => {
        this.getTemplateBoardUsers(this.data.id);
      });
  }
}
