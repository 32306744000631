import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { ReceivedEvent, SentEvent } from '@excelway/models/socket-io/event';
import { AuthUser } from '@excelway/types/auth-user.types';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private socket: Socket) {
    this.subscribeToStatus();
  }

  joinRoom(roomId: string, user: AuthUser): void {
    try {
      this.socket.emit('join-room', { roomId, user });
    } catch (error) {
      console.error('Got error when trying to join a room', error);
    }
  }

  leaveRoom(roomId: string, user: AuthUser): void {
    try {
      this.socket.emit('leave-room', { roomId, user });
    } catch (error) {
      console.error('Got error when trying to leave a room', error);
    }
  }

  publishEvent(event: SentEvent): void {
    try {
      this.socket.emit('events', event);
    } catch (error) {
      console.error(error);
    }
  }

  getEvents(): Observable<ReceivedEvent> {
    return this.socket.fromEvent<ReceivedEvent>('events');
  }
  getDeleteEventType(roleTypeName: string): string {
    // Implement a switch case or any logic to determine the eventType based on roleTypeName
    switch (roleTypeName) {
      case 'section':
        return 'delete-section';
      case 'board':
        return 'delete-board';
      // Add more cases
      default:
        return 'default-eventType';
    }
  }
  // Get socket status (connected , reconnect , disconnected  )
  private subscribeToStatus(): void {
    this.socket.ioSocket.on('connect', () => {
      console.log('Connected to realtime server...');
    });

    this.socket.ioSocket.on('reconnect', () => {
      console.log('Reconnected to realtime server...');
    });

    this.socket.ioSocket.on('disconnect', () => {
      console.log('Disconnected from the realtime server...');
    });
  }
}
