export const icons = [
  'academic-cap',
  'banknotes',
  'beaker',
  'book-open',
  'clipboard',
  'user',
  'user-group',
  'paint-brush',
  'puzzle-piece',
  'rocket-launch',
  'face-smile',
  'face-frown',
  'building-storefront',
  'code-bracket-square',
  'briefcase',
  'star',
  'light-bulb',
  'language',
  'paper-clip',
  'key',
  'building-library',
  'globe-asia-australia',
  'information-circle',
  'document',
  'flag',
  'megaphone',
  'trophy',
  'computer-desktop',
];

export function getRandomIcon(): string {
  const randomIndex = Math.floor(Math.random() * icons.length);
  return icons[randomIndex];
}
