import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AuthService } from '@excelway/services/auth.service';
import { AutorizationService } from '@excelway/services/autorization.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[authorizeToManageMembers]',
  standalone: true,
})
export class BoardsMembersAuthorizationDirective implements OnInit, OnDestroy {
  @Input('authorizeToManageMembers') objectId: string;
  private subscription: Subscription;
  role: string;

  constructor(
    private _permissionService: AutorizationService,
    private renderer: Renderer2,
    private el: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.getAuthUser().then(identity => {
      if (identity) {
        this.subscription = this._permissionService
          .getAccessLevel(this.objectId, identity.id)
          .subscribe(
            response => {
              this.role = response.role;
              if (this.role === 'read' || this.role === 'write') {
                if (
                  this.el.nativeElement.tagName === 'INPUT' ||
                  this.el.nativeElement.tagName === 'TEXTAREA' ||
                  this.el.nativeElement.tagName === 'SELECT' ||
                  this.el.nativeElement.tagName === 'BUTTON'
                ) {
                  this.renderer.setAttribute(
                    this.el.nativeElement,
                    'disabled',
                    'true'
                  );
                  this.el.nativeElement.style.opacity = '0.5';
                } else {
                  this.disableElement();
                }
              }
            },
            () => {}
          );
      }
    });
  }

  private disableElement(): void {
    this.el.nativeElement.style.pointerEvents = 'none';
    this.el.nativeElement.style.opacity = '0.5';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
