import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Board,
  Card,
  Label,
  List,
} from 'app/modules/workshops/scrumboard/scrumboard.models';
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScrumboardService {
  // Private
  private _board: BehaviorSubject<Board | null>;
  private _boards: BehaviorSubject<Board[] | null>;
  private _card: BehaviorSubject<Card | null>;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._board = new BehaviorSubject(null);
    this._boards = new BehaviorSubject(null);
    this._card = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for board
   */
  get board$(): Observable<Board | null> {
    return this._board.asObservable();
  }

  /**
   * Getter for boards
   */
  get boards$(): Observable<Board[] | null> {
    return this._boards.asObservable();
  }

  /**
   * Getter for card
   */
  get card$(): Observable<Card | null> {
    return this._card.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get boards
   */
  getBoards(): Observable<Board[]> {
    return this._httpClient.get<Board[]>('api/scrumboard/boards').pipe(
      map(response => response.map((item: any) => new Board(item))),
      tap(boards => this._boards.next(boards))
    );
  }

  /**
   * Get board
   *
   * @param id
   */
  getBoard(id: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/levels/board/` + id,
      { withCredentials: true }
    );
  }

  getActivity(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }
  /**
   * Create board
   *
   * @param board
   */
  createBoard(board: Board): Observable<Board> {
    return this.boards$.pipe(
      take(1),
      switchMap((boards: any) =>
        this._httpClient.put<Board>('api/scrumboard/board', { board }).pipe(
          map(newBoard => {
            // Update the boards with the new board
            this._boards.next([...boards, newBoard]);

            // Return new board from observable
            return newBoard;
          })
        )
      )
    );
  }

  /**
   * Update the board
   *
   * @param id
   * @param board
   */
  updateBoard(id: string, board: Board): Observable<Board> {
    return this.boards$.pipe(
      take(1),
      switchMap((boards: any) =>
        this._httpClient
          .patch<Board>('api/scrumboard/board', {
            id,
            board,
          })
          .pipe(
            map(updatedBoard => {
              // Find the index of the updated board
              const index = boards.findIndex(item => item.id === id);

              // Update the board
              boards[index] = updatedBoard;

              // Update the boards
              this._boards.next(boards);

              // Return the updated board
              return updatedBoard;
            })
          )
      )
    );
  }

  /**
   * Create RoleType
   *
   * @param RoleTypeParent
   * @param RoleTypeParent
   *  @param RoleTypeParent
   *
   *
   */
  createRoleType(
    RoleTypeParent: any,
    idRoleTypeParent: string,
    data: any,
    RoleType: any,
    relation: string
  ): Observable<any> {
    console.log(data);

    return this._httpClient.post(
      `${environment.backendUrl}/v1/${RoleTypeParent}/${idRoleTypeParent}/${relation}/${RoleType}`,
      data,
      { withCredentials: true }
    );
  }

  /**
   * Delete the board
   *
   * @param id
   */
  deleteBoard(id: string): Observable<boolean> {
    return this.boards$.pipe(
      take(1),
      switchMap((boards: any) =>
        this._httpClient
          .delete('api/scrumboard/board', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted board
              const index = boards.findIndex(item => item.id === id);

              // Delete the board
              boards.splice(index, 1);

              // Update the boards
              this._boards.next(boards);

              // Update the board
              this._board.next(null);

              // Update the card
              this._card.next(null);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  /**
   * Create list
   *
   * @param list
   */
  createActivityList(boardId: string, titel: string): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/activity/${boardId}/is_component_of/ActivityList`,
      { name: titel },
      { withCredentials: true }
    );
  }

  /**
   * Update the list
   *
   * @param list
   */
  updateList(list: List): Observable<List> {
    return this._httpClient
      .patch<List>('api/scrumboard/board/list', { list })
      .pipe(
        map((response: any) => new List(response)),
        tap(updatedList => {
          // Get the board value
          const board = this._board.value;

          // Find the index of the updated list
          const index = board?.asRightObject?.findIndex(
            item => item.leftObject.id === list.leftObject.id
          ) as any;

          // Update the list
          if (board && board.asRightObject) {
            board.asRightObject[index] = updatedList;
          }

          // Sort the board asRightObject
          // board.asRightObject.sort((a, b) => a.position - b.position);

          // Update the board
          this._board.next(board);
        })
      );
  }

  /**
   * Update the asRightObject
   *
   * @param asRightObject
   */
  updateasRightObject(asRightObject: List[]): Observable<List[]> {
    return this._httpClient
      .patch<List[]>('api/scrumboard/board/asRightObject', { asRightObject })
      .pipe(
        map(response => response.map((item: any) => new List(item))),
        tap(updatedasRightObject => {
          // Get the board value
          const board = this._board.value;

          // Go through the updated asRightObject
          updatedasRightObject.forEach((updatedList: any) => {
            // Find the index of the updated list
            const index = board?.asRightObject?.findIndex(
              item => item.leftObject.id === updatedList.leftObject.id
            ) as any;

            // Update the list
            if (board && board.asRightObject) {
              board.asRightObject[index] = updatedList;
            }
          });

          // Sort the board asRightObject
          // board.asRightObject.sort((a, b) => a.position - b.position);

          // Update the board
          this._board.next(board);
        })
      );
  }

  /**
   * Delete the list
   *
   * @param id
   */
  deleteList(id: string): Observable<boolean> {
    return this._httpClient
      .delete<boolean>('api/scrumboard/board/list', { params: { id } })
      .pipe(
        tap(() => {
          // Get the board value
          const board = this._board.value;

          // Find the index of the deleted list
          const index = board?.asRightObject?.findIndex(
            item => item.leftObject.id === id
          ) as any;

          // Delete the list
          board?.asRightObject?.splice(index, 1);

          // Sort the board asRightObject
          // board.asRightObject.sort((a, b) => a.position - b.position);

          // Update the board
          this._board.next(board);
        })
      );
  }

  /**
   * Get card
   */
  getCard(id: string): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/levels/card/` + id,
      { withCredentials: true }
    );
  }

  /**
   * Get postIt
   */
  getPostIt(id: string): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/levels/postit/` + id,
      { withCredentials: true }
    );
  }
  /**
   * Get object
   */
  getObject(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  /**
   * Create card
   *
   * @param card
   */
  createActivityCard(
    color: string,
    sectionId: string,
    card: string
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/ActivityList/${sectionId}/is_component_of/PostIt`,
      {
        name: card,
        description: 'description of card',
        color: color,
        priority: 'NA',
      },
      { withCredentials: true }
    );
  }

  updateActivityPosIt(cardData: any, cardId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/postit/${cardId}`,
      cardData
    );
  }
  /**
   * Update the card
   *
   * @param id
   * @param card
   */
  updateCard(id: string, card: Card): Observable<Card> {
    return this.board$.pipe(
      take(1),
      switchMap(board =>
        this._httpClient
          .patch<Card>('api/scrumboard/board/card', {
            id,
            card,
          })
          .pipe(
            map((updatedCard: any) => {
              // Find the card and update it
              board?.asRightObject?.forEach(listItem => {
                listItem?.leftObject?.asRightObject?.forEach(
                  (cardItem, index, array) => {
                    if (cardItem.leftObject.id === id) {
                      array[index] = updatedCard;
                    }
                  }
                );
              });

              // Update the board
              this._board.next(board);

              // Update the card
              this._card.next(updatedCard);

              // Return the updated card
              return updatedCard;
            })
          )
      )
    );
  }

  /**
   * Update the cards
   *
   * @param cards
   */
  updateCards(cards: Card[]): Observable<Card[]> {
    return this._httpClient
      .patch<Card[]>('api/scrumboard/board/cards', { cards })
      .pipe(
        map(response => response.map((item: any) => new Card(item))),
        tap(updatedCards => {
          // Get the board value
          const board = this._board.value;

          // Go through the updated cards
          updatedCards.forEach(() => {
            // Find the index of the updated card's list
            // const listIndex = board.asRightObject.findIndex(list => list.leftObject.id === updatedCard.listId);
            // Find the index of the updated card
            // const cardIndex = board.asRightObject[listIndex].cards.findIndex(item => item.id === updatedCard.id);
            // Update the card
            // board.asRightObject[listIndex].cards[cardIndex] = updatedCard;
            // Sort the cards
            // board.asRightObject[listIndex].cards.sort((a, b) => a.position - b.position);
          });

          // Update the board
          this._board.next(board);
        })
      );
  }

  /**
   * Delete the card
   *
   * @param id
   */
  deleteCard(id: string): Observable<boolean> {
    return this.board$.pipe(
      take(1),
      switchMap(board =>
        this._httpClient
          .delete('api/scrumboard/board/card', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the card and delete it
              board?.asRightObject?.forEach(listItem => {
                listItem?.leftObject?.asRightObject?.forEach(
                  (cardItem, index, array) => {
                    if (cardItem.leftObject.id === id) {
                      array.splice(index, 1);
                    }
                  }
                );
              });

              // Update the board
              this._board.next(board);

              // Update the card
              this._card.next(null);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  /**
   * Create label
   *
   * @param label
   */
  createLabel(label: Label): Observable<Label> {
    return this.board$.pipe(
      take(1),
      switchMap((board: any) =>
        this._httpClient
          .post<Label>('api/scrumboard/board/label', { label })
          .pipe(
            map(newLabel => {
              // Update the board labels with the new label
              board.labels = [...board.labels, newLabel];

              // Update the board
              this._board.next(board);

              // Return new label from observable
              return newLabel;
            })
          )
      )
    );
  }

  /**
   * Update the label
   *
   * @param id
   * @param label
   */
  updateLabel(id: string, label: Label): Observable<Label> {
    return this.board$.pipe(
      take(1),
      switchMap((board: any) =>
        this._httpClient
          .patch<Label>('api/scrumboard/board/label', {
            id,
            label,
          })
          .pipe(
            map(updatedLabel => {
              // Find the index of the updated label
              const index = board.labels.findIndex(item => item.id === id);

              // Update the label
              board.labels[index] = updatedLabel;

              // Update the board
              this._board.next(board);

              // Return the updated label
              return updatedLabel;
            })
          )
      )
    );
  }

  /**
   * Delete the label
   *
   * @param id
   */
  deleteLabel(id: string): Observable<boolean> {
    return this.board$.pipe(
      take(1),
      switchMap((board: any) =>
        this._httpClient
          .delete('api/scrumboard/board/label', { params: { id } })
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted label
              const index = board.labels.findIndex(item => item.id === id);

              // Delete the label
              board.labels.splice(index, 1);

              // Update the board
              this._board.next(board);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  /**
   * Search within board cards
   *
   * @param query
   */
  search(query: string): Observable<Card[] | null> {
    // @TODO: Update the board cards based on the search results
    return this._httpClient.get<Card[] | null>('api/scrumboard/board/search', {
      params: { query },
    });
  }
}
