import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './actions';
import { initialState, State } from './state';

const authReducer = createReducer(
  initialState,
  on(AuthActions.updateCurrentUser, (state, { user }) => ({
    ...state,
    user,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return authReducer(state, action);
}
