import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootStoreState } from '..';

@Injectable()
export class BoardsStoreEffects {
  constructor(
    private _store: Store<RootStoreState.State>,
    private _matDialog: MatDialog,
    private _actions$: Actions,
    private _router: Router
  ) {}
}
