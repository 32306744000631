<div
  class="relative isolate flex items-center gap-x-6 overflow-hidden bg-[#FF9966] px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
  <div
    class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
    aria-hidden="true"></div>
  <div
    class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
    aria-hidden="true"></div>
  <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#ffffff"
      class="w-6 h-6">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
    </svg>

    <p class="text-sm leading-6 text-white">
      <strong class="font-semibold"
        >Please confirm your email by clicking on the button, and we will send a
        verification code</strong
      >
    </p>

    <button
      class="bg-[#FF9966] hover:text-[#FF9966] hover:bg-white text-white font-semibold py-1 px-2 border border-white-500 hover:border-transparent rounded text-sm"
      (click)="redirect()">
      Send code
    </button>
  </div>
  <div class="flex flex-1 justify-end">
    <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
      <span class="sr-only">Dismiss</span>
    </button>
  </div>
</div>
