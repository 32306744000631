import { Action, createReducer, on } from '@ngrx/store';

import * as WorkshopsStoreActions from './actions';
import { initialState, State } from './state';

const workshopsReducer = createReducer(
  initialState,
  on(WorkshopsStoreActions.getWorkshop, state => ({
    ...state,
    pending: true,
  })),
  on(WorkshopsStoreActions.getWorkshopSuccess, (state, { workshop }) => ({
    ...state,
    currentWorkshop: workshop,
    pending: false,
  })),
  on(WorkshopsStoreActions.getWorkshopFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
  on(WorkshopsStoreActions.getCurrentActivity, state => ({
    ...state,
    pending: true,
  })),
  on(WorkshopsStoreActions.loadBoardSuccess, (state, { board }) => ({
    ...state,
    currentActivity: board,
  })),
  on(WorkshopsStoreActions.updateDisplayLabelSuccess, (state, { label }) => ({
    ...state,
    currentActivity: {
      ...state.currentActivity,
      value: {
        ...state.currentActivity.value,
        showLabels: label,
      },
    },
  })),
  on(
    WorkshopsStoreActions.toggleShowMembersNameSuccess,
    (state, { enabled }) => ({
      ...state,
      currentActivity: {
        ...state.currentActivity,
        value: {
          ...state.currentActivity.value,
          showAuthors: enabled,
        },
      },
    })
  ),
  on(
    WorkshopsStoreActions.addActivityPostItSuccess,
    (state, { card, listId }) => ({
      ...state,
      currentActivity: {
        ...state.currentActivity,
        ActivityList: [
          ...state.currentActivity.ActivityList.map(list => {
            if (list.id === listId) {
              list.PostIt.push(card.createdChildObject);
            }
            return list;
          }),
        ],
      },
    })
  ),
  on(WorkshopsStoreActions.toggleShowAllCardsSuccess, (state, { enabled }) => ({
    ...state,
    currentActivity: {
      ...state.currentActivity,
      value: {
        ...state.currentActivity.value,
        hideAllCards: enabled,
      },
    },
  })),
  on(
    WorkshopsStoreActions.getCurrentActivitySuccess,
    (state, { activity }) => ({
      ...state,
      currentActivity: activity,
      pending: false,
    })
  ),
  on(WorkshopsStoreActions.getCurrentActivityFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
  on(WorkshopsStoreActions.getWorkshops, state => ({
    ...state,
    pending: true,
  })),
  on(WorkshopsStoreActions.getWorkshopsSuccess, (state, { workshops }) => ({
    ...state,
    workshops,
    pending: false,
  })),
  on(WorkshopsStoreActions.getWorkshopsFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return workshopsReducer(state, action);
}
