import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectSettingsService {
  constructor(private _httpClient: HttpClient) {}

  // Add member
  addMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/add_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // update member
  updateMember(memberdata: any, roleTypeObjectId): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/edit_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // remove member
  removeMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/remove_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  //get project and users (dynamic webservice )
  getProjectsAndUsers(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      {
        withCredentials: true,
      }
    );
  }

  //update object details
  //
  updateObjectDetails(
    objectRoleType: string,
    objectId: string,
    objectData: any
  ): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/${objectRoleType}/${objectId}`,
      objectData,
      { withCredentials: true }
    );
  }

  //add workshop
  createProject(projectData: any): Observable<any> {
    const Customerid = 'clivkh7ei0000tb2scg5tzq3z';
    return this._httpClient.post(
      `${environment.backendUrl}/v1/Customer/${Customerid}/belongs_to/project`,
      projectData,
      { withCredentials: true }
    );
  }

  //add activity
  createActivity(projectData: any, projectId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/project/${projectId}/is_part_of/board`,
      projectData,
      { withCredentials: true }
    );
  }
  //
  // Get all workshops by user
  //
  getallWorkshops(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/project`,
      {
        withCredentials: true,
      }
    );
  }
  //
  // Get all workshops by user
  //
  accessWorkshop(id: any): Observable<any> {
    return this._httpClient.get<any>(
      '${environment.backendUrl}/v1/levels/project/' + id,
      { withCredentials: true }
    );
  }
  //
  // Get all workshops by user
  //
  getactivityColors(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      {
        withCredentials: true,
      }
    );
  }

  //
  addTask(colorData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/board/${acitivityId}/is_part_of/task`,
      colorData,
      { withCredentials: true }
    );
  }

  updateTask(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/task/${projectId}`,
      projectData
    );
  }

  getSectionCards(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      {
        withCredentials: true,
      }
    );
  }
  //
  //
  addactivityColor(colorData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/board/${acitivityId}/is_part_of/label`,
      colorData,
      { withCredentials: true }
    );
  }

  //update workshop
  //
  updateBoard(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/board/${projectId}`,
      projectData
    );
  }

  getworkshopBoard(id: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/levels/project/` + id,
      { withCredentials: true }
    );
  }
}
