import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
} from '@angular/core';
import { AuthzService } from '@excelway/services/authz.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[enableByRole]',
  standalone: true,
})
export class EnableByRoleDirective implements OnInit, OnDestroy {
  @Input('enableByRole') allowedRoles: string[] = [];
  private subscription: Subscription;
  private role: string | null = null;

  constructor(
    private _authzService: AuthzService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription = this._authzService.role$.subscribe(role => {
      this.role = role;
      this.updateElementVisibility();
    });

    // Initial check
    this.updateElementVisibility();
  }

  private updateElementVisibility(): void {
    if (this.role === null || !this.allowedRoles) {
      return;
    }

    const tagName = this.el.nativeElement.tagName;
    const isRoleAllowed = this.allowedRoles.includes(this.role);

    if (!isRoleAllowed) {
      if (tagName === 'BUTTON' || tagName === 'DIV' || tagName === 'FORM') {
        this.renderer.setStyle(
          this.el.nativeElement,
          'display',
          'none',
          RendererStyleFlags2.Important
        );
      } else if (
        tagName === 'INPUT' ||
        tagName === 'TEXTAREA' ||
        tagName === 'SELECT'
      ) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.el.nativeElement.style.opacity = '0.5';
        this.el.nativeElement.style.pointerEvents = 'none';
      } else {
        this.disableElement();
      }
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'display');
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      this.el.nativeElement.style.opacity = '';
      this.el.nativeElement.style.pointerEvents = '';
    }

    this.cdr.detectChanges();
  }

  private disableElement(): void {
    this.el.nativeElement.style.pointerEvents = 'none';
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.el.nativeElement.style.opacity = '0.7';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
