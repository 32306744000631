import { createAction, props } from '@ngrx/store';

export const getBoards = createAction('[Boards][API] Get Boards List');

export const getBoardsSuccess = createAction(
  '[Boards][API] Get Boards List Success',
  props<{ boards: any[] }>()
);

export const getBoardsFailure = createAction(
  '[Boards][API] Get Boards List Failure',
  props<{ error: any }>()
);

export const getBoard = createAction(
  '[Boards][API] Get Board By Id',
  props<{ id: string }>()
);

export const getBoardSuccess = createAction(
  '[Boards][API] Get Board By Id Success',
  props<{ board: any }>()
);

export const getBoardFailure = createAction(
  '[Boards][API] Get Board By Id Failure',
  props<{ error: any }>()
);
