export interface State {
  boards: any[];
  currentBoard?: any;
  navbarActions?: any[];
  pending?: boolean;
  error?: string | null;
}

export const initialState: State = {
  boards: [],
  currentBoard: null,
  navbarActions: [],
  pending: false,
  error: null,
};
