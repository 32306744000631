import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthUser } from '@excelway/types/auth-user.types';
import { State } from './state';

export const selectAuthState = createFeatureSelector<State>('auth');

export const selectLoggedUser = createSelector(
  selectAuthState,
  (state: State): AuthUser | null => state.user
);

export const selectLoggedUserId = createSelector(
  selectAuthState,
  (state: State): string | null => (state.user ? state.user.id : null)
);
