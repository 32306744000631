export interface NavigationItem {
  id: string;
  title: string;
  type: string;
  icon: string;
  link: string;
  img: string;
  button?: { title: string; icon: string; classes: string };
}

export interface State {
  items: NavigationItem[];
  projects: any[];
}

export const initialState: State = {
  items: [
    // {
    //   id: 'Home',
    //   title: 'Accueil',
    //   type: 'basic',
    //   icon: 'heroicons_outline:home',
    //   link: '/home',
    //   img: 'assets/images/logo-high.png',
    // },
    {
      id: 'My Tasks',
      title: 'Mon travail',
      type: 'basic',
      icon: 'heroicons_outline:chart-pie',
      link: '/mytasks',
      img: 'assets/images/logo-high.png',
    },
    {
      id: 'Projects',
      title: 'Espaces',
      type: 'basic',
      icon: 'heroicons_outline:view-list',
      link: '/projects',
      img: '',
      button: {
        title: 'Add Project',
        icon: 'heroicons_outline:plus-sm',
        classes: '',
      },
    },
  ],
  projects: [],
};
// customer.state.ts
export interface Customer {
  id: string;
  customer: string;
  context: string;
  dataOwner: string;
  roleTypeName: string;
  name: string;
  description: string;
  startDate: string | null;
  endDate: string | null;
  isArchived: boolean;
  value: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export interface CustomerState {
  customers: Customer[];
}

export const initialCustomerState: CustomerState = {
  customers: [],
};
