import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { membersInvitation } from '@excelway/constants/taggychips.config';
import { QueryModel } from '@excelway/models/api/query';
import { AuthUser } from '@excelway/types/auth-user.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';

import { CommonService } from 'app/common-dialogs/common.services';
import { NotificationsStore } from 'app/layout/common/notifications/notifications.store';
import { AdminConsoleService } from 'app/modules/admin-console/admin-console.service';
import { AuthStoreSelectors } from 'app/store/auth';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'settings-team-board',
  templateUrl: './team.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(NotificationsStore)],
})
export class SettingsTeamComponent implements OnInit {
  // get logged user
  user: AuthUser;
  private userSubscription: Subscription;
  readonly user$: Observable<AuthUser | null> = this._store.select(
    AuthStoreSelectors.selectLoggedUser
  );
  users: any[] = [];
  roles = [
    {
      label: 'Admin',
      value: 'admin',
      description:
        'Can manage members, delete board, edit board content, fields and items.',
    },
    {
      label: 'Editor',
      value: 'write',
      description:
        'Can edit board fields, create, edit, or delete sections and items.',
    },
    {
      label: 'Viewer',
      value: 'read',
      description:
        'Can view board details, members, sections, items, and use filters.',
    },
  ];
  memberForm: FormGroup;
  @Input() projectId = '';
  workspaceUsers: any[] = [];
  oldAccessLevel: string;
  invitedUsers: any[] = [];
  invitedUserGroups: any[] = [];
  usersRequestData: { id: string; email: string; redirectTo: string }[] = [];
  returnUrl = encodeURIComponent(window.location.href);
  membersInvitation = membersInvitation;
  objects: any[];
  directRelations: any[] = [];
  teams: any[] = [];
  members: any[];
  userGroups: any[] = [];
  @Input() role: string;

  /**
   * Constructor
   */
  constructor(
    private formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _adminConsoleService: AdminConsoleService,
    private readonly _store: Store,
    private notificationsStore: NotificationsStore,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    this.memberForm = this.formBuilder.group({
      role: ['read', [Validators.required]],
    });
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.userSubscription = this.user$.subscribe(user => {
      this.user = user as AuthUser;
    });
    this.getBoardAndUsers();
    this.loadWorkspaceUsers();
    //this.loadTeams();
    //this.getObjectUserGroups();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getObjectUserGroups = (): void => {
    this._commonService.getObjectMembers(this.data.id).subscribe(res => {
      this.userGroups = res;
      this.mergeMembers();
      this._changeDetectorRef.detectChanges();
    });
  };

  captureOldAccessLevel(role: string): void {
    this.oldAccessLevel = role;
  }

  onAdd(event: { value: any }): void {
    if (event.value.email) {
      this.invitedUsers.push(event.value);
    } else {
      this.invitedUserGroups.push(event.value);
    }
  }

  onRemove(event: { value: any }): void {
    if (event.value.email) {
      this.invitedUsers = this.invitedUsers.filter(
        user => user.email !== event.value.email
      );
    } else {
      this.invitedUserGroups = this.invitedUserGroups.filter(
        userGroup => userGroup.id !== event.value.id
      );
    }
  }

  loadTeams = (): void => {
    if (this.data.context) {
      this._adminConsoleService
        .getTeamsWithMemberCounts(this.data.context)
        .subscribe(teams => {
          this.teams = teams.map(team => ({
            id: team.id,
            name: team.name,
            createdAt: team.createdAt,
            members: team.memberCount,
            color: team.color,
          }));
          this.mergeObjects();
          this._changeDetectorRef.detectChanges();
        });
    }
  };

  mergeObjects(): void {
    this.objects = [...this.teams, ...this.workspaceUsers];
  }

  mergeMembers(): void {
    this.members = [...this.users, ...this.userGroups];
  }

  loadWorkspaceUsers = (): void => {
    const query: QueryModel = {
      roleType: 'Workspace',
      id: this.data.context,
      fields: ['id', 'name'],
      relations: [
        {
          relation: 'User',
          fields: ['id', 'name', 'email'],
        },
      ],
    };

    this._commonService.getObjectAndUsers(query).subscribe(user => {
      this.workspaceUsers = this.removeDuplicateUsers(user.User);
      this.mergeObjects();
      this._changeDetectorRef.detectChanges();
    });
  };

  removeDuplicateUsers = (users: any[]): any[] => {
    const uniqueUsers = users.reduce((acc, current) => {
      const x = acc.find(user => user.email === current.email);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniqueUsers;
  };

  getBoardAndUsers(): any {
    this._commonService
      .getObjectMembersByDirectRelation(this.data.id)
      .subscribe(
        user => {
          this.users = user;
          this.markSoleAdmin();
          this.mergeMembers();
          this._changeDetectorRef.markForCheck();
        },
        error => {
          console.error('Error', error);
        }
      );
  }

  updateMember(userId: string, role: string): any {
    const memberData = {
      roleTypeId: this.data.id,
      userId: userId,
      newAccessLevel: role,
      oldAccessLevel: this.oldAccessLevel,
    };
    this._commonService.updateMemberRole(memberData).subscribe(() => {
      this.toastr.success('Role updated successfully');
      this.getBoardAndUsers();
      this._changeDetectorRef.detectChanges();
    });
  }

  markSoleAdmin(): void {
    const admins = this.users.filter(user => user.role === 'admin');
    this.users = this.users.map(user => ({
      ...user,
      soleAdmin: user.role === 'admin' && admins.length === 1,
    }));
  }

  invite = (): void => {
    if (this.invitedUserGroups.length > 0 && this.invitedUsers.length > 0) {
      this.toastr.error("You can't invite both teams and users.");
      return;
    } else if (this.invitedUserGroups.length > 0) {
      this.inviteUserGroups();
    } else if (this.invitedUsers.length > 0) {
      this.inviteUsers();
    } else {
      this.toastr.warning('No users or teams to invite.');
    }
  };

  update = (member: any): void => {
    if (!member) {
      this.toastr.error('Something went wrong. Please try again');
      return;
    } else if (member.email) {
      this.updateMember(member.id, member.role);
    } else if (member.color) {
      this.updateUserGroupRole(member);
    } else {
      this.toastr.warning('No user or team to update its role.');
    }
  };

  remove = (member: any): void => {
    if (!member) {
      this.toastr.error('Something went wrong. Please try again');
      return;
    } else if (member.email) {
      this.openDeleteConfirmationForDeleteUser(
        member.id,
        this.data.id,
        member.role
      );
    } else if (member.color) {
      this.openDeleteConfirmationForDeleteUserGroup(member);
    } else {
      this.toastr.warning('No user or team to update its role.');
    }
  };

  updateUserGroupRole(userGroup: any): void {
    const body = {
      role: userGroup.role,
      userGroupId: userGroup.id,
    };
    this._adminConsoleService
      .updateUserGroupAccessLevelToObject(this.data.id, body)
      .pipe(
        catchError(error => {
          console.error('Error updating access level:', error);
          this.toastr.error(
            'Failed to update access level. Please try again later.'
          );
          return of(null);
        })
      )
      .subscribe(() => {
        this.toastr.success('Role updated');
        this.getObjectUserGroups();
        this._changeDetectorRef.detectChanges();
      });
  }

  openDeleteConfirmationForDeleteUser(
    userId: string,
    roleTypeId: string,
    role: string
  ): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete member',
      message:
        'Are you sure you want to delete this member ? This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
      },
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((confirmed: string) => {
      if (confirmed === 'confirmed') {
        this.removeMember(userId, roleTypeId, role);
      }
    });
  }

  openDeleteConfirmationForDeleteUserGroup = (member: any): void => {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete team',
      message:
        'Are you sure you want to delete this team? This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
      },
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((confirmed: string) => {
      if (confirmed === 'confirmed') {
        this.removeProjectFromUserGroup(member);
      }
    });
  };

  removeProjectFromUserGroup = (userGroup: any): void => {
    const body = {
      userId: undefined,
      userGroupId: userGroup.id,
    };
    this._adminConsoleService
      .removeObjectFromUserGroupOrUser(this.data.id, body)
      .subscribe(() => {
        this.toastr.success(`${userGroup.name} has been deleted successfully`);
        this.getObjectUserGroups();
        this._changeDetectorRef.detectChanges();
      });
  };

  removeMember(userId: string, roleTypeId: string, role: string): any {
    const removeMemberData = {
      userId: userId,
      roleTypeId: roleTypeId,
      role: role,
    };
    this._commonService.removeMemberRelation(removeMemberData).subscribe(() => {
      this.users = this.users.filter(user => user.id !== userId);
      this.getBoardAndUsers();
      this._changeDetectorRef.markForCheck();
      this.markSoleAdmin();
    });
  }

  async inviteUsers(): Promise<void> {
    const alreadyInUsers = this.invitedUsers.some(invitedUser =>
      this.users.some(existingUser => existingUser.email === invitedUser.email)
    );

    if (alreadyInUsers) {
      this.toastr.error('One or more users are already in the project.');
      this.memberForm.reset();
      return;
    }
    await this._commonService.inviteUsers(
      this.invitedUsers,
      this.data.id,
      'Board',
      this.data.context,
      this.memberForm.get('role')?.value
    );
    this.memberForm.reset();
    this.invitedUsers.forEach(user => this.onRemove({ value: user }));
    this.getBoardAndUsers();
    this._changeDetectorRef.detectChanges();
  }

  async inviteUserGroups(): Promise<void> {
    this._commonService
      .checkUserGroupsMemberRelations({
        roleTypeId: this.data.id,
        roleTypeName: 'Board',
        userGroups: this.invitedUserGroups,
      })
      .subscribe((res: any) => {
        this.directRelations = res;

        // If no direct relations, proceed to invitation process directly
        if (this.directRelations.length === 0) {
          this.processInvitations();
          return;
        }

        // If there are direct relations, show confirmation dialog
        const emails = this.directRelations
          .map((relation: any) => relation.email)
          .join(' / ');
        const confirmation = this._fuseConfirmationService.open({
          title: 'Membres Déja Présents',
          message: `Les utilisateurs ( ${emails} ) sont déjà associés à ce tableau.
              En ajoutant via cette équipe, leurs roles individuels seront remplacés par ceux définis pour l'équipe.`,
          actions: {
            confirm: {
              label: 'Confirmer',
            },
            cancel: {
              show: true,
              label: 'Annuler',
            },
          },
        });

        confirmation.afterClosed().subscribe(async (confirmed: string) => {
          if (confirmed === 'confirmed') {
            this.processInvitations();
          }
        });
      });
  }

  private async processInvitations(): Promise<void> {
    const body = {
      roleTypeId: this.data.id,
      roleTypeName: 'Board',
      userGroups: this.invitedUserGroups,
      role: this.memberForm.get('role')?.value,
      context: this.data.context,
      defaultRedirection: window.location.href,
    };

    await this._commonService
      .addMultipleUserGroups(body)
      .subscribe(async res => {
        await this._commonService.inviteUsers(
          res.users,
          this.data.id,
          'Board',
          this.data.context,
          this.memberForm.get('role')?.value
        );
      });

    this.memberForm.reset();
    this.invitedUserGroups.forEach(userGroup =>
      this.onRemove({ value: userGroup })
    );
    this.getBoardAndUsers();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
