import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import { MissingVerificationBannerComponent } from './missing-verification-banner.component';

@NgModule({
  declarations: [MissingVerificationBannerComponent],
  imports: [CommonModule, SharedModule, MatIconModule],
  exports: [MissingVerificationBannerComponent],
})
export class MissingVerificationBannerModule {}
