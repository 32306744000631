import { createAction, props } from '@ngrx/store';

export const getProjects = createAction('[Projects][API] Get Projects List');

export const getProjectsSuccess = createAction(
  '[Projects][API] Get Projects List Success',
  props<{ projects: any[] }>()
);

export const getProjectsFailure = createAction(
  '[Projects][API] Get Projects List Failure',
  props<{ error: any }>()
);

export const getProject = createAction(
  '[Projects][API] Get Project By Id',
  props<{ id: string }>()
);

export const getProjectSuccess = createAction(
  '[Projects][API] Get Project By Id Success',
  props<{ project: any }>()
);

export const getProjectFailure = createAction(
  '[Projects][API] Get Project By Id Failure',
  props<{ error: any }>()
);
