import { Action, createReducer, on } from '@ngrx/store';

import { WorkshopCardboardStoreActions } from '.';
import { CardBoardService } from './services/cardboard.service';
import { initialState, State } from './state';

const cardboardReducer = createReducer(
  initialState,
  on(() => initialState),
  on(WorkshopCardboardStoreActions.updateState, (state, { newState }) => ({
    ...state,
    dimension: newState.dimension,
    chapters: newState.dimension.chapters,
    chapter: newState.chapter,
    items: newState.chapter.items,
    item: newState.item,
    articles: newState.articles,
  })),
  on(WorkshopCardboardStoreActions.flipAll, state => ({
    ...state,
    articles: CardBoardService.flipAll(state.articles),
  })),
  on(WorkshopCardboardStoreActions.flipBackAll, state => ({
    ...state,
    articles: CardBoardService.flipBackAll(state.articles),
  })),
  on(WorkshopCardboardStoreActions.hideAll, state => ({
    ...state,
    articles: CardBoardService.hideAll(state.articles),
  })),
  on(WorkshopCardboardStoreActions.showAll, state => ({
    ...state,
    articles: CardBoardService.showAll(state.articles),
  })),
  on(WorkshopCardboardStoreActions.shuffle, state => ({
    ...state,
    articles: CardBoardService.shuffle(
      state.articles,
      state.initialArticles,
      state.itemsShuffled
    ),
    initialArticles:
      state.initialArticles.length !== 0
        ? state.initialArticles
        : state.articles,
    itemsShuffled: state.itemsShuffled ? false : true,
  })),
  on(WorkshopCardboardStoreActions.next, state => {
    const newState = CardBoardService.next(state);
    return {
      ...state,
      ...newState,
    };
  }),
  on(WorkshopCardboardStoreActions.previous, state => {
    const newState = CardBoardService.previous(state);
    return {
      ...state,
      ...newState,
    };
  }),
  on(WorkshopCardboardStoreActions.selectArticle, (state, { id }) => ({
    ...state,
    selectedArticle: id,
  })),
  on(WorkshopCardboardStoreActions.updateDimension, (state, { id }) => {
    const dimension: any = state.dimensions.find(_ => _._id === id);
    return {
      ...state,
      dimension,
      chapters: dimension.chapters,
      chapter: dimension.chapters[0],
      items: dimension.chapters[0].hasItems
        ? dimension.chapters[0].items
        : null,
      item: dimension.chapters[0].hasItems
        ? dimension.chapters[0].items[0]
        : null,
      articles: dimension.chapters[0].hasItems
        ? dimension.chapters[0].items[0].articles
        : dimension.chapters[0].articles,
    };
  }),
  on(WorkshopCardboardStoreActions.updateChapter, (state, { id }) => {
    const chapter: any = state.chapters.find(_ => _._id === id);
    return {
      ...state,
      chapter,
      items: chapter.hasItems ? chapter.items : null,
      item: chapter.hasItems ? chapter.items[0] : null,
      articles: chapter.hasItems ? chapter.items[0].articles : chapter.articles,
    };
  }),
  on(WorkshopCardboardStoreActions.updateItem, (state, { id }) => {
    const item: any = state.items?.find(_ => _._id === id);
    return {
      ...state,
      item,
      articles: item.articles,
    };
  }),
  on(
    WorkshopCardboardStoreActions.loadCardboardSuccess,
    (state, { dimensions }) => ({
      ...state,
      dimensions: dimensions,
      dimension: dimensions[0],
      chapters: dimensions[0].chapters,
      chapter: dimensions[0].chapters[0],
      items: dimensions[0].chapters[0].hasItems
        ? dimensions[0].chapters[0].items
        : [],
      item: dimensions[0]?.chapters[0]?.hasItems
        ? dimensions[0].chapters[0].items?.[0] || undefined
        : undefined,
      articles: dimensions[0]?.chapters[0]?.hasItems
        ? dimensions[0]?.chapters[0]?.items?.[0]?.articles ||
          dimensions[0]?.chapters[0]?.articles ||
          []
        : dimensions[0]?.chapters[0]?.articles || [],
    })
  ),

  on(
    WorkshopCardboardStoreActions.loadCardboardChartSuccess,
    (state, { results }) => {
      return {
        ...state,
        globalChart: results,
      };
    }
  ),
  on(WorkshopCardboardStoreActions.sendRate, (state, { value }) => ({
    ...state,
    articles: [
      ...state.articles.map(_ => {
        if (_.rank === value) {
          return {
            ..._,
            selected: true,
          };
        }
        return {
          ..._,
          selected: false,
        };
      }),
    ],
  })),
  on(WorkshopCardboardStoreActions.flipCard, (state, { value, card }) => {
    return {
      ...state,
      articles: state.articles.map(_ => {
        if (card._id === _._id) {
          return {
            ..._,
            flipped: value,
          };
        }
        return {
          ..._,
        };
      }),
    };
  }),
  on(WorkshopCardboardStoreActions.downloadReport, state => {
    return {
      ...state,
      pendingReport: true,
    };
  }),
  //   on(WorkshopActivitiesStoreActions.loadSuccess, state => initialState),
  on(WorkshopCardboardStoreActions.downloadReportSuccess, state => {
    return {
      ...state,
      pendingReport: false,
    };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return cardboardReducer(state, action);
}
