<div class="w-full h-full flex flex-col items-center">
  <div class="w-11/12 flex items-start gap-x-2 mt-8 py-5 text-xl">
    <span
      class="cursor-pointer hover:text-emerald-400 flex items-center"
      [routerLink]="['/user-interface/list']">
      <mat-icon>arrow_back</mat-icon>
      Interface List
    </span>
  </div>

  <div class="w-11/12 h-14 flex justify-between items-center py-5">
    <div class="relative flex w-full flex-wrap items-center items-stretch w-60">
      <input
        type="search"
        placeholder="Enter field name ..."
        aria-label="Search"
        aria-describedby="button-addon1"
        class="relative m-0 -mr-0 block w-44 flex-auto border-2 border-neutral-50 bg-white bg-clip-padding px-1.5 py-2 text-base font-normal text-neutral-700 rounded-l-md" />
      <button
        class="relative flex items-center bg-neutral-50 px-2 py-1.5 rounded-r-md cursor-text"
        data-te-ripple-color="light">
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:search'"></mat-icon>
      </button>
    </div>
    <div class="relative flex h-10 w-full min-w-[200px] max-w-[24rem]">
      <button
        [matTooltip]="
          fields.length === 0
            ? 'Add new field first'
            : selectedLanguage === '' || allLanguages.includes(selectedLanguage)
            ? 'This language already exists'
            : 'Add new language'
        "
        [matTooltipPosition]="'above'"
        (click)="addLanguage(selectedLanguage); selectedLanguage = ''"
        class="!absolute right-1 top-1 z-10 select-none rounded bg-emerald-400 hover:bg-emerald-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
        type="button"
        [disabled]="
          selectedLanguage === '' || allLanguages.includes(selectedLanguage)
        "
        data-ripple-light="true">
        + Add
      </button>
      <select
        [(ngModel)]="selectedLanguage"
        class="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 disabled:border-0 disabled:bg-blue-gray-50"
        required>
        <option
          class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
          disabled
          selected
          role="option">
          Select language to add...
        </option>
        <option
          class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
          *ngFor="let language of availableLanguages"
          [value]="language"
          role="option">
          {{ language }}
        </option>
      </select>
    </div>

    <button
      (click)="addRow()"
      type="button"
      class="text-white bg-emerald-400 hover:bg-emerald-500 font-medium rounded text-md w-36 sm:w-36 px-5 h-10 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      + New Field
    </button>
  </div>
  <div class="max-h-120 overflow-auto w-11/12 mt-4 rounded-lg bg-white p-1">
    <p-table [value]="fields">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5%">Field Name</th>
          <th style="width: 10%">Field Type</th>
          <th style="width: 10%" *ngFor="let lang of allLanguages">
            {{ lang }}
          </th>
          <th style="width: 5%">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-field>
        <tr>
          <td style="width: 5%">
            <input
              type="text"
              [(ngModel)]="field.name"
              [readonly]="!field.isEditing" />
          </td>
          <td>
            <p-dropdown
              [options]="fieldTypes"
              [(ngModel)]="field.value.type"
              [disabled]="!field.isEditing"></p-dropdown>
          </td>
          <td *ngFor="let lang of allLanguages">
            <textarea
              class="bg-gray-50 p-2 rounded-lg border-2 border-gray-300 focus:ring-emerald-400 focus:border-emerald-400"
              type="text"
              [(ngModel)]="field.value.languages[lang]"
              [readonly]="!field.isEditing"
              rows="2"
              cols="16"></textarea>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                (click)="toggleEdit(field)"
                *ngIf="!field.isEditing"
                pButton
                pRipple
                icon="pi pi-pencil"
                type="button"
                class="p-button-rounded p-button-text"></button>
              <button
                *ngIf="field.isEditing"
                (click)="saveRow(field)"
                pButton
                pRipple
                icon="pi pi-check"
                type="button"
                class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button
                (click)="cancel(field)"
                *ngIf="field.isEditing"
                pButton
                pRipple
                icon="pi pi-times"
                type="button"
                class="p-button-rounded p-button-text p-button-danger"></button>
            </div>
            <!-- <button *ngIf="!field.isEditing" (click)="toggleEdit(field)">
              Edit
            </button>
            <button *ngIf="field.isEditing" (click)="saveRow(field)">
              Save
            </button> -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
