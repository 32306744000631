<mat-form-field
  class="tagychips-example-chip-list"
  [appearance]="config.appearance">
  <!-- <mat-label>{{ config.label }}</mat-label> -->
  <mat-chip-grid
    [disabled]="isDisabled"
    #chipList
    aria-label="Object selection">
    <mat-chip-row
      [disabled]="isDisabled"
      *ngFor="let object of selectedObjects"
      (removed)="remove(object)"
      [style.background-color]="
        config.enableColors
          ? (object?.color ? object.color : config.backgroundColor) +
            ' !important'
          : '#e0e0e0'
      "
      [style.color]="
        config.enableColors ? object?.color + ' !important' : 'rgba(0,0,0,.87)'
      ">
      <!-- object avatar -->
      <img
        matChipAvatar
        *ngIf="config.enableAvatar && object?.avatar"
        src="{{ object?.avatar }}" />
      <span
        *ngIf="config.enableAvatar && !object?.avatar"
        matChipAvatar
        class="inline-block h-5 w-5 overflow-hidden rounded-full bg-gray-100">
        <svg
          class="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
      <!--/End object avatar -->

      <div class="chip-content">
        <span class="chip-title">
          {{
            object?.name
              ? object.name
              : object?.email
              ? object.email
              : object?.description.slice(0, 10)
          }}
        </span>
      </div>
      <button matChipRemove [attr.aria-label]="'remove ' + object">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      [disabled]="isDisabled"
      placeholder=""
      #objectInput
      [formControl]="objectCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (input)="_filter($event)" />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <div class="tagyChipsheader">
      <button
        [disabled]="isDisabled"
        class="headerButton"
        *ngIf="config.headerButton"
        (click)="headerButton()">
        <a
          style="
            font-family: 'Lato', sans-serif;
            font-weight: 600;
            font-size: 12px;
            color: #666666;
          "
          class="headerButtonText"
          >{{ config.headerButton }}</a
        >
      </button>
    </div>
    <ng-container *ngIf="filteredObjects | async as objects">
      <mat-option
        *ngFor="let object of objects; trackBy: trackByFn"
        [style.background-color]="
          config.enableColors
            ? (object?.color ? object.color : config.backgroundColor) +
              ' !important'
            : '#e0e0e0'
        "
        [disabled]="isDisabled"
        class="h-auto py-2"
        [value]="object">
        <div class="tagychips-flex tagychips-gap-x-4">
          <div *ngIf="config.enableAvatar">
            <img
              *ngIf="object.avatar"
              class="tagychips-img-custom tagychips-h-12 tagychips-w-12 tagychips-flex-none tagychips-rounded-full tagychips-bg-gray-50"
              src="{{ object.avatar }}"
              alt="" />
            <div
              *ngIf="!object.avatar"
              class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span class="font-medium text-gray-600 dark:text-gray-300">{{
                object.name
                  ? object.name.substring(0, 1).toUpperCase() +
                    object.name.substring(1, 2).toUpperCase()
                  : object.email.substring(0, 1).toUpperCase() +
                    object.email.substring(1, 2).toUpperCase()
              }}</span>
            </div>
          </div>

          <div
            class="tagychips-min-w-0 tagychips-flex-auto tagychips-custom-avatar-container"
            *ngIf="config.enableMultipleLines">
            <p
              class="tagychips-text-sm tagychips-font-semibold tagychips-leading-6 tagychips-text-gray-900">
              {{
                object?.name
                  ? object?.name
                  : object.email
                  ? object.email
                  : object
              }}
            </p>
            <p
              class="mt-1 tagychips-flex tagychips-text-xs tagychips-leading-5 tagychips-text-gray-500">
              {{ object?.description ? object?.description : object?.email }}
            </p>
          </div>
          <div class="disableMultipleLines" *ngIf="!config.enableMultipleLines">
            {{ object?.name }}
          </div>
        </div>
      </mat-option>
      <mat-option *ngIf="objects.length === 0" disabled
        >Aucune autre option</mat-option
      >
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
