import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-tagychips',
  templateUrl: './tagychips.component.html',
  styleUrls: ['./tagychips.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagychipsComponent implements OnInit, OnChanges {
  @Input()
  config = {
    label: 'Objects',
    placeholder: 'Enter a object',
    headerButton: '',
    secondaryPlaceholder: 'Add another object',
    appearance: 'outline' as MatFormFieldAppearance,
    separatorKeyCodes: [ENTER, COMMA],
    maxSelectedOptions: 5,
    addOnBlur: true,
    enableAvatar: true,
    enableColors: true,
    enableMultipleLines: false,
    enableFilterAfterSelect: false,
    inviteByEmail: false,
    backgroundColor: '',
    color: '',
  };
  @Input()
  objects: any[] = [];
  @Input()
  confirmationBeforeDelete: boolean = false;
  @Input()
  confirmationMessage = {};
  separatorKeysCodes: number[] = [ENTER, COMMA];
  objectCtrl = new FormControl();
  @Input()
  selectedObjects: any[] = [];
  filteredObjects!: Observable<any[]>;

  @Input()
  isDisabled: boolean;

  @ViewChild('objectInput')
  objectInput!: ElementRef<HTMLInputElement>;

  @Output() addTagychip = new EventEmitter<any>();
  @Output() removeTagychip = new EventEmitter<any>();
  @Output() headerButtonClicked = new EventEmitter<any>();

  constructor(private _fuseConfirmationService: FuseConfirmationService) {}

  ngOnInit(): void {
    this.updateFilteredObjects();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.objects && !changes.objects.firstChange) ||
      (changes.selectedObjects && !changes.selectedObjects.firstChange)
    ) {
      this.updateFilteredObjects();
    }
  }

  public add(event: MatChipInputEvent): void {
    const value = event.value.trim();
    const input = event.input;

    if (!value) {
      return;
    }

    if (
      this.config.inviteByEmail &&
      this.isEmail(value) &&
      !this.emailExistsInOptions(value)
    ) {
      const emailObject = { email: value };
      if (this.config.maxSelectedOptions === 1) {
        this.selectedObjects = [emailObject];
      } else {
        this.selectedObjects.push(emailObject);
      }
      this.addTagychip.next({ value: emailObject });
    } else {
      this.handleObject(value);
    }

    if (input) {
      input.value = '';
    }
    this.objectCtrl.setValue(null);
    this.updateFilteredObjects();
  }

  private isEmail(value: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  private emailExistsInOptions(email: string): boolean {
    return this.objects.some(object => object.email === email);
  }

  private handleObject(value: string): void {
    const matchingObject = this.objects.find(
      object =>
        object.name.toLowerCase() === value.toLowerCase() ||
        (object.email && object.email.toLowerCase() === value.toLowerCase())
    );

    if (!matchingObject) {
      return;
    }

    if (this.config.maxSelectedOptions === 1) {
      this.selectedObjects = [matchingObject];
      this.addTagychip.next({ value: matchingObject });
    } else if (
      !this.selectedObjects.find(f => f.name === matchingObject.name) &&
      this.selectedObjects.length < this.config.maxSelectedOptions
    ) {
      this.selectedObjects.push(matchingObject);
      this.addTagychip.next(matchingObject);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;

    if (this.config.maxSelectedOptions === 1) {
      this.selectedObjects = [value];
      this.addTagychip.next({ value });
    } else {
      if (
        (!this.selectedObjects.find(object => object.if === value.id) ||
          !this.selectedObjects.find(object => object.name === value.name)) &&
        this.selectedObjects.length < this.config.maxSelectedOptions
      ) {
        this.selectedObjects.push(value);
        this.addTagychip.next({ value });
      }
    }
    this.updateFilteredObjects();

    this.objectInput.nativeElement.value = '';
    this.objectCtrl.setValue(null);
  }

  public remove(value: any): void {
    if (this.confirmationBeforeDelete) {
      // Open the confirmation dialog
      const confirmation = this._fuseConfirmationService.open(
        this.confirmationMessage
      );
      // Subscribe to the confirmation dialog closed action
      confirmation.afterClosed().subscribe(result => {
        // If the confirm button pressed...
        if (result === 'confirmed') {
          // Delete the list
          const index = this.selectedObjects.findIndex(
            f => f.name === value.name
          );
          if (index >= 0) {
            this.selectedObjects.splice(index, 1);
            this.removeTagychip.next({ value });
            this.updateFilteredObjects();
          }
        }
      });
    } else {
      const index = this.selectedObjects.findIndex(f => f.name === value.name);
      if (index >= 0) {
        this.selectedObjects.splice(index, 1);
        this.removeTagychip.next({ value });
        this.updateFilteredObjects();
      }
    }
  }

  public headerButton(): void {
    this.headerButtonClicked.next('clicked');
  }

  public _filter(event: any): void {
    if (!event) return;
    const filterValue =
      this.objectCtrl.value && typeof this.objectCtrl.value === 'object'
        ? (this.objectCtrl.value.name.toLowerCase()
            ? this.objectCtrl.value.name.toLowerCase()
            : '') +
          (this.objectCtrl.value.email
            ? this.objectCtrl.value.email.toLowerCase()
            : '') +
          (this.objectCtrl.value.description
            ? this.objectCtrl.value.description.toLowerCase()
            : '')
        : this.objectCtrl.value
        ? this.objectCtrl.value.toLowerCase()
        : '';

    if (this.config.enableFilterAfterSelect) {
      this.filteredObjects = of(
        this.objects.filter(object => {
          const containsFilterValue =
            object.name.toLowerCase().includes(filterValue) ||
            (object.description &&
              object.description.toLowerCase().includes(filterValue)) ||
            (object.email && object.email.toLowerCase().includes(filterValue));

          if (object.id) {
            return (
              containsFilterValue &&
              !this.selectedObjects.find(f => f.id === object.id)
            );
          } else {
            return (
              containsFilterValue &&
              !this.selectedObjects.find(f => f.name === object.name)
            );
          }
        })
      );
    } else {
      this.filteredObjects = of(
        this.objects.filter(
          object =>
            object.name.toLowerCase().includes(filterValue) ||
            object.description?.toLowerCase().includes(filterValue) ||
            object.email?.toLowerCase().includes(filterValue)
        )
      );
    }
  }

  private updateFilteredObjects(): void {
    if (!this.objects) return;
    if (this.config.enableFilterAfterSelect) {
      this.filteredObjects = of(
        this.objects.filter(object => {
          if (!this.selectedObjects || this.selectedObjects.length === 0) {
            return true;
          }
          if (object.id) {
            return !this.selectedObjects.find(f => f && f.id === object.id);
          } else {
            return !this.selectedObjects.find(
              f => f && f.name.toLowerCase() === object.name.toLowerCase()
            );
          }
        })
      );
    } else {
      this.filteredObjects = of(this.objects);
    }
  }
}
