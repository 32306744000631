import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserWorkspaceService {
  private workspacesSubject = new BehaviorSubject<any[]>([]);
  workspaces$ = this.workspacesSubject.asObservable();

  private activeWorkspaceSubject = new BehaviorSubject<any>(null);
  activeWorkspace$ = this.activeWorkspaceSubject.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService
  ) {
    this.fetchWorkspaces();
  }

  async fetchWorkspaces(): Promise<void> {
    const authUser = await this._authService.getAuthUser();

    if (authUser) {
      const userId = authUser.id;
      this._httpClient
        .get<any[]>(`${environment.backendUrl}/user-workspaces/v1/${userId}`, {
          withCredentials: true,
        })
        .subscribe((response: any[]) => {
          const workspaces = response;

          this.workspacesSubject.next(workspaces);

          const activeWorkspaceId = this.getActiveWorkspaceId();

          // Set the active workspace from localStorage if available
          if (activeWorkspaceId) {
            this.setActiveWorkspace(activeWorkspaceId);
          } else if (workspaces.length > 0) {
            // Set the first workspace as the active workspace by default
            this.setActiveWorkspace(workspaces[0].id);
          }
        });
    }
  }

  setActiveWorkspace(workspaceId: string): void {
    const workspace = this.workspacesSubject
      .getValue()
      .find(w => w.id === workspaceId);
    if (workspace) {
      this.activeWorkspaceSubject.next(workspace);
      localStorage.setItem('activeWorkspaceId', workspaceId);
    }
  }

  getActiveWorkspaceId(): string | null {
    const workspaceId = localStorage.getItem('activeWorkspaceId');
    if (!workspaceId) {
      console.log('ERROR in retrieving active workspace');
      return null;
    }
    return workspaceId;
  }
}
