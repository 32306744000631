import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { WorkshopCardboardStoreModule } from './cardboard-store/cardboard-store.module';
import { WorkshopsStoreEffects } from './effects';
import { reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    WorkshopCardboardStoreModule,
    StoreModule.forFeature('workshops', reducer),
    EffectsModule.forFeature([WorkshopsStoreEffects]),
  ],
  providers: [],
})
export class WorkshopsStoreModule {}
