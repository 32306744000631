<div
  [ngSwitch]="labelsMenuView"
  (click)="$event.stopPropagation()"
  class="flex flex-col h-64 w-64 overscroll-none bg-white">
  <div *ngSwitchCase="'labels'">
    <!-- Header -->
    <div
      class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 border-b border-slate-200 pl-2 sm:pl-8 b">
      <div class="text-md font-medium">Colors</div>
      <button mat-icon-button (click)="labelsMenuView = 'add'" class="mr-5">
        <mat-icon class="icon-size-5">add</mat-icon>
        <span class="text-md font-medium ml-1"> Add </span>
      </button>
    </div>

    <!-- Body -->
    <div class="flex flex-col p-4">
      <div class="flex flex-row"  *ngFor="let color of activityColors">
        <div [style.background-color]="color.color" class="rounded-full h-3 w-3 mt-3 ml-4"></div>
        <mat-checkbox class="ml-2" (change)="toggleProductTag(color, $event)"
        [color]="'primary'"> {{ color.name }} </mat-checkbox>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'add'">
    <!-- Header -->
    <div
      class="flex flex-0 items-center justify-between h-16 pr-1 sm:pr-5 border-b border-slate-200 pl-2 sm:pl-8 b">
      <button class="flex flex-row" (click)="labelsMenuView = 'labels'">
        <mat-icon
          [svgIcon]="'heroicons_outline:arrow-sm-left'"
          class="icon-size-4 mt-1"></mat-icon>
        <div class="text-md font-medium ml-2">Colors</div>
      </button>
    </div>

    <!-- Body -->
    <div class="flex flex-col h-24">
      <form
        class="flex flex-row items-center flex-auto p-2 overflow-y-auto"
        [formGroup]="composeForm">
        <!-- To -->
        <mat-form-field class="mt-3 ml-3">
          <mat-label>Name</mat-label>
          <input matInput [formControlName]="'name'" />
        </mat-form-field>
        <button class="border-slate-500" [matMenuTriggerFor]="labelsMenu">
          <div [style.background-color]="colorDisplayed" class="rounded-full h-7 w-7 ml-9 mt-5"></div>
        </button>
        <mat-menu #labelsMenu="matMenu" [overlapTrigger]="false">
          <color-picker (colorSelected)="colorSelected($event)"></color-picker>
        </mat-menu>
      </form>
    </div>

    <div
      class="flex justify-end p-2 sm:flex-row sm:items-center border-t border-slate-200 sm:mt-6">
      <div class="flex items-center sm:mt-0">
        <!-- Save as draft -->
        <button (click)="addColor()" class="sm:mx-3 bg-emerald-400 text-white" mat-stroked-button>
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
