<div class="w-full max-w-8x overflow-hidden">
  <!-- Add team member -->
  <div class="w-full">
    <form class="flex flex-col" [formGroup]="memberForm" *ngIf="memberForm">
      <div class="flex flex-row gap-4">
        <app-tagychips
          type="text"
          class="w-full"
          [objects]="objects"
          [config]="membersInvitation"
          (removeTagychip)="onRemove($event)"
          (addTagychip)="onAdd($event)">
        </app-tagychips>
        <div class="flex flex-col gap-1">
          <mat-form-field
            class="fuse-mat-dense w-40"
            [subscriptSizing]="'dynamic'">
            <mat-select
              formControlName="role"
              [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
              disableOptionCentering
              #roleSelect="matSelect">
              <mat-select-trigger class="text-md">
                <span>Role:</span>
                <span class="ml-1 font-medium">{{ roleSelect.value }}</span>
              </mat-select-trigger>
              <ng-container *ngFor="let role of roles">
                <mat-option
                  class="h-auto py-4 leading-none"
                  [value]="role.value">
                  <div class="font-medium">{{ role.label }}</div>
                  <div
                    class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">
                    {{ role.description }}
                  </div>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <button
            [disabled]="memberForm.get('role').invalid"
            (click)="invite()"
            class="px-4 py-2 text-white rounded-md bg-[#39cb9e]">
            Invite member
          </button>
        </div>
      </div>
      <div class="flex flex-row"></div>
    </form>
  </div>

  <!-- Team members -->
  <div
    class="flex flex-col mt-7 divide-y border-t h-96 max-h-96 overflow-y-auto overflow-x-hidden">
    <ng-container *ngFor="let user of users">
      <div class="flex flex-col sm:flex-row sm:items-center py-3">
        <div class="flex items-center">
          <div
            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
            <ng-container *ngIf="user.avatar">
              <img
                class="object-cover w-full h-full"
                [src]="user.avatar"
                alt="Contact avatar" />
            </ng-container>
            <ng-container *ngIf="!user.avatar">
              <div
                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{ user?.email.charAt(0) }}
              </div>
            </ng-container>
          </div>
          <div class="ml-4">
            <div class="font-medium">{{ user.name }}</div>
            <div class="text-secondary">{{ user.email }}</div>
          </div>
        </div>
        <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">
          <div class="order-2 sm:order-1 ml-4 sm:ml-0">
            <mat-form-field
              class="fuse-mat-dense w-32"
              [subscriptSizing]="'dynamic'">
              <mat-select
                [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
                [(ngModel)]="user.role"
                (openedChange)="captureOldAccessLevel(user.role)"
                (selectionChange)="updateMember(user.id, user.role)"
                disableOptionCentering
                [disabled]="user.soleAdmin"
                #roleSelectRole="matSelect">
                <mat-select-trigger class="text-md">
                  <span>Role:</span>
                  <span class="ml-1 font-medium">{{
                    user.role | titlecase
                  }}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let role of roles">
                  <mat-option
                    class="h-auto py-4 leading-none"
                    [value]="role.value">
                    <div class="font-medium">{{ role.label }}</div>
                    <div
                      class="mt-1.5 text-sm whitespace-normal leading-normal text-secondary">
                      {{ role.description }}
                    </div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="order-1 sm:order-2 sm:ml-3">
            <button
              title="delete project"
              mat-icon-button
              [disabled]="user.soleAdmin"
              (click)="openDeleteConfirmation(user.id, data.id, user.role)">
              <mat-icon
                class="text-hint"
                [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
