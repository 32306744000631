<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user?.avatar"
      [src]="user?.avatar" />
    <mat-icon
      *ngIf="!showAvatar || !user?.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span
      class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
      [ngClass]="{
        'mr-px mb-px': !showAvatar || !user?.avatar,
        'bg-green-500': user?.status === 'online',
        'bg-amber-500': user?.status === 'away',
        'bg-red-500': user?.status === 'busy',
        'bg-gray-400': user?.status === 'not-visible'
      }"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <!--User Settings Item -->
  <button (click)="openDialog()" mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
    <span>User settings</span>
  </button>

  <!--Workspaces Item -->
  <button mat-menu-item [matMenuTriggerFor]="workspaceMenu">
    <mat-icon [svgIcon]="'heroicons_outline:workspace-logo'"></mat-icon>
    <span>Change Workspace</span>
  </button>

  <!--User Interface Item -->
  <button
    mat-menu-item
    (click)="goToAdminConsole()"
    *ngIf="!navItems.items[0].isAdmin && role === 'admin'">
    <mat-icon svgIcon="heroicons_outline:adjustments-horizontal"></mat-icon>
    <span>Admin console</span>
  </button>

  <!-- Item -->
  <!-- <button
    mat-menu-item
    [matMenuTriggerFor]="userStatus"
    *ngIf="!navItems.items[0].isAdmin">
    <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
    <span>Status</span>
  </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus()">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus()">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus()">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus()">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>

<mat-menu
  class="w-52 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
  #workspaceMenu="matMenu">
  <!-- Application workspaces -->
  <ng-container *ngIf="!navItems.items[0].isAdmin">
    <ng-container *ngFor="let workspace of workspaces$ | async">
      <button
        (click)="switchToWorkspace(workspace.id)"
        class="flex items-center justify-between w-full p-2 hover:bg-gray-200">
        <span
          [ngClass]="{
            'text-mint-500': workspace.id === (activeWorkspace$ | async)?.id
          }">
          {{ workspace.name }}
        </span>
        <mat-icon
          *ngIf="workspace.id === (activeWorkspace$ | async)?.id"
          class="ml-2 text-mint-400 icon-size-5 font-extrabold"
          [svgIcon]="'heroicons_outline:check'">
        </mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <!-- Admin console workspaces -->
  <ng-container *ngIf="navItems.items[0].isAdmin">
    <ng-container *ngFor="let workspace of workspaces$ | async">
      <button
        class="flex items-center justify-between w-full p-2 hover:bg-gray-200"
        (click)="switchToWorkspaceForAdmin(workspace.id)">
        <span
          [ngClass]="{
            'text-mint-500': workspace.id === activeAdminWorkspaceId
          }">
          {{ workspace.name }}
        </span>
        <mat-icon
          *ngIf="workspace.id === activeAdminWorkspaceId"
          class="ml-2 text-mint-400 icon-size-5 font-extrabold"
          [svgIcon]="'heroicons_outline:check'">
        </mat-icon>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
