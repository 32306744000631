<div class="flex flex-col justify-center items-center w-full h-full">
  <form [formGroup]="createInterfaceForm" class="w-4/5 flex flex-col gap-y-10">
    <div>
      <label
        for="interface-name"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Interface Name</label
      >
      <div class="mt-2">
        <input
          formControlName="name"
          type="text"
          name="interface-name"
          id="interface-name"
          class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          placeholder="interface name" />
      </div>
    </div>

    <div>
      <label
        for="description"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Interface Description</label
      >
      <div class="mt-2">
        <textarea
          formControlName="description"
          rows="4"
          name="comment"
          id="comment"
          class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"></textarea>
      </div>
    </div>

    <div>
      <label
        for="interface-path"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Interface Path</label
      >
      <div class="mt-2">
        <input
          formControlName="path"
          type="text"
          name="interface-path"
          id="interface-path"
          class="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          placeholder="interface path" />
      </div>
    </div>

    <div>
      <label
        for="interface-type"
        class="block text-sm font-medium leading-6 text-gray-900"
        >App Service Name :
      </label>
      <div class="mt-2">
        <select
          formControlName="appServiceId"
          name="interface-type"
          id="interface-type"
          class="block p-2 w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
          <option *ngFor="let module of appServicesModules" [value]="module.id">
            {{ module.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex justify-end">
      <div class="flex flex-row gap-x-4">
        <button (click)="onCancel()">Cancel</button>
        <button (click)="onSubmit()" [disabled]="!createInterfaceForm.valid">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
