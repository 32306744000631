import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { CommonService } from 'app/common-dialogs/common.services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [];
  selectedPanel: string = 'profile-details';
  private initialFormData: any = {};
  private currentFormData: any = {};

  constructor(
    public _matDialogRef: MatDialogRef<UserProfileComponent>,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    // Setup available panels
    this.panels = [
      {
        id: 'profile-details',
        icon: 'heroicons_outline:user-circle',
        title: 'Profile',
        description: ' Manage your profile and personal informations',
      },
      {
        id: 'user-security',
        icon: 'heroicons_outline:lock-closed',
        title: 'Security',
        description: ' Manage your account security',
      },
      {
        id: 'user-notification',
        icon: 'heroicons_outline:bell',
        title: 'Notifications',
        description: ' Choose your notification preferences',
      },
    ];
    this._matDialogRef.afterClosed().subscribe(() => {
      this.dialogClosed();
    });
  }

  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  private dialogClosed(): void {
    if (this.selectedPanel === 'profile-details' && this.isFormChanged()) {
      this.updateMember();
    }
  }

  private isFormChanged(): boolean {
    return (
      JSON.stringify(this.initialFormData) !==
      JSON.stringify(this.currentFormData)
    );
  }

  private updateMember(): void {
    if (this.currentFormData) {
      const objectData = {
        name: `${this.currentFormData.firstname} ${this.currentFormData.lastname}`,
        jobTitle: this.currentFormData.jobTitle,
        language: this.currentFormData.language,
        timeZone: this.currentFormData.timeZone,
      };

      this._commonService
        .updateObjectDetails('User', objectData, this.data.id)
        .subscribe(() => {
          this.cdr.detectChanges();
          this.toastr.success('Member has been updated successfully');
        });
    }
  }

  handleFormChanges(updatedFormData: any): void {
    if (!this.initialFormData) {
      this.initialFormData = { ...updatedFormData };
    }
    this.currentFormData = { ...updatedFormData };
  }

  getPanelInfo(id: string): any {
    return this.panels.find(panel => panel.id === id);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
