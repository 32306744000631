import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { RollbarService } from 'app/app.module';
import * as Rollbar from 'rollbar';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.error(err.orginialError || err);
    this.rollbar.error(err.originalError || err);
  }
}
