export const colors = [
  '#FF9B9B',
  '#FFC8C8',
  '#FFC294',
  '#FFE368',
  '#FFFBA8',
  '#DEFF85',
  '#F7FFDB',
  '#C5E6D7',
  '#DBFFF7',
  '#9EE7E3',
  '#D8E5FF',
  '#D5D1FF',
  '#EEC8FF',
  '#FFD9FA',
  '#FFC4E2',
  '#D8D8D8',
];

export function getRandomColor(): string {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}
