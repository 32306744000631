<div class="h-full max-h-full">
  <form [formGroup]="profileDetailsForm" *ngIf="profileDetailsForm" action="">
    <div class="grid grid-cols-4 gap-8 pb-7 border-b">
      <label
        class="w-full col-span-4 text-gray-900 text-base font-medium leading-normal py-0"
        >Profile</label
      >

      <div class="col-span-2">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="firstname"
          >First Name</label
        >
        <input
          formControlName="firstname"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          id="firstname"
          type="text"
          placeholder="First name" />
      </div>

      <div class="col-span-2">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="lastname"
          >Last Name</label
        >
        <input
          formControlName="lastname"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          id="lastname"
          type="text"
          placeholder="Last name" />
      </div>

      <div class="col-span-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="jobTitle"
          >Job Title</label
        >
        <input
          formControlName="jobTitle"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          id="jobTitle"
          type="text"
          placeholder="Job Title" />
      </div>

      <!-- Language -->
      <div class="col-span-2">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="language"
          >Language</label
        >
        <mat-select
          formControlName="language"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
          <mat-select-trigger>
            {{ profileDetailsForm.get('language')?.value || 'Select Language' }}
          </mat-select-trigger>
          <mat-option *ngFor="let lang of languagesList" [value]="lang.full">
            {{ lang.full }}
          </mat-option>
        </mat-select>
      </div>

      <!-- Time Zone -->
      <div class="col-span-2">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="timeZone"
          >Time Zone</label
        >
        <mat-select
          formControlName="timeZone"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
          <mat-select-trigger>
            {{
              profileDetailsForm.get('timeZone')?.value || 'Select Time Zone'
            }}
          </mat-select-trigger>
          <mat-option *ngFor="let zone of timeZones" [value]="zone">
            {{ zone }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </form>
</div>
