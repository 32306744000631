import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerState, State } from './state';

export const selectNavigationState =
  createFeatureSelector<State>('navigations');

export const selectAllItems = createSelector(
  selectNavigationState,
  (state: State) => state.items
);

export const selectEntireState = createSelector(
  selectNavigationState,
  (state: State) => state
);

export const selectCustomerState =
  createFeatureSelector<CustomerState>('customer');

export const selectAllCustomers = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.customers
);

export const selectProjects = createSelector(
  selectNavigationState,
  (state: State) => state.projects
);
