<div
  class="absolute inset-0 overflow-y-auto overflow-x-hidden w-full h-full max-h-full">
  <mat-drawer-container class="h-full max-h-full">
    <!-- Drawer -->
    <mat-drawer
      class="h-full max-h-full"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer>
      <!-- Header -->
      <div class="flex px-8 py-8">
        <!-- Title -->
        <div
          class="text-base font-semibold tracking-tight leading-normal py-4 h-5 text-gray-900">
          Profile Settings
        </div>
        <!-- Close button -->
        <div class="lg:hidden">
          <button mat-icon-button (click)="drawer.close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div>
      </div>
      <!-- Panel links -->
      <div class="">
        <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
          <div
            class="flex px-8 py-5 cursor-pointer border-t border-b"
            [ngClass]="{
              'hover:bg-gray-100 dark:hover:bg-hover':
                !selectedPanel || selectedPanel !== panel.id,
              'bg-primary-50 dark:bg-hover':
                selectedPanel && selectedPanel === panel.id
            }"
            (click)="goToPanel(panel.id)">
            <mat-icon
              [ngClass]="{
                'text-hint': !selectedPanel || selectedPanel !== panel.id,
                'text-primary dark:text-primary-500':
                  selectedPanel && selectedPanel === panel.id
              }"
              [svgIcon]="panel.icon"></mat-icon>
            <div class="ml-3">
              <div
                class="font-medium leading-6"
                [ngClass]="{
                  'text-primary dark:text-primary-500':
                    selectedPanel && selectedPanel === panel.id
                }">
                {{ panel.title }}
              </div>
              <div class="mt-0.5 text-secondary">
                {{ panel.description }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="border-black">
      <!-- Main -->
      <div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12">
        <!-- Panel header -->
        <div class="flex items-center">
          <!-- Drawer toggle -->
          <button
            class="lg:hidden -ml-2"
            mat-icon-button
            (click)="drawer.toggle()">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
          </button>
        </div>

        <!-- Load settings panel -->
        <div class="w-full h-full">
          <ng-container [ngSwitch]="selectedPanel">
            <!-- user profile details -->
            <ng-container *ngSwitchCase="'profile-details'">
              <excelway-user-profile-details
                (updateMember)="
                  handleFormChanges($event)
                "></excelway-user-profile-details>
            </ng-container>
            <!-- user security tab -->
            <ng-container *ngSwitchCase="'user-security'">
              <excelway-user-security></excelway-user-security>
            </ng-container>
            <!-- user notification preferences -->
            <ng-container *ngSwitchCase="'user-notification'">
              <excelway-user-notification-preferences></excelway-user-notification-preferences>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
