import { Component } from '@angular/core';

@Component({
  selector: 'excelway-user-security',
  templateUrl: './user-security.component.html',
})
export class UserSecurityComponent {
  displayPictureIcons: boolean;
  constructor() {}
}
