import { Action, createReducer, on } from '@ngrx/store';

import * as BoardsStoreActions from './actions';
import { initialState, State } from './state';

const boardsReducer = createReducer(
  initialState,
  on(BoardsStoreActions.getBoard, state => ({
    ...state,
    pending: true,
  })),
  on(BoardsStoreActions.getBoardSuccess, (state, { board }) => ({
    ...state,
    currentBoard: board,
    pending: false,
  })),
  on(BoardsStoreActions.getBoardFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),
  on(BoardsStoreActions.getBoards, state => ({
    ...state,
    pending: true,
  })),
  on(BoardsStoreActions.getBoardsSuccess, (state, { boards }) => ({
    ...state,
    boards,
    pending: false,
  })),
  on(BoardsStoreActions.getBoardsFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return boardsReducer(state, action);
}
