<div class="mt-2 text-[#4B5563]">
  Choose the scope of your template's accessibility; either within projects
  where you hold an admin role, or across the workspace upon validation by the
  workspace admin.
</div>
<div class="mt-3 flex flex-col gt-xs:flex-row">
  <div *ngIf="availability$ | async as availability">
    <form [formGroup]="projectSelectionForm">
      <mat-radio-group
        class="flex flex-col"
        [color]="'primary'"
        [formControlName]="'projectSelection'">
        <mat-radio-button
          class="mb-2"
          [value]="'someProjects'"
          [checked]="availability.availableInProjects">
          Available in specific projects
        </mat-radio-button>
        <div
          *ngIf="
            projectSelectionForm.get('projectSelection')?.value ===
            'someProjects'
          "
          class="flex-col border-2 md:w-1/3 sm:w-2/3 rounded-lg">
          <div>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodeToggle
                matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox
                  class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">
                  {{ node.name }}</mat-checkbox
                >
              </mat-tree-node>

              <mat-tree-node
                *matTreeNodeDef="let node; when: hasNoContent"
                matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-form-field>
                  <input
                    dir="auto"
                    matInput
                    #itemValue
                    placeholder="New item..." />
                </mat-form-field>
              </mat-tree-node>

              <mat-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding>
                <button
                  mat-icon-button
                  matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? 'expand_more'
                        : 'chevron_right'
                    }}
                  </mat-icon>
                </button>
                <mat-checkbox
                  [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">
                  {{ node.name }}</mat-checkbox
                >
              </mat-tree-node>
            </mat-tree>
          </div>
        </div>
        <mat-radio-button
          class="mb-2"
          [value]="'allProjects'"
          [checked]="availability.availableAll">
          Available everywhere
        </mat-radio-button>
      </mat-radio-group>
      <div
        class="flex flex-row"
        *ngIf="
          projectSelectionForm.get('projectSelection').value ===
            'someProjects' && !areProjectsSelected()
        ">
        <div class="text-red-400 font-medium">
          Please select at least one project.
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex items-center justify-end">
          <button
            (click)="onSubmit()"
            [disabled]="!areProjectsSelected()"
            class="btn-primary-hover"
            type="button">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
