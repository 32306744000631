import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ComponentStore, OnStateInit } from '@ngrx/component-store';
import {
  EMPTY,
  Observable,
  catchError,
  exhaustMap,
  filter,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { HeaderModel } from '@excelway/models/layout/header.model';
import { LayoutService } from '@excelway/services/layout.service';
import { ToastrService } from 'ngx-toastr';

export interface ModernState {
  previousRoute: string[];
  header: HeaderModel | null;
}

export const initialState: ModernState = {
  previousRoute: [],
  header: null,
};

@Injectable()
export class ModernStore
  extends ComponentStore<ModernState>
  implements OnStateInit
{
  constructor(
    private readonly _layoutService: LayoutService,
    private readonly _router: Router,
    private _toastr: ToastrService
  ) {
    super(initialState);

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.ngrxOnStateInit();
      });
  }

  ngrxOnStateInit(): void {
    const { projectId, roleType, id, activeTab } = this.getRouteParams();

    this.getHeader({ roleType, id });

    this.patchState({
      previousRoute:
        roleType !== 'Activity'
          ? ['/projects/project', projectId, activeTab]
          : ['/projects/project', projectId, 'workshops', activeTab],
    });
  }

  // Effects
  readonly getHeader = this.effect(
    (queryParams$: Observable<{ id: string; roleType: string }>) => {
      return queryParams$.pipe(
        // 👇 Handle race condition with the proper choice of the flattening operator.
        switchMap(queryParams =>
          this._layoutService
            .getHeader(queryParams.id, queryParams.roleType)
            .pipe(
              //👇 Act on the result within inner pipe.
              tap({
                next: header => this.patchState({ header }),
              }),
              // 👇 Handle potential error within inner pipe.
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  readonly goBack = this.effect<void>(source$ =>
    source$.pipe(
      withLatestFrom(this.select(state => state.previousRoute)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      exhaustMap(([_, previousRoute]: [void, string[]]) => {
        const originalBoardId = localStorage.getItem('originalBoardId');
        if (originalBoardId) {
          // If it exists, navigate to the original board
          return this._router
            .navigate([
              '/projects/project',
              this.getRouteParams().projectId,
              'boards',
              originalBoardId,
              'kanban',
            ])
            .then(() => {
              localStorage.removeItem('originalBoardId');
              this._toastr.success(
                'You have successfully created and edited a template'
              );
            });
        } else {
          return this._router.navigate(previousRoute);
        }
      })
    )
  );

  // !Effects

  private getRouteParams(): {
    projectId: string;
    roleType: string;
    id: string;
    activeTab: string;
  } {
    const url = this._router.url;
    const regex = /\/projects\/project\/([^/]+)\/(?:boards|workshops)\/([^/]+)/;
    const match = regex.exec(url);
    if (!match) {
      return {
        projectId: '',
        roleType: '',
        id: '',
        activeTab: '',
      };
    }
    const projectId: any = match?.length > 1 ? match[1] : null;
    const roleType = url.includes('activity')
      ? 'Activity'
      : url.indexOf('workshops') !== -1
      ? 'Workshop'
      : url.indexOf('boards') !== -1
      ? 'Board'
      : 'Unknown';
    const activeTab = url.includes('activity')
      ? url.split('/')[5]
      : url.indexOf('workshops') !== -1
      ? 'workshops'
      : url.indexOf('boards') !== -1
      ? 'boards'
      : '';
    const id: any =
      roleType !== 'Activity'
        ? match?.length > 2
          ? (match[2] as string)
          : null
        : (url.split('/')[7] as string);

    return {
      projectId,
      roleType,
      id,
      activeTab,
    };
  }
}
