import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { InterfaceFieldsComponent } from './interface-fields/interface-fields.component';
import { CreateInterfaceComponent } from './interface-list/create-interface/create-interface.component';
import { InterfaceListComponent } from './interface-list/interface-list.component';
import { UserInterfaceRoutingModule } from './user-interface-routing.routing';
import { InterfaceStore } from './user-interface.store';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

import { MatTooltipModule } from '@angular/material/tooltip';
import { provideComponentStore } from '@ngrx/component-store';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [
    InterfaceListComponent,
    InterfaceFieldsComponent,
    CreateInterfaceComponent,
  ],
  imports: [
    CommonModule,
    UserInterfaceRoutingModule,

    //prime ng
    TableModule,
    InputTextareaModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    TagModule,

    //Angular Materiel
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  providers: [provideComponentStore(InterfaceStore)],
})
export class UserInterfaceModule {}
