import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ChapterModel } from './models/cardboard/chapiter.model';
import { DimensionModel } from './models/cardboard/dimension.model';
import { ItemArticleModel } from './models/cardboard/item-article.model';
import { ItemModel } from './models/cardboard/item.model';
import { ResultCommentModel } from './models/cardboard/result-comment.model';
import { CardboardStateModel } from './models/socket-io/cardboard-state.model';
import { State } from './state';

export const selectCardboardState =
  createFeatureSelector<State>('workshop-cardboard');

export const selectDimensions = createSelector(
  selectCardboardState,
  (state: State): DimensionModel[] => state.dimensions
);

export const selectDimension = createSelector(
  selectCardboardState,
  (state: State): DimensionModel => state.dimension
);

export const selectChapters = createSelector(
  selectCardboardState,
  (state: State): ChapterModel[] => state.chapters
);

export const selectChapter = createSelector(
  selectCardboardState,
  (state: State): ChapterModel => state.chapter
);

export const selectItems = createSelector(
  selectCardboardState,
  (state: State): ItemModel[] => (state.items ? state.items : [])
);

export const selectFiltredItems = createSelector(
  selectCardboardState,
  (state: State): ItemModel[] => (state.filtredItems ? state.filtredItems : [])
);

export const selectItem = createSelector(
  selectCardboardState,
  (state: State): ItemModel =>
    state.item !== undefined ? state.item : ({} as ItemModel)
);

export const selectArticles = createSelector(
  selectCardboardState,
  (state: State): ItemArticleModel[] => state.articles
);

export const selectItemsShuffled = createSelector(
  selectCardboardState,
  (state: State): boolean => state.itemsShuffled
);

export const selectCurrentItemId = createSelector(
  selectCardboardState,
  (state: State): string =>
    state.item ? state.item._id : state.chapter?._id || ''
);

export const selectComments = createSelector(
  selectCardboardState,
  (state: State): ResultCommentModel[] => {
    if (state.item) {
      return state.item.result.comments || [];
    } else if (state.chapter && state.chapter.result) {
      return state.chapter.result.comments || [];
    } else {
      return [];
    }
  }
);

export const selectCurrentState = createSelector(
  selectCardboardState,
  (state: State): CardboardStateModel => ({
    dimension: state.dimension,
    chapter: state.chapter,
    item: state.item,
    articles: state.articles,
  })
);

export const selectSelectedArticle = createSelector(
  selectCardboardState,
  (state: State): string => (state.selectedArticle ? state.selectedArticle : '')
);

export const selectEcartType = createSelector(
  selectCardboardState,
  (state: State): boolean => state.ecartType
);
