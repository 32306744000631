import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AuthService } from '@excelway/services/auth.service';
import { AutorizationService } from '@excelway/services/autorization.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[authorizeCrud]',
  standalone: true,
})
export class BoardsCrudAuthorizationDirective implements OnInit, OnDestroy {
  @Input('authorizeCrud') objectId: string;
  private subscription: Subscription;
  role: string;

  constructor(
    private _permissionService: AutorizationService,
    private renderer: Renderer2,
    private el: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.getAuthUser().then(identity => {
      if (identity) {
        this.subscription = this._permissionService
          .getAccessLevel(this.objectId, identity.id)
          .subscribe(
            response => {
              this.role = response.role;
              if (this.role === 'read') {
                this.disableFormElements(this.el.nativeElement);
              }
            },
            () => {}
          );
      }
    });
  }

  private disableFormElements(element: HTMLElement): void {
    const formElements = element.querySelectorAll(
      'input, textarea, select, button, mat-select'
    );
    formElements.forEach((el: HTMLElement) => {
      this.renderer.setAttribute(el, 'disabled', 'true');
      el.style.opacity = '0.5';
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
