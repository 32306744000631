import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ObjectService } from '@excelway/services/object.service';
import { WorkshopService } from 'app/modules/workshops/workshop.activities';

@Component({
  selector: 'access-and-invites',
  templateUrl: './access-and-invites.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessInvitesComponent implements OnInit {
  accessInvitesForm: UntypedFormGroup;
  plans: any[];
  isPublic: boolean;

  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<AccessInvitesComponent>,
    private _workshopService: WorkshopService,
    private _objectService: ObjectService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    this.accessInvitesForm = this._formBuilder.group({
      plan: [''], // Set initial value based on isPublic
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._workshopService
      .getWorkshopActivities({
        roleType: 'Workshop',
        id: this.data.id,
        fields: ['parentId', 'isPublic'],
      })
      .subscribe(response => {
        this.accessInvitesForm.controls['plan'].setValue(
          response.isPublic ? 'public' : 'private'
        );
      });

    // Setup the plans
    this.plans = [
      {
        value: 'public',
        label: 'Public workshop',
        icon: 'lock_open',
        details: 'Available to everyone with the link. No sign-up required.',
        price: '10',
      },
      {
        value: 'private',
        label: 'Private workshop',
        icon: 'lock',
        details: 'Only available to those invited by email. Sign-up required.',
        price: '20',
      },
    ];
  }

  saveChanges(): void {
    this._objectService
      .updateObject('Workshop', this.data.id, {
        name: this.data.name,
        status: this.data.status,
        isPublic: this.isPublic ? true : false,
      })
      .subscribe(response => {
        console.log('activity updated', response);
      });
    this._matDialogRef.close();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
