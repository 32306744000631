import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorkshopService } from '../workshop.activities';

@Component({
  selector: 'start-brainstorm-dialog',
  templateUrl: './start-brainstorm-dialog.html',
  providers: [DatePipe],
})
export class StartBrainstormDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  board: boolean;
  workshopId = '';
  isPublic = true;
  composeForm: UntypedFormGroup;
  selectedColor = [];
  selectedColorString: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    public _formbuilder: FormBuilder,
    private datePipe: DatePipe,
    private _Activatedroute: ActivatedRoute,
    private _workshopService: WorkshopService,
    private _formBuilder: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<StartBrainstormDialogComponent>
  ) {}
  ngOnInit(): void {
    // Create the form
    this.composeForm = this._formBuilder.group({
      checkboxAllColors: [''],
      cc: [''],
      bcc: [''],
      subject: [''],
      body: [''],
    });
    console.log(this.data);
    this.isPublic = this.data.value?.isPublic;
  }

  colorChanged(event: any): void {
    this.selectedColor = event;
    this.selectedColorString = this.selectedColor.join('');
    console.log(this.selectedColorString);
    console.log(typeof this.selectedColorString);
  }

  startBrainstorm(): void {
    console.log(this.selectedColor);
    const activityData = {
      workFlowStep: 'brainstorm',
      brainstormColors: this.selectedColorString,
      isPublic: true,
    };
    this._workshopService
      .updateActivity(activityData, this.data.boardId)
      .subscribe();
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
