import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { WorkshopService } from '../workshop.activities';

@Component({
  selector: 'animator-category-selector',
  templateUrl: './category-selector.component.html',
})
export class CategorySelectorComponent implements OnInit {
  labelsMenuView: string;
  composeForm: UntypedFormGroup;

  yellow: boolean = false;
  red: boolean = false;
  blue: boolean = false;
  @Output() colorValues = new EventEmitter<any>();
  @Input() boardId: string;

  colorDisplayed = '#FF9B9B';
  activityColors: any[] = [];
  selectedColors: any[] = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _workshopService: WorkshopService
  ) {}

  ngOnInit(): void {
    this.labelsMenuView = 'labels';
    this.composeForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      color: [''],
    });

    this.getColors();

    console.log(this.activityColors);
  }

  colorSelected(event): void {
    this.colorDisplayed = event.color.color;
  }

  getColors(): void {
    this._workshopService
      .getActivityColors({
        roleType: 'Activity',
        id: this.boardId,
        fields: [],
        relations: [
          {
            relation: 'Label',
            fields: ['id', 'name', 'color'],
          },
        ],
      })
      .subscribe(Response => {
        this.activityColors = Response.Label;
      });
  }

  toggleProductTag(color: any, change: any): void {
    if (change.checked) {
      this.selectedColors.push(color.color);
      console.log(this.selectedColors);
    } else {
      console.log('No');
      this.selectedColors = this.selectedColors.filter(e => e !== color.color);
      console.log(this.selectedColors);
    }
    this.colorValues.emit(this.selectedColors);
  }

  yellowChecked(checked: boolean): void {
    this.yellow = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }

  redChecked(checked: boolean): void {
    console.log(checked);
    this.red = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }

  blueChecked(checked: boolean): void {
    this.blue = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }

  addColor(): void {
    const colorData = {
      name: this.composeForm.value.name,
      description: '',
      color: this.colorDisplayed,
    };
    this._workshopService.addActivityColor(colorData, this.boardId).subscribe();
  }
}
