<div class="h-full">
  <form action="">
    <div class="grid grid-cols-4 gap-8">
      <div class="col-span-4 flex flex-col gap-y-1">
        <div
          class="w-[136px] text-gray-900 text-base font-medium leading-normal">
          Security
        </div>
        <div class="w-[544px] text-gray-600 text-sm font-normal leading-normal">
          Change your password
        </div>
      </div>
      <div class="col-span-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
          Current password
        </label>
        <input
          formControlName="name"
          class="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          placeholder="Name" />
      </div>
      <div class="col-span-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
          New password
        </label>
        <input
          formControlName="name"
          class="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          placeholder="Name" />
      </div>
      <div class="col-span-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
          Confirm password
        </label>
        <input
          formControlName="name"
          class="bg-white shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          placeholder="Name" />
      </div>
      <div class="col-span-4">
        <div class="border-b pb-10">
          <button type="button" class="btn-primary-hover">Save changes</button>
        </div>
      </div>
    </div>
  </form>
</div>
