import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
})
export class WorkshopColorPickerComponent implements OnInit {
  labelsMenuView: string;
  composeForm: UntypedFormGroup;
  @Output() colorSelected = new EventEmitter<any>();
  @Input() roleType: any = {};

  colors = [
    {
      name: '',
      color: '#FF9B9B',
    },
    {
      name: '',
      color: '#FFC8C8',
    },
    {
      name: '',
      color: '#FFC294',
    },
    {
      name: '',
      color: '#FFE368',
    },
    {
      name: '',
      color: '#FFFBA8',
    },
    {
      name: '',
      color: '#DEFF85',
    },
    {
      name: '',
      color: '#F7FFDB',
    },
    {
      name: '',
      color: '#C5E6D7',
    },
    {
      name: '',
      color: '#DBFFF7',
    },
    {
      name: '',
      color: '#9EE7E3',
    },
    {
      name: '',
      color: '#D8E5FF',
    },
    {
      name: '',
      color: '#D5D1FF',
    },
    {
      name: '',
      color: '#EEC8FF',
    },
    {
      name: '',
      color: '#FFD9FA',
    },
    {
      name: '',
      color: '#FFC4E2',
    },
    {
      name: '',
      color: '#D8D8D8',
    },
  ];
  yellow: boolean = false;
  red: boolean = false;
  blue: boolean = false;
  @Output() colorValues = new EventEmitter<any>();

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.roleType === 'activity') {
      this.colors = [
        {
          name: '',
          color: '#FFE8E8',
        },
        {
          name: '',
          color: '#f3dbc2',
        },
        {
          name: '',
          color: '#F9D5DF',
        },
        {
          name: '',
          color: '#d3e0dc',
        },
        {
          name: '',
          color: '#c6fafa',
        },
        {
          name: '',
          color: '#dcffcc',
        },
        {
          name: '',
          color: '#f3faf8',
        },
        {
          name: '',
          color: '#d9e4dd',
        },
        {
          name: '',
          color: '#f6def6',
        },
        {
          name: '',
          color: '#CDD7EF',
        },
        {
          name: '',
          color: '#e3dbff',
        },
        {
          name: '',
          color: '#cbf1f5',
        },
        {
          name: '',
          color: '#d2e1c8',
        },
        {
          name: '',
          color: '#f5e8b4',
        },
      ];
    }
    this.labelsMenuView = 'labels';
    this.composeForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      color: [''],
    });
  }

  colorSelect(color: any): void {
    this.colorSelected.next({ color });
  }

  yellowChecked(checked: boolean): void {
    this.yellow = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }

  redChecked(checked: boolean): void {
    console.log(checked);
    this.red = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }

  blueChecked(checked: boolean): void {
    this.blue = checked;
    this.colorValues.next([
      { red: this.red },
      { blue: this.blue },
      { yellow: this.yellow },
    ]);
  }
}
