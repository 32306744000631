import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectWorkshopsState = createFeatureSelector<State>('workshops');

export const selectWorkshops = createSelector(
  selectWorkshopsState,
  (state: State): any[] => state.workshops
);

export const selectCurrentWorkshop = createSelector(
  selectWorkshopsState,
  (state: State): any => state.currentWorkshop
);

export const selectCurrentActivity = createSelector(
  selectWorkshopsState,
  (state: State): any => state.currentActivity
);

export const selectError = createSelector(
  selectWorkshopsState,
  (state: State): any => state.error
);

export const selectPending = createSelector(
  selectWorkshopsState,
  (state: State): any => state.pending
);
