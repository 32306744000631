import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InterfaceStore } from '../user-interface.store';

interface Field {
  id?: string;
  name: string;
  value: {
    type: string;
    languages: { [key: string]: string };
  };
  isEditing?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './interface-fields.component.html',
})
export class InterfaceFieldsComponent implements OnInit, OnDestroy {
  fields$ = this.interfaceStore.fields$;
  interfaceId: string;
  fieldTypes: string[] = [
    'autocomplete',
    'boolean',
    'button',
    'checkbox',
    'color',
    'currency',
    'custom',
    'date',
    'dateTime',
    'email',
    'file',
    'multiSelect',
    'number',
    'password',
    'phone',
    'radio',
    'range',
    'rating',
    'search',
    'select',
    'slider',
    'text',
    'textarea',
    'time',
    'toggle',
    'url',
  ];
  availableLanguages: string[] = [
    'English',
    'French',
    'Arabic',
    'Spanish',
    'German',
    'Chinese',
    'Japanese',
  ];
  selectedLanguage: string = '';
  languages: string[] = [];
  fields: Field[] = [];
  allLanguages: string[] = [];
  private subscriptions: Subscription = new Subscription();
  private loadedFields: Field[] = [];

  constructor(
    private interfaceStore: InterfaceStore,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.interfaceId = params['id'];
      this.loadFields(params['id']);
    });

    this.subscriptions.add(
      this.fields$.subscribe(fields => {
        this.fields = fields.map(field => ({
          id: field.id,
          name: field.name,
          value: {
            type: field.value.type,
            languages: field.value.languages,
          },
          isEditing: false,
        }));
        this.updateAllLanguages();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.interfaceStore.resetState();
  }

  loadFields(interfaceId: string): void {
    this.interfaceStore.getInterfaceFields({
      interfaceId: interfaceId,
      skip: 0,
      take: 10,
    });
  }

  updateAllLanguages(): void {
    const languagesSet = new Set<string>();
    this.fields.forEach(field => {
      Object.keys(field.value.languages).forEach(lang =>
        languagesSet.add(lang)
      );
    });
    this.allLanguages = Array.from(languagesSet);
  }

  addRow(): void {
    const newField: Field = {
      name: '',
      value: {
        type: this.fieldTypes[0],
        languages: {},
      },
      isEditing: true,
    };
    this.allLanguages.forEach(lang => (newField.value.languages[lang] = ''));
    this.fields.push(newField);
  }

  addLanguage(language: string): void {
    this.languages.push(language);
    this.fields.forEach(field => (field.value.languages[language] = ''));
    this.updateAllLanguages();
  }

  toggleEdit(field: Field): void {
    field.isEditing = !field.isEditing;
  }

  saveRow(field: Field): void {
    const newField = {
      id: field.id,
      name: field.name,
      value: {
        type: field.value.type,
        languages: field.value.languages,
      },
    };

    if (field.id) {
      this.interfaceStore.updateFieldEffect({
        fieldData: newField,
        fieldId: newField.id as string,
      });
    } else {
      this.interfaceStore.createField({
        body: newField,
        interfaceId: this.interfaceId,
      });
    }
    field.isEditing = false;
  }

  cancel(field: Field): void {
    field.isEditing = false;
  }
}
