<div class="flex flex-col max-w-240 md:min-w-260 max-h-screen -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 border-b border-slate-200 pl-6 sm:pl-8 b">
    <div class="text-lg font-medium">Start Brainstorm</div>
    <button mat-icon-button [tabIndex]="-1" (click)="this.matDialogRef.close()">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  <!-- Compose form -->
  <form
    class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto"
    [formGroup]="composeForm">
    <!-- To -->
    <div class="flex justify-center mt-5">
      <span>Choose one or more colors of cards for this brainstorm</span>
      <div [matTooltip]="'Mark all as read'">
        <mat-icon style="cursor: pointer" class="s-16 ml-2"
          >help_outline
        </mat-icon>
      </div>
    </div>
    <div class="flex justify-between mt-10">
      <div>
        <mat-checkbox
          [color]="'primary'"
          [formControlName]="'checkboxAllColors'">
          All Colors
        </mat-checkbox>
      </div>

      <div>
        <button mat-stroked-button [matMenuTriggerFor]="labelsMenu">
          <span>Colors</span>
          <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #labelsMenu="matMenu" [overlapTrigger]="false">
          <animator-category-selector
            [boardId]="this.data.boardId"
            (colorValues)="colorChanged($event)"></animator-category-selector>
        </mat-menu>
      </div>
    </div>
    <!-- Body -->
  </form>
  <!-- Actions -->
  <div
    class="flex justify-end p-4 sm:flex-row sm:items-center border-t border-slate-200 sm:mt-6">
    <div class="flex items-center sm:mt-0">
      <!-- Discard -->
      <button
        class="ml-auto sm:ml-0 btn-tertiary-hover"
        (click)="this.matDialogRef.close()">
        Cancel
      </button>
      <!-- Save as draft -->
      <button (click)="startBrainstorm()" class="sm:mx-3 btn-primary-hover">
        <span>Start Brainstorm</span>
      </button>
    </div>
  </div>
</div>
