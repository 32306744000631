import { createAction, props } from '@ngrx/store';

export const getWorkshops = createAction('[Workshops][API] Get Workshops List');

export const getWorkshopsSuccess = createAction(
  '[Workshops][API] Get Workshops List Success',
  props<{ workshops: any[] }>()
);

export const getWorkshopsFailure = createAction(
  '[Workshops][API] Get Workshops List Failure',
  props<{ error: any }>()
);

export const getCurrentActivity = createAction(
  '[Workshops][API] Get Current Activity'
);

export const loadBoard = createAction(
  '[Workshop][Board Page] Load Board',
  props<{ boardId: string }>()
);

export const loadBoardSuccess = createAction(
  '[Workshop][Board API] Load Board Success',
  props<{
    board: any;
  }>()
);

export const loadBoardFailure = createAction(
  '[Workshop][Board API] Load Board Failure',
  props<{ error: string }>()
);

export const getCurrentActivitySuccess = createAction(
  '[Workshops][API] Get Current Activity Success',
  props<{ activity: any }>()
);

export const getCurrentActivityFailure = createAction(
  '[Workshops][API] Get Current Activity Failure',
  props<{ error: any }>()
);

export const updateDisplayLabel = createAction(
  '[Workshop][Brainstorming API] Update Display Label via Settings',
  props<{
    label: boolean;
  }>()
);

export const updateDisplayLabelSuccess = createAction(
  '[Workshop][Brainstorming API] Update Display Label via Settings Success',
  props<{
    label: boolean;
  }>()
);

export const updateDisplayLabelFailure = createAction(
  '[Workshop][Brainstorming API] Update Display Label via Settings Failure',
  props<{
    error: any;
  }>()
);

export const usersNavigateChapters = createAction(
  '[Workshop][Brainstorming API] Update Users Navigate Chapters via Settings',
  props<{
    navigate: boolean;
  }>()
);

export const usersNavigateChaptersSuccess = createAction(
  '[Workshop][Brainstorming API] Update Users Navigate Chapters via Settings Success',
  props<{
    navigate: boolean;
  }>()
);

export const usersNavigateChaptersFailure = createAction(
  '[Workshop][Brainstorming API] Update Users Navigate Chapters via Settings Failure',
  props<{
    error: any;
  }>()
);

export const addActivityPostIt = createAction(
  '[Workshop][Canvas Page] add Activity PostIt',
  props<{ selectedColor: any; listId: any; title: any }>()
);

export const addActivityPostItSuccess = createAction(
  '[Workshop][Canvas Page] add Activity PostIt Success',
  props<{ card: any; listId: any }>()
);

export const addActivityPostItFailure = createAction(
  '[Workshop][Canvas Page] add Activity PostIt Failure',
  props<{ error: any }>()
);

export const toggleShowMembersName = createAction(
  '[Workshop][Canvas Page] Toggle Show Members Names',
  props<{ enabled: boolean }>()
);

export const toggleShowMembersNameSuccess = createAction(
  '[Workshop][Canvas Page] Toggle Show Members Names Success',
  props<{ enabled: boolean }>()
);

export const toggleShowMembersNameFailure = createAction(
  '[Workshop][Canvas Page] Toggle Show Members Names Failure',
  props<{ error: any }>()
);

export const toggleShowAllCards = createAction(
  '[Workshop][Brainstorming Page] Toggle Show All cards',
  props<{ enabled: boolean }>()
);

export const toggleShowAllCardsSuccess = createAction(
  '[Workshop][Brainstorming Page] Toggle Show All cards Success',
  props<{ enabled: boolean }>()
);

export const toggleShowAllCardsFailure = createAction(
  '[Workshop][Brainstorming Page] Toggle Show All cards Failure',
  props<{ error: any }>()
);

export const getWorkshop = createAction(
  '[Workshops][API] Get Workshop By Id',
  props<{ id: string }>()
);

export const getWorkshopSuccess = createAction(
  '[Workshops][API] Get Workshop By Id Success',
  props<{ workshop: any }>()
);

export const getWorkshopFailure = createAction(
  '[Workshops][API] Get Workshop By Id Failure',
  props<{ error: any }>()
);
